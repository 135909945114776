import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminParamsGenerator, adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import axios from 'axios';
import { toast } from 'react-toastify';

interface PurchaseOrder {
    id: number;
    purchase_order_id: string;
}
interface ProformaInvoice {
    id: number;
    proforma_invoice_id: string;
    amount: number;
    paid_amount: number;
    balance_due: number;
}

interface PaymentReceipt {
    amount: number;
}

interface AddUpdatePaymentReceiptProps {
    show: boolean;
    refreshNow: () => void;
    setShow: (show: boolean) => void;
    initialValue: any;
    setInitialValue: (value: any) => void;
}

const AddUpdatePaymentReceipt = ({ 
    show, 
    refreshNow, 
    setShow, 
    initialValue, 
    setInitialValue 
}: AddUpdatePaymentReceiptProps) => {
    const [loading, setLoading] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState<PurchaseOrder[]>([]);
    const [proformaOrderList, setProformaOrderList] = useState<ProformaInvoice[]>([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [file, setFile] = useState<any>(null);
    const [balanceDue, setBalanceDue] = useState<number | null>(null);
    const [statusList, setStatusList] = useState([]);
    const [paymentReceipts, setPaymentReceipts] = useState<PaymentReceipt[]>([]);
    const [balanceDueLoading, setBalanceDueLoading] = useState(false);
    const [vendorInvoiceList, setVendorInvoiceList] = useState([]);
    const [proformaLoader, setProformaLoader] = useState(false)
    const [vendorInvoiceLoader, setVendorInvoiceLoader] = useState(false)

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchaseOrderList, paymentMethod, paymentStatus] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                    getRequest(URL_EndPoints()?.getPurchasePaymentMethod , null),
                    getRequest(URL_EndPoints()?.getPaymentStatusListing, null),
                ]);

                setPurchaseOrderList(purchaseOrderList?.data?.data);
                setPaymentMethods(paymentMethod?.data?.data);
                setStatusList(paymentStatus?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    const getPeformaData = async () => {
        setProformaLoader(true)
        const proforma = await getRequest(URL_EndPoints(adminParamsGenerator("getProformaByPurchaseOrderId", initialValue?.purchase_order_id))?.getProformaByPurchaseOrderId, setProformaLoader)
        setProformaOrderList(proforma?.data?.data);
    }

    useEffect(() => {
        if (initialValue?.purchase_order_id) {
            getPeformaData();
        }
    }, [initialValue?.purchase_order_id]);

    const fetchVendorInvoices = async (proformaId: string) => {
        setVendorInvoiceLoader(true)
        try {
            const response = await getRequest(URL_EndPoints(adminParamsGenerator("getVendorInvoiceByProformaInvoiceId", proformaId))?.getVendorInvoiceByProformaInvoiceId, setVendorInvoiceLoader)
            setVendorInvoiceList(response?.data?.data?.vendorInvoices || []);
        } catch (error) {
            console.error('Error fetching vendor invoices:', error);
            toast.error('Failed to fetch vendor invoices');
        } finally {
            setVendorInvoiceLoader(false)
        }
    };

    const fetchPaymentReceiptsAndCalculateBalance = async (proformaId: string) => {
        if (initialValue.status) return;
        setBalanceDueLoading(true);
        try {
            // Find the selected proforma to get its total amount
            const selectedProforma = proformaOrderList.find(
                (po: any) => po.id === parseInt(proformaId)
            );

            if (!selectedProforma) return;

            const response = await getRequest(URL_EndPoints(adminParamsGenerator("getPaymentReceiptByProformaInvoiceId", proformaId))?.getPaymentReceiptByProformaInvoiceId, null)
            const receipts = response?.data?.data || [];
            setPaymentReceipts(receipts);

            // Calculate total paid amount
            const totalPaidAmount = receipts.reduce(
                (sum: number, receipt: PaymentReceipt) => {
                    const amount = Number(receipt.amount) || 0;
                    return sum + amount;
                },
                0
            );

            // Calculate balance due
            const newBalanceDue = selectedProforma.amount - totalPaidAmount;
            setBalanceDue(newBalanceDue);

        } catch (error) {
            console.error('Error fetching payment receipts:', error);
            toast.error('Error calculating balance due');
        } finally {
            setBalanceDueLoading(false);
        }
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        
        if (name === "purchase_order_id") {
            setInitialValue({
                ...initialValue,
                [name]: value,
                amount: '',
                proforma_invoice_id: '',
                vendor_invoice_id: '',
            });
            setBalanceDue(null);
        } else if (name === "proforma_invoice_id") {
            setInitialValue({
                ...initialValue,
                [name]: value,
                amount: '',
                vendor_invoice_id: '',
            });
            
            if (!initialValue.status) {
                fetchPaymentReceiptsAndCalculateBalance(value);
                fetchVendorInvoices(value);
            }
        } else {
            setInitialValue({
                ...initialValue,
                [name]: value,
            });
        }
    };

    const [fileSelected, setFileSelected] = useState<boolean>(false);
    // const [file, setFile] = useState<any>(null);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!initialValue.amount || parseFloat(initialValue.amount) <= 0) {
            toast.error('Amount must be greater than 0');
            return;
        }

        if (typeof (file) == "string" || !file) {
            delete initialValue.media
        } else {
            initialValue.media = file
        }

        setLoading(true)
        const response = await postRequestWithFile(URL_EndPoints()?.createPaymentReceipt, initialValue, setLoading)
        adminToastRunner(response)
        refreshNow && refreshNow();
        setShow && setShow(false);
    };

    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Payment Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id_controlled">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchaseOrderList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {
                                <Form.Group as={Col} className="mb-3" controlId="proforma_invoice_id">
                                    <Form.Label><strong>Select Proforma</strong></Form.Label>
                                    {
                                        proformaLoader ? <IsLoading /> : <Form.Control
                                            as="select"
                                            name="proforma_invoice_id"
                                            value={initialValue["proforma_invoice_id"] || []}
                                            onChange={handleInputChange}
                                            disabled={!initialValue.purchase_order_id}
                                            required
                                        >
                                            <option value="">Select Proforma</option>
                                            {proformaOrderList.map((item: any) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.proforma_invoice_id ? item.proforma_invoice_id : `${(item.proforma_invoice_id)} | NA`}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    }
                                </Form.Group>
                            }

                            <Form.Group as={Col} className="mb-3" controlId="vendor_invoice_id">
                                <Form.Label><strong>Select Vendor Invoice</strong></Form.Label>
                                {
                                    vendorInvoiceLoader ? <IsLoading /> : <Form.Control
                                    as="select"
                                    name="vendor_invoice_id"
                                    value={initialValue["vendor_invoice_id"] || ""}
                                    onChange={handleInputChange}
                                    disabled={!initialValue.proforma_invoice_id}
                                >
                                    <option value="">Select Vendor Invoice</option>
                                    {vendorInvoiceList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.invoice_number}
                                        </option>
                                    ))}
                                </Form.Control>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} className="mb-3" controlId="status">
                                <Form.Label><strong>Select Status</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={initialValue["status"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    {statusList
                                    .filter((status: any) => status.status !== 'Declined')
                                    .map((status: any) => (
                                        <option key={status.id} value={status.id}>
                                            {status.status}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                        <Form.Group as={Col} className="mb-3" controlId="payment_method">
                                <Form.Label><strong>Select Payment Method</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="payment_method"
                                    value={initialValue["payment_method"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Payment Method</option>
                                    {paymentMethods.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="payment_date">
                                <Form.Label><strong>Payment Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Payment Date"
                                    name="payment_date"
                                    value={initialValue['payment_date']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="amount">
                                <Form.Label>
                                    <strong>Amount</strong>
                                    {balanceDueLoading ? (
                                        <small className="ms-2">
                                            <Spinner animation="border" size="sm" /> Calculating due...
                                        </small>
                                    ) : (
                                        balanceDue !== null && (
                                            <small className="text-danger fw-bold ms-2">
                                                (due: {balanceDue})
                                            </small>
                                        )
                                    )}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Amount"
                                    name="amount"
                                    value={initialValue['amount']}
                                    onChange={handleInputChange}
                                    required
                                    max={balanceDue || undefined}
                                    min={0}
                                    disabled={balanceDueLoading || !balanceDue || balanceDue === 0}
                                />
                            {balanceDue === 0 && (
                                <small className="text-danger">
                                    No balance due for this proforma invoice
                                </small>
                            )}
                        </Form.Group>


                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdatePaymentReceipt;
