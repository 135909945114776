import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { adminToastRunner, adminToastRunnerError, getName } from '../admins/adminUtils';
import { NewData, NewData222 } from './data';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import OpenCleanerList from './OpenCleanerList';
import IsLoading from '../../consts/IsLoading/IsLoading';
import MomentDate from '../../consts/MomentDate';
import Not_Found from '../../consts/Not_Found';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import AdminSingleDateFilter from '../admins/adminForms/adminFormComponents/AdminSingleDateFilter';
import ChampDetailModal from './modals/ChampDetailModal';
import ChampDetailTable from './tables/ChampDetailTable';

const AssignJobForm = ({ show, row, assignAbsentJobId, assignAbsentJobRender }: any) => {
    const [assignJobList, setAssignJobList] = useState<any>([]);
    const [showCleaner, setShowCleaner] = useState<any>(false);
    const [pending, setPending] = useState<any>(false);
    const [apiLoader, setApiLoader] = useState<any>(false);
    const [date, setDate] = useState(MomentDate(0))
    const [showChampDetail, setShowChampDetail] = useState<any>(false)
    const [rowData, setRowData] = useState<any>(row)
    async function getFetchInitialData() {
        setPending(true)
        const results = await postRequest(URL_EndPoints()?.getChampActiveJobs, {
            date: date,
            champId: row?.id,
            // "date": "2025-01-12",
            // "champId": 41918
        }, setPending)
        setAssignJobList(results?.data?.data)
    }

    const searchHandler = () => {
        getFetchInitialData()
    }

    const onChangeHandler = (event: any, name: any) => {
        setDate(event ? event?.value : event)
    }

    useEffect(() => {
        getFetchInitialData()
    }, [show, row?.id])

    const submitForm = async ({
        jobId,
        champId,
        assignment_type
    }: any) => {
        const payload = [
            {
                id: jobId,
                champId: champId,
                assignment_type: assignment_type
            }
        ]
        const newPayload = { payload: payload?.map((ele: any) => ({ jobId: ele?.id, champId: ele?.champId ?? 0, assignment_type: ele?.assignment_type ?? 1 })) }
        setApiLoader(true)
        const response: any = await postRequest(URL_EndPoints(null)?.["assignChampToJobs"], newPayload, setApiLoader)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            getFetchInitialData()
        }
    }

    const [subscriptionid, setSubscriptionId] = useState(null)
    const [index, setIndex] = useState<any>(null)
    const openChampAssignCleaner = (subscriptionid: any, index: number) => {
        setShowCleaner(true)
        setSubscriptionId(subscriptionid)
        setIndex(index)
    }

    const getChampDetails = (data: any) => {
        data.isSelectedChamp = true
        assignJobList[index].availableChamp = data
        setAssignJobList(assignJobList)
        setShowCleaner(false)
    }


    return (
        <>
            <div className="d-flex justify-content-end align-items-center">
                <div style={{ width: "300px" }} className='me-2 ms-1'>
                    <AdminSingleDateFilter
                        onChangeHandler={onChangeHandler}
                        payload={{ date: date }}
                        name="date"
                    />
                </div>
                <button className='btn btn-sm btn-primary' onClick={searchHandler}>Search</button>

            </div>
            {
                pending ? <IsLoading /> : <>
                    <table className="table table-hover">
                        <thead>
                            {
                                assignJobList?.length > 0 && <tr>
                                    {/* <th scope="col">S.N</th> */}
                                    <th scope="col">Customer</th>
                                    <th scope="col">Champ Name</th>
                                    <th scope="col">Detail</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                assignJobList?.map((ele: any, index: any) => {
                                    console.log(ele)
                                    return <tr key={index}>
                                        <td>
                                            <div className='d-flex flex-column p-2'>
                                                <GChatBTN
                                                    ticketDataParent={{
                                                        id: null,
                                                        customerid: ele?.subscriptionMonth?.ctCustomer?.id
                                                    }}
                                                    Title={getName(ele?.subscriptionMonth?.ctCustomer)}
                                                    Toggleid={"kt_drawer_chat2_toggle2"}
                                                    classes={"text-dark"}
                                                    icons={"commenting"}
                                                ></GChatBTN>
                                                {
                                                    ele?.displayDetail
                                                        ?.filter((elm: any) => elm.value?.trim() != "")
                                                        ?.map((element: any) => {
                                                            return <div className='d-flex justify-content-between align-items-center mb-2 w-75' >
                                                                <span className="fs-8">{element?.value}</span>
                                                            </div>
                                                        })
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            {ele?.availableChamp ? (
                                                ele?.availableChamp?.isSelectedChamp ? (
                                                    <div className='d-flex gap-2 flex-column'>
                                                        <button
                                                            className='btn btn-sm btn-success'
                                                            onClick={() => openChampAssignCleaner(ele?.subscriptionMonth?.id, index)}
                                                        >
                                                            {getName(ele?.availableChamp)}
                                                        </button>
                                                        <button
                                                            className='btn btn-sm btn-success'
                                                            onClick={() => submitForm({
                                                                jobId: ele?.id,
                                                                champId: ele?.availableChamp?.id,
                                                                assignment_type: 1
                                                            })}
                                                        >
                                                            Assign
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="d-flex gap-2 flex-column align-items-center">
                                                            <div className='d-flex flex-column align-items-center'>
                                                                <span className='text-primary me-2'>Suggested Champ: </span>
                                                                <OpenChat title={getName(ele?.availableChamp)} champId={ele?.availableChamp?.id} />
                                                            </div>
                                                            <button
                                                                className='btn btn-sm btn-success'
                                                                onClick={() => submitForm({
                                                                    jobId: ele?.id,
                                                                    champId: ele?.availableChamp?.id,
                                                                    assignment_type: 1
                                                                })}
                                                            >
                                                                Assign
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            ) : (
                                                <button
                                                    className='btn btn-sm btn-primary'
                                                    onClick={() => openChampAssignCleaner(ele?.subscriptionMonth?.id, index)}
                                                >
                                                    Select
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <>
                                                <ChampDetailTable rowData={ele} submitForm={submitForm} />
                                                {/* <div className='d-flex justify-content-between align-items-center mb-2 w-75' >
                                                    <span>
                                                        <button className='btn btn-sm btn-primary' onClick={() => {
                                                            setShowChampDetail(true)
                                                            setRowData(ele)
                                                        }}>Available Champs In Range</button>
                                                    </span>
                                                </div>   */}
                                            </>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>

                    {
                        assignJobList?.length == 0 && <Not_Found headerTitle="No Data Found" />
                    }
                    {/* {
                        assignJobList?.length > 0 && <div className='d-flex justify-content-end text-end mt-4'>
                            <button className='btn btn-sm btn-primary' disabled={apiLoader} onClick={submitForm}>{apiLoader ? "Submitting..." : "Submit"}</button>
                        </div>
                    } */}
                </>
            }

            {
                showCleaner && <OpenCleanerList
                    show={showCleaner}
                    setShow={setShowCleaner}
                    getChampDetails={getChampDetails}
                    champAvailty={{
                        champAvailty: subscriptionid,
                        champAvailtyRender: Math.random(),
                        from: "champAbsentList"
                    }}
                />
            }
            <ChampDetailModal rowData={rowData} show={showChampDetail} setShow={setShowChampDetail} />
        </>
    )
}
export default AssignJobForm
