import { faAdd, faMinus, faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, Button } from 'react-bootstrap';

const ViewRow = ({ item, index, taskStatus, handleQuantity, handleDropdownChange, addNewRow, removCurrentRow, addedItems, quantityValue }: any) => {
    return (
        <>
            <tr key={item?.id + "viewrow" + index} className='px-2'>
                <td className='mx-1'>{item?.item?.title} {` ( ${item?.quantity_ordered} ) `}</td>
                <td>
                    <Form.Control
                        type="number"
                        value={item?.quantity}
                        onChange={(e) => handleQuantity(index, e.target.value, item?.quantity_ordered, false, { id: item?.id })}
                        min={1}
                    />
                </td>
                <td>
                    <Form.Control
                        as="select"
                        name="condition"
                        value={item?.condition}
                        onChange={(e) => handleDropdownChange(index, e.target.value, "condition")}
                        required
                    >
                        <option value="">Select Condition</option>
                        {taskStatus?.cat_1?.map((ele: any) => (
                            <option key={ele.id} value={ele.id}>
                                {ele.name}
                            </option>
                        ))}
                    </Form.Control>
                </td>
                <td>
                    <Form.Control
                        as="select"
                        name="age"
                        value={item?.age}
                        onChange={(e) => handleDropdownChange(index, e.target.value, "age")}
                        required
                    >
                        <option value="">Select Age</option>
                        {taskStatus?.cat_2?.map((ele: any) => (
                            <option key={ele.id} value={ele.id}>
                                {ele.name}
                            </option>
                        ))}
                    </Form.Control>
                </td>
                <td>
                    <Form.Control
                        as="select"
                        name="care"
                        value={item?.care}
                        onChange={(e) => handleDropdownChange(index, e.target.value, "care")}
                        required
                    >
                        <option value="">Select Care</option>
                        {taskStatus?.cat_3?.map((ele: any) => (
                            <option key={ele.id} value={ele.id}>
                                {ele.name}
                            </option>
                        ))}
                    </Form.Control>
                </td>
                <td>
                    <Button variant="success" style={{ width: "40px", height: "35px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => addNewRow(item?.id, {
                        "id": item?.id,
                        "quantity": 0,
                        "condition": item?.condition,
                        "age": item?.age,
                        "care": item?.care,
                        "quantity_ordered": item?.quantity_ordered,
                    })} className='mt-1'><FontAwesomeIcon icon={faAdd} className='text-white fs-1' /></Button>
                </td>
            </tr>

            {
                addedItems?.map((addedElement: any, addedIndex: any) => {
                    return (
                        <tr key={"sub" + addedIndex + addedElement} className='px-2'>
                            <td className='mx-1'>{item?.item?.title}</td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={addedElement?.quantity}
                                    onChange={(e) => handleQuantity(index, e.target.value, item?.quantity_ordered, true, {
                                        id: item?.id, addedIndex: addedIndex, addedElement: addedElement
                                    })}
                                    min={1}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    name="condition"
                                    value={addedElement?.condition}
                                    onChange={(e) => handleDropdownChange(index, e.target.value, "condition", true, {
                                        id: item?.id, addedIndex: addedIndex, addedElement: addedElement
                                    })}
                                    required
                                >
                                    <option value="">Select Condition</option>
                                    {taskStatus?.cat_1?.map((ele: any) => (
                                        <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    name="age"
                                    value={addedElement?.age}
                                    onChange={(e) => handleDropdownChange(index, e.target.value, "age", true, {
                                        id: item?.id, addedIndex: addedIndex, addedElement: addedElement
                                    })}
                                    required
                                >
                                    <option value="">Select Age</option>
                                    {taskStatus?.cat_2?.map((ele: any) => (
                                        <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    name="care"
                                    value={addedElement?.care}
                                    onChange={(e) => handleDropdownChange(index, e.target.value, "care", true, {
                                        id: item?.id, addedIndex: addedIndex, addedElement: addedElement
                                    })}
                                    required
                                >
                                    <option value="">Select Care</option>
                                    {taskStatus?.cat_3?.map((ele: any) => (
                                        <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </td>

                            <td>
                                <Button className='mt-1' variant="danger" onClick={() => removCurrentRow(item?.id, addedIndex, addedItems)} style={{ width: "40px", height: "35px", display: "flex", alignItems: "center", justifyContent: "center" }}><FontAwesomeIcon icon={faRemove} className='text-white fs-1' /></Button>
                            </td>
                        </tr>
                    )
                })
            }

        </>
    );
};

export default ViewRow;
