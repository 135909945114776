import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

interface Product {
  proformaInvoiceItemsList: any;
  item_id: string;
  title: string;
  quantity_invoiced: number;
  unit_price: number;
  gst_percentage: number;
  item_amount: number;
  tax_amount: number;
  total_price: number;
}

interface ProductModalProps {
  show: boolean;
  productData: Product[];
  handleClose: () => void;
  modalLoader: boolean
}

const ViewProductModal: React.FC<ProductModalProps> = ({ show, productData, handleClose, modalLoader }) => {
  const columns = [
    {
        name: 'Item Name',
        selector: (row: Product) => row?.proformaInvoiceItemsList.title,
        sortable: true,
    },
    {
        name: 'Quantity',
        selector: (row: Product) => row.quantity_invoiced,
        sortable: true,
    },
    {
        name: 'Unit Price',
        selector: (row: Product) => row.unit_price || 0,
        sortable: true,
    },
    {
        name: 'GST %',
        selector: (row: Product) => row.gst_percentage || 0,
        sortable: true,
    },
    {
        name: 'Item Amount',
        selector: (row: Product) => row.item_amount || 0,
        sortable: true,
    },
    {
        name: 'Tax Amount',
        selector: (row: Product) => row.tax_amount || 0,
        sortable: true,
    },
    {
        name: 'Total Price',
        selector: (row: Product) => row.total_price || 0,
        sortable: true,
    }
  ];

  return (
    <Modal show={show} onHide={handleClose} size="xl"
    dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalLoader ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : productData && productData.length > 0 ? (
          <DataTable
            columns={columns}
            data={productData}
            pagination
            highlightOnHover
            striped
          />
        ) : (
          <p>No items available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewProductModal;
