import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { adminToastRunner, adminToastRunnerError, } from '../admins/adminUtils';
import AdminSelectSearchable from '../admins/adminForms/adminFormComponents/AdminSelectSearchable';
import NewOrderDate from './NewOrderDate';
import MomentDate from '../../consts/MomentDate';

const CreateMaterialDeliverTask = (props: any) => {
    const { show, setShow, refreshNow, filteredSelectedRows } = props;
    const [loading, setLoading] = useState(false);
    const [champList, setChampList] = useState([]);
    const [selectedItems, setSelectedItems] = useState<any[]>(filteredSelectedRows);

    const [initialValue, setInitialValue] = useState({
        md_champ_id: null,
        task_date: MomentDate(0),
        orderItemQuantityDetails: selectedItems?.map((ele: any) => ({
            order_id: ele?.order_id,
            item_id: ele?.item_id,
            quantity: ele?.quantity,
        }))
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [champListResponse] = await Promise.all([
                    getRequest(URL_EndPoints()?.getMaterialDeliveryChampList, null),
                ]);
                setChampList(champListResponse?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    const handleQuantityChange = (index: number, value: number, orderedQuantity: number) => {

        if (value > orderedQuantity) {
            adminToastRunnerError('Quantity cannot be greater than ordered quantity');
            return
        }

        const updatedItems = [...selectedItems];
        updatedItems[index].quantity = !value ? value : Number(value);
        setSelectedItems(updatedItems);
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        initialValue.orderItemQuantityDetails = selectedItems?.map((ele: any) => ({
            order_id: ele?.order_id,
            item_id: ele?.item_id,
            quantity: ele?.quantity,
        }))

        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('No Item Is Selected');
            return;
        }

        if (!initialValue?.md_champ_id || !initialValue?.task_date) {
            toast.warning('Champ Name / Task Date is missing', { position: "top-center" });
            return;
        }

        setLoading(true);
        try {
            let response = await postRequest(URL_EndPoints()?.createMaterialDeliveryTask, initialValue, setLoading)
            adminToastRunner(response);
            refreshNow && refreshNow();
            if (response?.data?.status == 200) {
                setShow(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to submit form');
        } finally {
            setLoading(false);
        }
    };

    const onChangeHandler = (event: any, name: any) => {
        setInitialValue({ ...initialValue, [name]: event ? event?.value : event })
    }

    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Material Delivery Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <Row className='w-100'>
                            {/* Select Delivery Champ */}
                            <Col xs={12} md={6} className="mb-3">
                                <AdminSelectSearchable
                                    HeaderTitle='Select Delivery Champ'
                                    onChangeHandler={onChangeHandler}
                                    selectData={champList}
                                    displayFields={["first_name", "last_name"]}
                                    id="id"
                                    name="md_champ_id"
                                    defaultData={null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                    isDisabled={false}
                                />
                            </Col>

                            {/* Single Date Filter */}
                            <Col xs={12} md={6} className="mb-3">
                                <NewOrderDate
                                    onChangeHandler={onChangeHandler}
                                    payload={initialValue}
                                    name="task_date"
                                />
                            </Col>
                        </Row>
                    </div>
                    {selectedItems?.length > 0 && (
                        <div>
                            {/* <h5><strong>Selected Items</strong></h5> */}
                            <Table striped bordered hover responsive>
                                <thead style={{ backgroundColor: '#5cb85c', color: 'white' }} className='px-2'>
                                    <tr className='px-2'>
                                        <th><strong>Title ( Unit )</strong></th>
                                        <th><strong>Order Id</strong></th>
                                        <th><strong>Quantity Ordered</strong></th>
                                        <th><strong>Enter Quantity</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedItems.map((item, index) => (
                                        <tr key={index} className='px-2'>
                                            <td>{item?.inventoryOrderedItemMaster?.title} {`(${item?.inventoryOrderedItemMaster?.unit})`}</td>
                                            <td>{item?.order_id}</td>
                                            <td>{item?.quantity_ordered}</td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    value={item?.quantity}
                                                    onChange={(e: any) => handleQuantityChange(index, e.target.value, item?.quantity_ordered)}
                                                    min={1}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>

                        </div>
                    )}

                    <div className='text-end'>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateMaterialDeliverTask;
