import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { KTCardBody } from '../../../_metronic/helpers'
import { JobStatusCounts } from './JobStatusCounts'
import { TrainingTaskCounts } from './TrainingTaskCounts'

export const TrainingTaskCountLists = () => {
  const [activeTab, setActiveTab] = useState('jobStatus1')

  return (
    <KTCardBody>
      <div className='mb-4 fs-4 fw-semibold'>Training Task Counts</div>
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || 'jobStatus1')}
        className='mb-4'
      >
        <Tab eventKey='jobStatus1' title='Count By Area Manager'>
          <JobStatusCounts />
        </Tab>
        <Tab eventKey='jobStatus2' title='Count All by Area Manager '>
          <TrainingTaskCounts />
        </Tab>

      </Tabs>
    </KTCardBody>
  )
}

