import { useEffect, useReducer, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { CardCountWidget } from './CardCountWidget'
import { newDailyJobDashboardStates, newDailyJobDashboardStatesReducer } from '../admins/ad_states/newDailyJobDashboardStates'
import { KTCardBody } from '../../../_metronic/helpers'
import { useDispatch } from 'react-redux'
import SingleSelectSearchDetails from '../../consts/Select/SingleSelectSearchDetails'
import SingleSelectSearchCategory from '../../consts/Select/SingleSelectSearchCategory'
import Refresh from '../../consts/Refresh'
import VehicleTypeListController from '../VehicleTypeLists/VehicleTypeListController'
import RewardAndPenaltiesList from '../RewardAndPenaltiesList/RewardAndPenaltiesList'
import SkeletonLoader from '../../consts/IsLoading/SkeletonLoader'
import NewTicketsList from '../NewTicketsList/NewTicketsList'
import NewRenewalSuscriptionControllerRoute from '../NewRenewalSuscriptionsPagination/NewRenewalSuscriptionControllerRoute'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import UserLogoutStatus from '../userLogoutStatus/UserLogoutStatus'
import StackBarCharts from '../admins/adminComponents/adminCharts/StackBarCharts'
import VisitorsListV2 from '../VisitorsList/VisitorsListV2'
import VisitorListChampV2 from '../VisitorListChamp/VisitorListChampV2'
import moment from 'moment'



export const AgentDashboard = () => {
	let toggleValue = {
		help: true,
		renewal: true,
		welcome: true,
		agent: true,
	}

	const dispatch = useDispatch()
	const [newDailyJobDashboardStatesData, setLocalStateDispatch] = useReducer(newDailyJobDashboardStatesReducer, newDailyJobDashboardStates);
	const [key, setKey] = useState<number>(1)

	const [pendingAgentProductivity, setPendingAgentProductivity] = useState<any>(false)
	const handleSelect = (key: any) => {
		setKey(key);
	}
	const [openClose, setOpenClose] = useState<any>(toggleValue)
	const [payload, setPayload] = useState<any>({})
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

	async function contentApiDataLoad() {
		setLocalStateDispatch({ type: "TDL_HELP_TICKET_DATA", payload: null })
		setLocalStateDispatch({ type: "TDL_RENEWAL_TICKET_DATA", payload: null })
		setLocalStateDispatch({ type: "TDL_WELCOM_TICKET_DATA", payload: null })
		setLocalStateDispatch({ type: "TDL_AGENT_PRODUCTIVITY_DATA", payload: null })
		setLocalStateDispatch({ type: "TDL_PROSPECT_TICKETS_DATA", payload: null })

		setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
		let newPayload = { ...payload, startDate, endDate }

		const getOpenHelpTickets = postRequest(URL_EndPoints()?.getOpenHelpTickets, newPayload, null)
		const getOpenRenewalTickets = postRequest(URL_EndPoints()?.getOpenRenewalTickets, newPayload, null)
		const getOpenWelcomeTickets = postRequest(URL_EndPoints()?.getOpenWelcomeTickets, newPayload, null)
		const getAgentProductivity = postRequest(URL_EndPoints()?.getAgentProductivity, newPayload, null)
		const getOpenProspectTickets = postRequest(URL_EndPoints()?.getOpenProspectTickets, newPayload, null)

		const [getOpenHelpTicketsApi, getOpenRenewalTicketsApi, getOpenWelcomeTicketsApi, getAgentProductivityApi, getOpenProspectTicketsApi]: any[] = await Promise.allSettled([getOpenHelpTickets, getOpenRenewalTickets, getOpenWelcomeTickets, getAgentProductivity, getOpenProspectTickets])

		setLocalStateDispatch({ type: "TDL_HELP_TICKET_DATA", payload: getOpenHelpTicketsApi?.value?.data?.data })
		setLocalStateDispatch({ type: "TDL_RENEWAL_TICKET_DATA", payload: getOpenRenewalTicketsApi?.value?.data?.data })
		setLocalStateDispatch({ type: "TDL_WELCOM_TICKET_DATA", payload: getOpenWelcomeTicketsApi?.value?.data?.data })
		setLocalStateDispatch({ type: "TDL_AGENT_PRODUCTIVITY_DATA", payload: getAgentProductivityApi?.value?.data?.data })
		setLocalStateDispatch({ type: "TDL_PROSPECT_TICKETS_DATA", payload: getOpenProspectTicketsApi?.value?.data?.data })
	}

	async function initialData() {

		const getAdminList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
		const getAllRoles = await getRequest(URL_EndPoints(null)?.getAllRoles, null)
		setLocalStateDispatch({ type: "RTL_ADMIN_LIST_DATA", payload: getAdminList?.data?.data })
		setLocalStateDispatch({ type: "RTL_ROLES_LIST_DATA", payload: getAllRoles?.data?.data })

		const getSubscriptionRenewalReason: any = await postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason, {}, null)
		let inactiveReasonData: any = []
		let alertReasonData: any = []
		getSubscriptionRenewalReason?.data?.data?.map((reason: any) => {
			if (reason.category_id == 2) {
				inactiveReasonData.push(reason)
			} else if (reason.category_id == 3) {
				alertReasonData.push(reason)
			}
		})

		dispatch({ type: "INACTIVE_DATA", payload: inactiveReasonData })
		dispatch({ type: "ALET_REASON", payload: alertReasonData })
	}

	useEffect(() => {
		initialData()
		contentApiDataLoad()
	}, [])

	const handleSearch = async () => {
		setOpenClose(toggleValue)
		setLocalStateDispatch({ type: "TDL_FILTER_DATE", payload: payload?.date })
		setLocalStateDispatch({ type: "TDL_FILTER_JOB_TYPE", payload: payload?.supervisorid })
		setLocalStateDispatch({ type: "TDL_FILTER_SUPERVISOR", payload: payload?.type })
		setLocalStateDispatch({ type: "TDL_FILTER_CUSTOMER_VINTAGE", payload: payload?.customerVintage })
		contentApiDataLoad()
	}

	useEffect(() => {
		handleSearch()
	}, [payload?.adminUserId, payload?.roleId])

	const handleTabClick = (filtereData: any = {}, tabKey: any = undefined, isRedirect: any = undefined) => {
		console.log('tabKey', tabKey);
		console.log('filtereData', filtereData);

		let filterDataList: any = {}
		if (tabKey == 2) {
			let { tabKey, ...rest } = filtereData
			filterDataList["newTicketList"] = { newTicketList: rest, isFilter: true }

		}

		if (tabKey == 4) {
			let { tabKey, ...rest } = filtereData
			filterDataList["newRenewalSubscriptionList"] = { newRenewalSubscriptionList: rest, isFilter: true }
		}

		if (tabKey == 5) {
			let { tabKey, ...rest } = filtereData
			filterDataList["customerLeadsFilter"] = { customerLeadsFilter: rest, isFilter: true }
		}

		if (tabKey == 6) {
			let { tabKey, ...rest } = filtereData
			filterDataList["inactiveFilterList"] = { inactiveFilterList: rest, isFilter: true }
		}

		if (tabKey == 7) {
			let { tabKey, ...rest } = filtereData
			filterDataList["champLeadsFilter"] = { champLeadsFilter: rest, isFilter: true }
		}

		setLocalStateDispatch({ type: "NEW_DAILY_DASHBOARD_OPEN_FILTER_DATA", payload: filterDataList })

		setKey(tabKey ?? 1);
	}

	const handleChangeInput = (event: any, name: any) => {
		setPayload({ ...payload, [name]: event ? event?.value : event })
	}

	const handleSubmit = async () => {
		if (!startDate || !endDate) {
			alert('Please select both start and end dates');
			return;
		}
		
		setOpenClose((prev: any) => ({ ...prev, ["agent"]: !prev?.agent }))
		const newPayload = {
			startDate,
			endDate,
			adminUserId: payload?.adminUserId,
		};

		setPendingAgentProductivity(true);
		try {
			const response = await postRequest(
				URL_EndPoints()?.getAgentProductivity,
				newPayload,
				setPendingAgentProductivity
			);

			setLocalStateDispatch({
				type: 'TDL_AGENT_PRODUCTIVITY_DATA',
				payload: response.data?.data,
			});
		} catch (error) {
			console.error('Error fetching agent productivity:', error);
			setPendingAgentProductivity(false);
		}
	};

	return (
		<>
			<KTCardBody className='card py-4'>
				{
					<>
						<Tabs activeKey={key} onSelect={handleSelect} id="controlled-tab-example">
							<Tab eventKey={1} title="Agent Daily Dashboard">

								{
									<>
										<div className='d-flex row mt-2'>
											{/* <div className=' mb-2 col col-md-2 col-sm-6 col-lg-2' style={{ position: "relative" }}>
												<label htmlFor="Date fw-semibold">Job Date</label>
												<div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
													<input
														className='form-control col-12'
														type="date"
														name="date"
														id="date"
														autoComplete='off'
														style={{ height: "39px" }}
														onChange={(event) => handleChangeInput(event.target, "date")}
														value={payload.date}
													/>
												</div>
											</div> */}

											<div className="mb-2 col col-md-3 col-sm-6 col-lg-3" key={"ewew2"}>
												<label htmlFor="Date fw-semibold">Agent Filter</label>
												<SingleSelectSearchDetails
													handleChangeInputData={handleChangeInput}
													HeaderTitle="Select Agent"
													SelectData={newDailyJobDashboardStatesData?.adminListData}
													DynamicKey={"first_name"}
													DynamicKey2={"last_name"}
													DynamicKey3={"phone"}
													id={"id"}
													name="adminUserId"
												/>
											</div>

											<div className="mb-2 col col-md-3 col-sm-6 col-lg-3" key={"ewew2"}>
												<label htmlFor="Date fw-semibold">Role Filter</label>
												<SingleSelectSearchDetails
													handleChangeInputData={handleChangeInput}
													HeaderTitle="Select Role"
													SelectData={newDailyJobDashboardStatesData?.rolesListData}
													DynamicKey={"Role_name"}
													id={"id"}
													name="roleId"
												/>
											</div>

											{/* <div className="mb-2 col col-md-2 col-sm-6 col-lg-2" key={43432432}>
												<label htmlFor="Date fw-semibold">Service Type</label>
												<SingleSelectSearchCategory
													handleChangeInputData={handleChangeInput}
													HeaderTitle='Service Type'
													SelectData={newDailyJobDashboardStatesData?.jobTypeListData}
													DynamicKey={'name'}
													id={'id'}
													name='type'
												></SingleSelectSearchCategory>
											</div> */}

											{/* <div className=' mb-2 col col-md-2 col-sm-6 col-lg-2' style={{ position: "relative" }}>
												<label htmlFor="Date fw-semibold">Customer Vintage</label>

												<div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
													<input
														className='form-control col-12'
														type="number"
														name="customerVintage"
														// id="date"
														placeholder='Customer Vintage'
														autoComplete='off'
														style={{ height: "39px" }}
														onChange={(event) => handleChangeInput(event.target, "customerVintage")}
														value={payload.customerVintage}
													/>
												</div>
											</div> */}

											<div className="mb-2 col d-flex  justify-content-end align-items-center">
												<Refresh refreshNow={handleSearch} />
												{/* <button className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-2 px-1 filterBtn text-center fw-bold'
													onClick={handleSearch}
													style={{
														height: "39px",
														width: "40%",
														margin: "0",
														marginLeft: "9px"
													}}
												> <i className="bi bi-filter p-1 fs-5  fw-bold"  ></i> Apply</button> */}
											</div>
										</div>
										{

											<div className='row ml-2 g-5 g-xl-8 justify-content-center mb-10'>
												<UserLogoutStatus onlyDisplay={true} defaultData={{ agentIDs: payload?.adminUserId ? [payload?.adminUserId] : [] }} source="agentDailyDashboard" />
												<StackBarCharts onlyDisplay={true} defaultData={{ agentIDs: payload?.adminUserId ? [payload?.adminUserId] : [] }} />
												{
													!newDailyJobDashboardStatesData?.helpTicketData ? <SkeletonLoader rowCount={5} title="Open Help Ticket" /> : <>

														<div className='d-flex' style={{ position: "relative" }} >
															<div style={{ display: "flex", position: 'absolute', top: "-20px", left: 0 }} onClick={
																() => setOpenClose((prev: any) => ({ ...prev, ["help"]: !prev?.help }))
															}>
																<h4 className='cursor-pointer text-primary'>{newDailyJobDashboardStatesData?.helpTicketData?.title ?? "Loading..."} <FontAwesomeIcon icon={openClose?.help ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' /></h4>
															</div >
															{
																openClose?.help && newDailyJobDashboardStatesData?.helpTicketData?.children?.map((element: any) => {
																	return <div className='col-xl-2 border-jobd job-section relative'>
																		<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} mainTitle={newDailyJobDashboardStatesData?.helpTicketData?.title} />
																	</div>
																})
															}

														</div>


													</>
												}

												{
													!newDailyJobDashboardStatesData?.renewalTicketData ? <SkeletonLoader rowCount={5} title="Open Renewal Ticket" /> :
														<>
															<div className='d-flex' style={{ position: "relative" }} >
																<div style={{ display: "flex", position: 'absolute', top: "-20px", left: 0 }} onClick={() => setOpenClose((prev: any) => ({ ...prev, ["renewal"]: !prev?.renewal }))}>
																	<h4 className='cursor-pointer text-primary'>{newDailyJobDashboardStatesData?.renewalTicketData?.title ?? "Loading..."} <FontAwesomeIcon icon={openClose?.renewal ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' /></h4>
																</div >
																{
																	openClose?.renewal &&
																	newDailyJobDashboardStatesData?.renewalTicketData?.children?.map((element: any) => {
																		return <div className='col-xl-2 border-jobd job-section'>
																			<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} mainTitle={newDailyJobDashboardStatesData?.renewalTicketData?.title} />
																		</div>
																	})}
															</div>

														</>



												}

												{
													!newDailyJobDashboardStatesData?.welcomeCallTicketData ? <SkeletonLoader rowCount={5} title="Open Welcome Ticket" /> : <>
														<div className='d-flex' style={{ position: "relative" }} >
															<div style={{ display: "flex", position: 'absolute', top: "-20px", left: 0 }} onClick={() => setOpenClose((prev: any) => ({ ...prev, ["welcome"]: !prev?.welcome }))}>
																<h4 className='cursor-pointer text-primary'>{newDailyJobDashboardStatesData?.welcomeCallTicketData?.title ?? "Loading..."} <FontAwesomeIcon icon={openClose?.welcome ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' /></h4>
															</div >

															{
																openClose?.welcome && newDailyJobDashboardStatesData?.welcomeCallTicketData?.children?.map((element: any) => {
																	return <div className='col-xl-2 border-jobd job-section'>
																		<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} mainTitle={newDailyJobDashboardStatesData?.welcomeCallTicketData?.title} />
																	</div>
																})
															}

														</div >


													</>
												}

												{
													!newDailyJobDashboardStatesData?.prospectTicketsData ? <SkeletonLoader rowCount={5} title="Open Prospect Ticket" /> : <>
														<div className='d-flex' style={{ position: "relative" }} >
															<div style={{ display: "flex", position: 'absolute', top: "-20px", left: 0 }} onClick={() => setOpenClose((prev: any) => ({ ...prev, ["welcome"]: !prev?.welcome }))}>
																<h4 className='cursor-pointer text-primary'>{newDailyJobDashboardStatesData?.prospectTicketsData?.title ?? "Loading..."} <FontAwesomeIcon icon={openClose?.welcome ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' /></h4>
															</div >

															{
																openClose?.welcome && newDailyJobDashboardStatesData?.prospectTicketsData?.children?.map((element: any) => {
																	return <div className='col-xl-2 border-jobd job-section'>
																		<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} mainTitle={newDailyJobDashboardStatesData?.prospectTicketsData?.title} />
																	</div>
																})
															}

														</div >


													</>
												}

												{
													(pendingAgentProductivity || !newDailyJobDashboardStatesData?.agentProductivityData) ? <SkeletonLoader rowCount={5} title="Agent Productivity" /> : <>
														<div className='d-flex' style={{ position: "relative" }} >
															<div style={{ display: "flex", position: 'absolute', top: "-20px", left: 0 }} onClick={() => setOpenClose((prev: any) => ({ ...prev, ["agent"]: !prev?.agent }))}>
																<div className='d-flex justify-content-start align-items-center gap-2px mb-4'>
																	<h4 className='cursor-pointer text-primary me-3'>{newDailyJobDashboardStatesData?.agentProductivityData?.title ?? "Loading..."} <FontAwesomeIcon icon={openClose?.agent ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' />
																	</h4>

																	<div className="d-flex align-items-center justify-content-center">
																		<input
																			type="date"
																			value={startDate}
																			onChange={(e) => setStartDate(e.target.value)}
																			className="form-control me-2 mb-2"
																			style={{ height: "30px" }}
																			placeholder="Start Date"
																			onClick={(e) => e.stopPropagation()}
																		/>
																		<input
																			type="date"
																			value={endDate}
																			onChange={(e) => setEndDate(e.target.value)}
																			className="form-control me-2 mb-2"
																			style={{ height: "30px" }}
																			placeholder="End Date"
																			onClick={(e) => e.stopPropagation()}
																		/>
																		<span className="badge badge-primary py-2 px-3 mb-2" style={{ height: "20px", cursor: 'pointer', }} onClick={(e) => {
																			e.stopPropagation()
																			handleSubmit()
																		}}>
																			Submit
																		</span>
																	</div>
																</div>
															</div >

															{
																openClose?.agent && newDailyJobDashboardStatesData?.agentProductivityData?.children?.map((element: any) => {
																	return <div className='col-xl-2 border-jobd job-section'>
																		<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} mainTitle={newDailyJobDashboardStatesData?.agentProductivityData?.title} />
																	</div>
																})
															}

														</div >
													</>
												}

											</div>
										}

									</>
								}
							</Tab>

							<Tab eventKey={2} title="Ticket List" >
								<div className='w-125'>

									{
										key == 2 ? <NewTicketsList filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.newTicketList} /> : <div></div>
									}
								</div>
							</Tab>

							<Tab eventKey={4} title="New Renewal Subscription" >
								<div className='w-125'>
									{
										key == 4 ? <NewRenewalSuscriptionControllerRoute filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.newRenewalSubscriptionList} /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={5} title="Customer Leads" >
								<div className='w-125'>
									{
										key == 5 ? <VisitorsListV2 filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.customerLeadsFilter} /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={6} title="Customer Vehicles" >
								<div className='w-125'>
									{
										key == 6 ? <VehicleTypeListController filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.inactiveFilterList} /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={7} title="Champ Leads" >
								<div className='w-125'>
									{
										key == 7 ? <VisitorListChampV2 filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.champLeadsFilter} /> : <></>
									}
								</div>
							</Tab>
						</Tabs>
					</>
				}

			</KTCardBody >
		</>
	)
}
