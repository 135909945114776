
export const getColumns = (handleStatusUpdate?: any, statusList?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Amount',
    cell: (row: any) => row?.amount,
    sortField: 'amount',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Payment Status',
    cell: (row: any) => {
      if (row?.PaymentReceiptStatus?.status === 'Pending') {
        return (
          <select 
            className="form-select form-select-sm"
            onChange={(e) => handleStatusUpdate(row.id, e.target.value)}
            defaultValue="Pending"
          >
            <option value="Pending" disabled>Pending</option>
            {statusList
              ?.filter((status: any) => status.status !== 'Pending')
              .map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.status}
                </option>
            ))}
          </select>
        );
      } else {
        return (
          <span className={`badge badge-${row?.PaymentReceiptStatus?.status === 'Paid' ? 'success' : 'danger'}`}>
            {row?.PaymentReceiptStatus?.status}
          </span>
        );
      }
    },
    sortField: 'PaymentReceiptStatus.status',
    sortable: true,
    reorder: true,
    id: 9,
    width: '150px',
  },
  {
    name: 'Payment Method',
    cell: (row: any) => row?.paymentReceiptMethods?.name,
    sortField: 'paymentReceiptMethods.name',
    sortable: true,
    reorder: true,
    id: 10,
    width: '150px',
  },
  {
    name: 'Payment Date',
    cell: (row: any) => row?.payment_date,
    sortField: 'payment_date',
    sortable: true,
    reorder: true,
    id: 6,
    width: '120px',
  },
  {
    name: 'Media',
    cell: (row: any) => (
      <img 
        src={row?.media} 
        alt="" 
        style={{ width: "50px", height: "50px", cursor: "pointer" }} 
        onClick={() => window.open(row?.media, '_blank')}
      />
    ),
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Purchase Order Number',
    cell: (row: any) => row?.paymentReceiptPurchaseOrder?.purchase_order_id,
    sortField: 'paymentReceiptPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Proforma Invoice',
    cell: (row: any) => row?.paymentReceiptProformaInvoice?.proforma_invoice_id,
    sortField: 'paymentReceiptProformaInvoice.proforma_invoice_id',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Vendor Invoice Number',
    cell: (row: any) => row?.paymentReceiptVendorInvoice?.invoice_number,
    sortField: 'paymentReceiptVendorInvoice.invoice_number',
    sortable: true,
    reorder: true,
    id: 11,
    width: '120px',
  },
  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 4,
    width: '200px',
  },
]
