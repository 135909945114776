import moment from 'moment'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Vendor Invoice Number',
    cell: (row: any) => row?.invoice_number,
    sortField: 'invoice_number',
    sortable: true,
    reorder: true,
    id: 6,
    width: '200px',
  },
  {
    name: 'Items',
    cell: (row: any) => (
      <button
        className="btn btn-sm btn-primary"
        onClick={() => handleViewButtonClick(row?.id)}
      >
        <i className='fa fa-eye '></i>
      </button>
    ),
    sortable: false,
    reorder: true,
    id: 12,
    width: '120px',
  },
  {
    name: 'Media',
    cell: (row: any) => (
      <img 
        src={row?.media} 
        alt="" 
        style={{ width: "50px", height: "50px", cursor: "pointer" }} 
        onClick={() => window.open(row?.media, '_blank')}
      />
    ),
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Invoice Amount',
    cell: (row: any) => row?.invoice_amount,
    sortField: 'invoice_amount',
    sortable: true,
    reorder: true,
    id: 10,
    width: '120px',
  },
  {
    name: 'Purchase Order Number',
    cell: (row: any) => row?.vendorInvoicePurchaseOrder?.purchase_order_id,
    sortField: 'vendorInvoicePurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Invoice Date',
    cell: (row: any) => row?.invoice_date,
    sortField: 'invoice_date',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.VendorInvoiceStatus?.status,
    sortField: 'VendorInvoiceStatus.status',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'updatedAt',
    cell: (row: any) => moment(row?.updatedAt).format("MM-DD-YYYY"),
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 9,
    width: '120px',
  },


]
