import React, { useEffect, useState } from 'react'
import { customSortingCoumn, isAllowedChampNumber } from '../../consts/components/Utility'
import { getName, sortDateWithNull } from '../admins/adminUtils'
import AdminActiveOrInactive from '../admins/adminComponents/AdminActiveOrInactive'
import ListRowToolTip from './../admins/adminComponents/ListRowToolTip';
import MaskedPhoneNumber from '../../consts/MaskedPhoneNumber'
import Calling from '../../consts/Call/Calling';
import Layout from '../../consts/Call/Layout';

export const ChampAbsentListColumns = (data?: any) => [

    {
        id: 2,
        reorder: true,
        name: 'ID',
        selector: (row: { id: any }) => row.id,
        sortable: true,
        minWidth: "80px"
    },
    {
        id: 3,
        reorder: true,
        name: 'NAME',
        selector: (row: any) => <Calling title={getName(row)} row_id={row?.id} row={row} type="cleanerid" From={Layout} isOpen={true} source="champ_absent_list"/>,
        sortable: true,
    },
    {
        id: 4,
        reorder: true,
        name: 'PHONE',
        selector: (row: any) => isAllowedChampNumber(<MaskedPhoneNumber phoneNumber={row.phone} />, row?.id),
        sortable: true,
    },

]
