import { Modal } from "react-bootstrap"
import { getFullName } from "../../../utils/helper.util"
import OpenChat from "../../../consts/champChatDashboard/OpenChat"

const ChampDetailModal = ({rowData, show, setShow}: {rowData: any, show: boolean, setShow: any}) => {
    console.log('rowData', rowData)
    return (
        <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="small-modal" 
        centered
        key={"ChampDetailModal"}
        size="lg"
        className=""
    >
        <div style={{boxShadow: "0 0 10px rgba(0,0,0,0.2)"}}>
            <Modal.Header closeButton style={{backgroundColor: "#f8f9fa"}}>
                <Modal.Title>Available Champ Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Champ</th>
                                <th scope="col">Workload &lt; 80%</th>
                                <th scope="col">Same Society</th>
                                <th scope="col">Repeated Champ</th>
                                <th scope="col">Champ Availability</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData?.inRangeChamps?.map((champ: any) => {
                                return <tr>
                                    <td>
                                        <OpenChat 
                                            title={getFullName(champ)} 
                                            champId={champ?.id} 
                                        />
                                    </td>
                                    <td>{champ?.withInLoadPercentage ? "Yes" : "No"}</td>
                                    <td>{champ?.availableInSociety ? "Yes" : "No"}</td>
                                    <td>{champ?.isRepeatChamp ? "Yes" : "No"}</td>
                                    <td>{champ?.champAvailabilities?.map((elm: any) => elm.champAvailabilityTimeslot?.name).join(", ")}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
            </Modal.Body>
        </div>
    </Modal >
    )
}

export default ChampDetailModal