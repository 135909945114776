import React, { useState, useEffect, useRef } from 'react'
import { LocalBaseURL } from '../../../BaseURLmanagement'
import { newColumns } from './NewColumns'
import './custom.css'
import { KTCardBody } from '../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import {
  getCurrentFilterHandler,
  getFilterDataBySearch,
  initialPayload,
  operators,
  removeFilterHandler,
} from '../../consts/DynamicFilteration/components/CommonData'
import {
  useAdminListApiUrl,
  useAdminManagementPermission,
  useAdminSmartFilterList,
} from '../admins/adminUtils'
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents'
import { DynamicFilter } from '../../consts/DynamicFilteration/FilterHandler'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import axios from 'axios'
import ProductModal from './ProductModal'
import * as XLSX from 'xlsx';
import { isAllowedUser } from '../../consts/components/Utility'
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal'
import RightBox from '../../consts/mediaQueryLayout/RightBox'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton'
import CreateMaterialDeliverTask from './CreateMaterialDeliverTask'

const NewOrderListMain = () => {
  LocalBaseURL()
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0)
  const [perPageSize, setPerPageSize] = useState(10)
  const [direction, setdirections] = useState<any>('')
  const [payload, setPayload] = useState<any>(initialPayload())
  const [filter, setFilter] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  console.log('filteredSelectedRows', filteredSelectedRows);
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [search, setSearch] = useState<any>('')
  const [filterkey, setFilterKey] = useState<any>({})
  const [sortby, setSortBy] = useState<any>('')
  const [page, setPages] = useState<any>(1)
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>('')
  const [dynFilter, setDynFilter] = useState<any>('')
  const [productData, setProductData] = useState<any>([])
  const [showProductModal, setShowProductModal] = useState<boolean>(false)
  const [sortedColumns, setSortedColumns] = useState<any>(newColumns())

  const isUserAllowed = isAllowedUser?.includes(Number(localStorage.getItem("user")));

  async function InvokedRendered(payloads: any, setloading: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const { data } = await postRequest(
      URL_EndPoints(null)?.getAllNewInventoryOrdersPagination,
      payload,
      setloading
    )

    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.inventoryOrdersPagination)
    setPending(false)
  }

  const handleViewButtonClick = async (orderid: any) => {
    const { data } = await getRequest(URL_EndPoints(null)?.getInventoryOrderById + '/' + orderid, setPending)
    setProductData(data.data.inventoryOrderedItems)
    setShowProductModal(true)
  }

  useEffect(() => {
    if (filterData) {

      const newCol = newColumns(handleViewButtonClick, filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newCol);
    }
  }, [filterData]);


  const handleCloseModal = () => {
    setShowProductModal(false)
  }

  const onChangeHandler = (
    event: any,
    key: any,
    value: any,
    defVal: any = false,
    timestamp: any,
    multiVal: any
  ) => {

    setPayload({
      ...payload,
      filterData: {
        ...payload.filterData,
        [key]: {
          ...payload.filterData[key],
          [value]: event?.value,
          [key + '_' + value]: defVal ? event : '',
          isDate: defVal ? false : true,
          timestamp: timestamp === 'null' ? undefined : timestamp,
          multiVal: multiVal,
        },
      },
    })
  }

  const handlePagination = (
    search: any = '',
    sortBy = '',
    sortDirection: any = 'desc',
    page: any = 1,
    perPageSize: any = 10
  ) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(
      handlePagination(search, sortby, direction, page, perPageSize),
      setPending
    )
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(
      key,
      currentItemRemovedOrAdded,
      setFilterKey,
      setSelectedFilterData,
      selectedFilterData,
      setStoreFilterList,
      storeFilterList,
      payload,
      setPayload
    )
  }

  const handleSort = async (column: any, sortDirection: any) => {

    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(
      handlePagination(search, column.sortField, sortDirection, page, perPageSize),
      setPending
    )
  }

  const handlePageChange = (page: any) => {

    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {

    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  }

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search &&
        InvokedRendered(
          handlePagination(search && search, sortby, direction, page, perPageSize),
          setPending
        )
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {

    !search &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [search])

  const isFirstRender = useRef(true)
  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    selectedFilterData?.length === 0 &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [selectedFilterData?.length])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords &&
        (await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
          keyword: storeSearchKeywords,
        }))
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList('getAllNewInventoryOrdersPagination')
  }, [])

  const exportToExcel = (filterData: any[], fileName = 'export.xlsx') => {
    // handling the export data
    const transformedData = filterData.flatMap((order) => {
      const baseData = {
        id: order.id,
        ordered_by_id: order.ordered_by_id,
        order_date: order.order_date,
        cleaner_first_name: order.inventoryOrderCleanerBy?.first_name || '',
        cleaner_last_name: order.inventoryOrderCleanerBy?.last_name || '',
        champ_first_name: order.inventOryorderCleanerChamp?.first_name || '',
        champ_last_name: order.inventOryorderCleanerChamp?.last_name || '',
        area_manager_first_name: order.inventOryorderCleanerChamp.champSupervisor.first_name || '',
        area_manager_last_name: order.inventOryorderCleanerChamp.champSupervisor.last_name || ''
      };

      return (order.inventoryOrderedItems || []).map((item: any) => ({
        ...baseData,
        item_id: item.item_id,
        quantity: item.quantity,
        inventory_item_title: item.inventoryOrderedItemMaster?.title || '',
        inventory_item_description: item.inventoryOrderedItemMaster?.description || ''
      }));
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };
  const [showCreateDeliverTask, setShowCreateDeliverTask] = useState(false)
  return (
    <>
      <KTCardBody className='card py-4' key='inventoryOrderListKey'>
        <AdminServerSidePaginationLists
          title='New Inventory Order List'
          reorderColumnTitle='inventoryOrderListKey'
          sortedColumns={sortedColumns}
          filterData={filterData}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          conditionalRowStyles={{}}
          handleSort={handleSort}
          keyName={'inventoryOrderListKey'}
          search={search}
          setSearch={setSearch}
          paginationPerPage={perPageSize}
          showFilter={true}
          ExpandedComponent={() => <></>}
          expandableRows={false}
          filterHandler={{
            onChangeHandler: onChangeHandler,
            actionHandler: actionHandler,
            getCurrentFilter: getCurrentFilter,
            removeFilter: removeFilter,
            payload: payload,
            Component: DynamicFilter,
            filterData: filter,
            OperatorsData: operators,
            selectedFilterData,
            filterComponents: KeywiseComponents,
            storeFilterList: storeFilterList,
            filterkey: filterkey,
            setSeacrch: setSearchFilter,
            isLoadingFilter: isLoadingFilter,
            listApiUrl: listApiUrl('unsuccessfullSubscriptionskey'),
          }}

          SubHeaderComponentRightSection={() => <>
            {isUserAllowed ? <button className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3 filterBtn' style={{ position: "relative" }} onClick={() => exportToExcel(filterData)}>
              Export
            </button> : ''}
          </>
          }

          SubHeaderComponentActionButtons={() => <>
            {
              filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                <BulkUtilButton title="Create Material Delivery Task" filteredSelectedRows={filteredSelectedRows} setShow={setShowCreateDeliverTask} />
              </BulkUtilButtonWrapper> : <></>
            }
          </>}
        />
      </KTCardBody>

      {
        showCreateDeliverTask ? <CreateMaterialDeliverTask
          show={showCreateDeliverTask}
          setShow={setShowCreateDeliverTask}
          refreshNow={null}
          filteredSelectedRows={filteredSelectedRows?.map((ele: any) => ele?.inventoryOrderedItems?.map((ele: any) => ({ ...ele, quantity_ordered: ele?.quantity })))?.flat()}

        /> : <></>
      }


      <ProductModal
        show={showProductModal}
        productData={productData}
        handleClose={handleCloseModal}
      />
    </>
  )
}

export default NewOrderListMain
