import React from 'react';

const NewOrderDate = ({ onChangeHandler, payload = "", name = "" }: any) => {
  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  // Get tomorrow's date in YYYY-MM-DD format
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split('T')[0];

  return (
    <input
      type='date'
      style={{ height: '38px' }}
      value={payload[name]}
      name={name}
      min={formattedToday} // Set minimum date to today
      max={formattedTomorrow} // Set maximum date to tomorrow
      onChange={(event: any) =>
        onChangeHandler(event.target, name, true)
      }
      className='w-100 form-control me-2 align-start'
    />
  );
};

export default NewOrderDate;
