
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { colWidthsHotTable, handleAfterSelectionRow, hotInstanceLoader, searchFieldKeyupCallback, postRequestByLocalUrl, getRequestByLocalUrl } from '../admins/adminUtils';

import moment from 'moment';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import MomentDate from '../../consts/MomentDate';
import ReviewCountsFilter from './ReviewCountsFilter';
import { ListHintReview } from './ListHintReview';

registerAllModules();
const intervalType = [{ name: "Daily", id: "day" }, { name: "Weekly", id: "week" }, { name: "Monthly", id: "month" }]
const defaultRowsIDs = [1, 2]

export const ReviewCounts = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [agentListData, setAgentListData] = useState<any>([])
    const [rowsFilterData, setRowsFilterData] = useState<any>([])
    const [formList, setFormList] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: MomentDate(0),
        interval: "day",
        selectedDisplayValues: defaultRowsIDs,
        form_id: [],
        agent_id: [],
        reviewer_id: [],
    })

    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])


    async function getReviewCountLists() {
        setFilteredSelectedRows([])
        const reviewCountList = await postRequestByLocalUrl("https://analyticsapi.carselonadaily.com/api/v1/analytics/form/getReviewCountData", payload, null)
        // const reviewCountList = await postRequestByLocalUrl("http://localhost:7000/api/v1/analytics/form/getReviewCountData", payload, setPending)
        setColumns(reviewCountList?.data?.columns || [])
        setFilterData(reviewCountList?.data?.data || [])
        setData(reviewCountList?.data?.data || [])
        setPending(false)
    }

    const getActionsList = async () => {
        setPending(true)
        const getFormRows = await getRequestByLocalUrl("https://analyticsapi.carselonadaily.com/api/v1/analytics/form/getFormRows", null)
        setRowsFilterData(getFormRows?.data?.data || [])

    }

    const getActionsListOther = async () => {
        const agentList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
        const getFormsList = await getRequestByLocalUrl("https://adminapi.carselonadaily.com/api/admin/form/getForms", null)
        setAgentListData(agentList?.data?.data)
        setFormList(getFormsList?.data?.data?.forms || [])
    }

    useEffect(() => {
        getActionsList()
    }, [])

    useEffect(() => {
        getReviewCountLists()
        getActionsListOther()
    }, [])

    const searchHandler = () => {
        getReviewCountLists()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);
    const cellRenderInner = (args: any, Handsontable: any) => {
        const [instance, td, row, col, prop, value, cellProperties] = args
        Handsontable.renderers.TextRenderer.apply(this, args)
        if (row % 2 == 1 && col > 1) {
            if (value < 0) {
                td.style.backgroundColor = '#870000'
                td.style.color = 'white'
            } else if (value >= 5 && value <= 10) {
                td.style.backgroundColor = 'green'
                td.style.color = 'white'
            } else {
                td.style.backgroundColor = '#7cc8ff'
                td.style.color = 'white'
            }
        } else if (value > 0) {
            td.style.backgroundColor = '#ecedf1'
        }
    }
    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 2) { return removeUnderScore(sentence) }
        else if (index == 1 || sentence?.includes("_")) { removeUnderScore(sentence) }
        return removeUnderScore(sentence?.split(' ')[1])
    }

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };


    return (
        <>
            <KTCardBody>
                <div className='mb-4 fs-4 fw-semibold'>Quality Scores</div>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                        {
                            <DropDownFilterMenu
                                ParentData={{
                                    handleActions: searchHandler,
                                    payloads: payload,
                                    onChangeHandler: onChangeHandler,
                                    intervalType: intervalType,
                                    agentListData: agentListData,
                                    rowsFilterData: rowsFilterData,
                                    formList: formList,
                                    defaultInterval: { label: "Daily", value: "day" },
                                    defaultRows: rowsFilterData?.filter((ele: any) => defaultRowsIDs?.includes(ele.id))?.map((ele: any) => ({ label: ele?.name, value: ele?.id })),
                                    showListType: true
                                }}
                                Component={ReviewCountsFilter}
                            ></DropDownFilterMenu>
                        }
                    </RightBox>
                </MainContainerWrapper>

                <ListHintReview payload={payload} />
                {/* hint components */}

                {pending ? <IsLoading /> : <div >

                    <HotTable
                        ref={hotTableComponentRef}
                        style={{ zIndex: 1 }}
                        data={filterData}
                        columns={columns?.map((ele: any, index: any) => {
                            return {
                                title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                                type: 'text',
                                data: ele,
                                renderer: cellRenderer
                            }
                        })}
                        className='ReviewCounts'
                        filters={true}
                        dropdownMenu={true}
                        colWidths={colWidthsHotTable(columns, 2, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        title='Sales Counts'
                        rowHeaders={true}
                        colHeaders={true}
                        fixedColumnsLeft={2}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                    // afterDeselect={handleAfterDeselect}
                    />
                </div>}

            </KTCardBody>

            {columns?.map((ele: any) => (
                <ReactTooltip
                    anchorId={ele}
                    place={"top"}
                    className="bg-dark"
                    content={ele}
                />
            ))}

        </>
    );
};

