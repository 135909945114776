import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
let listType: any = {
    1: "area_manager_id",
    2: "created_by_id",
}


let intervalType: any = {
    day: "Day",
    week: "Weekly",
    month: "Monthly",
}

export const ListHintStatus = ({ payload }: any) => {
    const remvoveUnderScore = (str: any) => {
        let formattedStr = str
        .replace(/_id$/, '') 
        .replace(/_/g, ' ') 
        .replace(/\b\w/g, (char: string) => char.toUpperCase()); 
        return formattedStr
    }

    return (
        <div className='mb-4 d-flex justify-content-start align-items-center gap-3'>

            {
                "startDate" in payload && <div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Date: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.startDate} <span className='fw-bold text-danger ms-2 me-3'><FontAwesomeIcon icon={faArrowRight} className='text-danger' /></span> {payload?.endDate}</span>
                </div>
            }
            {
                "interval" in payload && < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Internal: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload?.interval ? intervalType[payload?.interval] : "Not Selected"}</span>
                </div>
            }

            {
                "area_manager_id" in payload && < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                    <span className='fw-bold fs-7 text-primary me-2'>Default AM/CreatedBy: 👉 </span>
                    <span className='fw-bold fs-7 '>{payload[listType[payload?.list_type_id]] && payload[listType[payload?.list_type_id]]?.length == 0 ? "All" : remvoveUnderScore(listType[payload?.list_type_id])}</span>

                </div>
            }



        </div >
    )
}

