import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getName } from '../../admins/adminUtils'
import ViewGoodReceiptItems from '../viewItemsList/ViewGoodReceiptItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { GoodItemListColumn } from '../viewItemsList/GoodItemListColumn'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { getRequest } from '../../../consts/Server/Requests'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },{
    name: 'Goods Receipt Number',
    cell: (row: any) => row?.goods_receipt_id,
    sortField: 'goods_receipt_id',
    sortable: true,
    reorder: true,
    id: 13,
    width: '160px',
  },  
  {
    name: 'Goods Items',
    cell: (row: any) => <GoodReceiptItems row={row} />,
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 11,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.goodsReceiptStatus?.status,
    sortField: 'goodsReceiptStatus.status',
    sortable: true,
    reorder: true,
    id: 8,
    width: '160px',
  },
  {
    name: 'Media',
    cell: (row: any) => (
      <img 
        src={row?.media} 
        alt="" 
        style={{ width: "50px", height: "50px", cursor: "pointer" }} 
        onClick={() => window.open(row?.media, '_blank')}
      />
    ),
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Purchase Order Number',
    cell: (row: any) => row?.goodsReceiptPurchaseOrder?.purchase_order_id,
    sortField: 'goodsReceiptPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Received Date',
    cell: (row: any) => row?.received_date,
    sortField: 'received_date',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Received By',
    cell: (row: any) => getName(row?.goodsReceiptAdAdmin),
    sortField: 'goodsReceiptAdAdmin.first_name',
    sortable: true,
    reorder: true,
    id: 6,
    width: '200px',
  },
  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 4,
    width: '150px',
  },
  {
    name: 'updatedAt',
    cell: (row: any) => moment(row?.updatedAt).format("MM-DD-YYYY"),
    sortField: 'updatedAt',
    sortable: true,
    reorder: true,
    id: 12,
    width: '120px',
  },


]

const GoodReceiptItems = ({ row }: any) => {
  const [show, setShow] = useState(false)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const handleViewItems = async () => {
    try {
      setLoading(true)
      const response = await getRequest(URL_EndPoints()?.getAllGoodsItems + '/' + row.id, null)
      setItems(response?.data?.data)
      setShow(true)
    } catch (error) {
      console.error('Error fetching goods items:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <span>
        <FontAwesomeIcon 
          className='me-2 ms-2 text-muted fs-4 cursor-pointer' 
          icon={faEye} 
          onClick={handleViewItems} 
        />
      </span>
      {show && (
        <ViewGoodReceiptItems
          title="Good Receipt Items"
          show={show}
          setShow={setShow}
          listData={items}
          modalLoader={loading}
          columns={GoodItemListColumn()}
        />
      )}
    </>
  )
}