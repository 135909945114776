import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import { toast } from 'react-toastify';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import axios from 'axios';

const AddUpdateVendorInvoice = ({ show, refreshNow, setShow, setData, initialValue, setInitialValue, apiUrl }: any) => {
    const [loading, setLoading] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [file, setFile] = useState<any>(null);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [vendorInvoiceHistory, setVendorInvoiceHistory] = useState<any[]>([]);
    const [proformaLoader, setProformaLoader] = useState(false);
    const [proformaOrderList, setProformaOrderList] = useState([]);
    const [vendorInvoiceLoader, setVendorInvoiceLoader] = useState(false);
    const [paymentReceiptList, setPaymentReceiptList] = useState<any[]>([]);


    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchaseOrderList] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                ]);
                setPurchaseOrderList(purchaseOrderList?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    const handleInputChange = async (e: any) => {
        if (e.target.name === "payment_receipt_id") {
            const selectedOptions = Array.from(e.target.selectedOptions, (option: any) => parseInt(option.value));
            
            setInitialValue({
                ...initialValue,
                [e.target.name]: selectedOptions
            });
        } else if (e.target.name === "purchase_order_id") {
            // Reset form values when purchase order changes
            setInitialValue({
                ...initialValue,
                [e.target.name]: e.target.value,
                invoice_number: '',
                invoice_amount: '',
                proforma_invoice_id: '',
                payment_receipt_id: '',
                notes: ''
            });
            
            setSelectedItems([]);
            setProformaOrderList([]);
            setVendorInvoiceHistory([]);
            setPaymentReceiptList([]);
            
            if (!e.target.value) {
                return;
            }
            
            setProformaLoader(true);
            try {
                // Fetch proforma invoices for the selected purchase order
                const proformaResponse = await getRequest(
                    URL_EndPoints()?.getProformaByPurchaseOrderId + '/' + e.target.value, 
                    null
                );
                
                if (proformaResponse?.data?.data) {
                    setProformaOrderList(proformaResponse.data.data);
                } else {
                    toast.warning('No proforma invoices found for this purchase order');
                }
            } catch (error) {
                console.error('Error fetching proforma invoices:', error);
            } finally {
                setProformaLoader(false);
            }
    
        } else if (e.target.name === "proforma_invoice_id") {
            // Handle proforma invoice selection
            setInitialValue({
                ...initialValue,
                [e.target.name]: e.target.value,
                payment_receipt_id: ''
            });
    
            setSelectedItems([]);
            setPaymentReceiptList([]);
    
            if (!e.target.value) {
                return;
            }
    
            setVendorInvoiceLoader(true);
            try {
                // Fetch all required data in parallel
                const [itemsResponse, invoiceResponse, paymentReceiptsResponse] = await Promise.all([
                    getRequest(URL_EndPoints()?.getAllProformaInvoiceItems + '/' + e.target.value, null),
                    getRequest(URL_EndPoints()?.getVendorInvoiceByProformaInvoiceId + '/' + e.target.value, null),
                    getRequest(URL_EndPoints()?.getPaymentReceiptByProformaInvoiceId + '/' + e.target.value, null)                    
                ]);
    
                // Handle payment receipts
                if (paymentReceiptsResponse?.data?.data) {
                    setPaymentReceiptList(paymentReceiptsResponse.data.data);
                } else {
                    toast.info('No payment receipts found for this proforma invoice');
                }
    
                // Handle items response
                if (!itemsResponse?.data?.data) {
                    toast.error('No items found in this proforma invoice');
                    return;
                }
    
                const items = itemsResponse.data.data;
                const invoiceHistory = invoiceResponse?.data?.data?.itemSummary || [];

                const formattedItems = items.map((item: any) => {
                    const summaryItem = invoiceHistory.find((invoice: any) => invoice.item_id === item.item_id);
                    const quantityAlreadyInvoiced = summaryItem?.quantity_invoiced || 0;
                    const amountAlreadyInvoiced = summaryItem?.item_amount || 0;

                    return {
                        item_id: item.item_id,
                        title: item.proformaInvoiceItemsList.title,
                        quantity_ordered: item.quantity_invoiced,
                        quantity_invoiced: '',
                        quantity_remaining: item.quantity_invoiced - quantityAlreadyInvoiced,
                        price_remaining: Number(item.total_price) - Number(amountAlreadyInvoiced),
                        unit_price: '',
                        gst_percentage: 0,
                        item_amount: 0,
                        tax_amount: 0,
                        total_price: 0
                    };
                });
    
                setSelectedItems(formattedItems);
    
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setVendorInvoiceLoader(false);
            }
    
        } else if (e.target.name === "invoice_amount") {
            // Handle invoice amount input
            const value = e.target.value;
            if (value < 0) {
                toast.error('Invoice amount cannot be negative');
                return;
            }
            setInitialValue({
                ...initialValue,
                [e.target.name]: value
            });
    
        } else {
            // Handle all other inputs
            setInitialValue({
                ...initialValue,
                [e.target.name]: e.target.value,
            });
        }
    };

    const [fileSelected, setFileSelected] = useState<boolean>(false);
    
    // const [file, setFile] = useState<any>(null);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleQuantityChangeReceived = (name: string, value: any, index: number) => {
        const updatedItems = [...selectedItems];

        if (name === "quantity_invoiced") {
            const numValue = Number(value);
            const maxQuantity = updatedItems[index].quantity_remaining;
            
            if (numValue < 0) {
                toast.error('Quantity cannot be negative');
                return;
            }
            
            if (numValue > maxQuantity) {
                toast.error(`Quantity cannot exceed remaining quantity (${maxQuantity})`);
                return;
            }
            updatedItems[index].quantity_invoiced = value;
            updatedItems[index].quantity_ordered = numValue;
    
        }
        
        if (["quantity_ordered", "unit_price", "gst_percentage", "item_amount", "tax_amount", "total_price"].includes(name)) {
        const numValue = Number(value);
            if (numValue < 0) {
                return;
            }
            updatedItems[index][name] = numValue;
        }
    
        if (!["item_amount", "tax_amount", "total_price"].includes(name)) {
            const quantity = updatedItems[index].quantity_ordered;
            const unitPrice = updatedItems[index].unit_price;
            const gstPercentage = updatedItems[index].gst_percentage;
            
            const itemAmount = quantity * unitPrice;
            const taxAmount = (itemAmount * gstPercentage) / 100;
            const totalAmount = itemAmount + taxAmount;
            
            updatedItems[index] = {
                ...updatedItems[index],
                item_amount: Number(itemAmount.toFixed(2)),
                tax_amount: Number(taxAmount.toFixed(2)),
                total_price: Number(totalAmount.toFixed(2))
            };
        }
    
        setSelectedItems(updatedItems);
        
        // Update form total amount
        const totalFormAmount = updatedItems.reduce((sum, item) => sum + item.total_price, 0);
        setInitialValue({
            ...initialValue,
            invoice_amount: Number(totalFormAmount.toFixed(2))
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        
        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('Please select at least one item');
            return;
        }
    
        // Validate required fields
        const requiredFields = ['purchase_order_id', 'invoice_number', 'invoice_date'];
        for (const field of requiredFields) {
            if (!initialValue[field]) {
                toast.error(`Please fill in ${field.replace(/_/g, ' ')}`);
                return;
            }
        }
    
        // Validate items have required values
        const invalidItems = selectedItems.some(item => 
            !item.unit_price || 
            item.total_price <= 0
        );
    
        if (invalidItems) {
            toast.error('Please fill in all required item details');
            return;
        }
    
        setLoading(true);
        try {
            let payload = { ...initialValue };
            
            if (typeof (file) === "string" || !file) {
                delete payload.media;
            } else {
                payload.media = file;
            }
    
            // Add items list to payload
            payload.item_list = JSON.stringify(selectedItems);
    
            const response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], payload, setLoading);
            adminToastRunner(response);
            
            if (response?.data?.data?.items) {
                setData(response?.data?.data?.items);
            }
    
            refreshNow && refreshNow();
            setShow && setShow(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to submit form');
        } finally {
            setLoading(false);
        }
    };

    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + Number(item.total_price), 0);
    };

    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Vendor Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchaseOrderList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="proforma_invoice_id">
                                <Form.Label><strong>Select Proforma</strong></Form.Label>
                                {
                                    proformaLoader ? <IsLoading /> : <Form.Control
                                        as="select"
                                        name="proforma_invoice_id"
                                        value={initialValue["proforma_invoice_id"] || []}
                                        onChange={handleInputChange}
                                        disabled={!initialValue.purchase_order_id}
                                        required
                                    >
                                        <option value="">Select Proforma</option>
                                        {proformaOrderList.map((item: any) => (
                                            <option key={item.id} value={item.id}>
                                                {item.proforma_invoice_id ? item.proforma_invoice_id : `${(item.proforma_invoice_id)} | NA`}
                                            </option>
                                        ))}
                                    </Form.Control>
                                }
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="payment_receipt_id">
                                <Form.Label><strong>Select Payment Receipt(s)</strong></Form.Label>
                                {vendorInvoiceLoader ? (
                                    <IsLoading />
                                ) : (
                                    <Dropdown>
                                        <Dropdown.Toggle 
                                            variant="light" 
                                            style={{ 
                                                width: '100%', 
                                                textAlign: 'left',
                                                backgroundColor: 'white',
                                                border: '1px solid #ced4da'
                                            }}
                                            disabled={!initialValue.proforma_invoice_id}
                                        >
                                            {initialValue.payment_receipt_id?.length 
                                                ? `${initialValue.payment_receipt_id.length} selected`
                                                : "Select Payment Receipt"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ width: '100%' }}>
                                            {paymentReceiptList.map((item: any) => (
                                                <Dropdown.Item 
                                                    key={item.id} 
                                                    onClick={() => {
                                                        const currentSelected = initialValue.payment_receipt_id || [];
                                                        const newSelected = currentSelected.includes(item.id)
                                                            ? currentSelected.filter((id: number) => id !== item.id)
                                                            : [...currentSelected, item.id];
                                                        
                                                        handleInputChange({
                                                            target: {
                                                                name: 'payment_receipt_id',
                                                                selectedOptions: newSelected.map((id: any) => ({ value: id }))
                                                            }
                                                        });
                                                    }}
                                                    style={{ 
                                                        backgroundColor: 'transparent',
                                                        position: 'relative',
                                                        paddingLeft: '30px'
                                                    }}
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={initialValue.payment_receipt_id?.includes(item.id)}
                                                        style={{
                                                            position: 'absolute',
                                                            left: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)'
                                                        }}
                                                        readOnly
                                                    />
                                                    {item.payment_receipt_id ? item.payment_receipt_id : `${item.id}`}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="invoice_number">
                                <Form.Label><strong>Invoice Number</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Invoice Number"  
                                    name="invoice_number"
                                    value={initialValue['invoice_number']}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">

                        <Form.Group as={Col} className="mb-3" controlId="invoice_date">
                                <Form.Label><strong>Invoice Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Invoice Date"
                                    name="invoice_date"
                                    value={initialValue['invoice_date']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="invoice_amount">
                                <Form.Label><strong>Invoice Amount</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Invoice Amount"
                                    name="invoice_amount"
                                    value={initialValue['invoice_amount']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                        </Row>

                        {selectedItems?.length > 0 && selectedItems
                            .filter(ele => ele?.quantity_remaining > 0)
                            .map((ele, index) => (
                                <div key={ele?.id || `item-${index}`} className="mb-4 p-3" style={{
                                    border: '2px solid #000',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}>
                                    <div className="mb-3 d-flex justify-content-between align-items-center"
                                        style={{
                                            backgroundColor: '#ffe6e6',
                                            padding: '8px',
                                            borderRadius: '4px'
                                        }}>
                                        <div className="text-danger fw-bold">
                                            {ele?.title} (ID: {ele?.item_id})
                                        </div>
                                        <Button 
                                            variant="danger" 
                                            size="sm"
                                            onClick={() => {
                                                const updatedItems = selectedItems.filter((_, i) => i !== index);
                                                setSelectedItems(updatedItems);
                                                
                                                // Update form total amount
                                                const totalFormAmount = updatedItems.reduce((sum, item) => sum + Number(item.total_price), 0);
                                                setInitialValue({
                                                    ...initialValue,
                                                    invoice_amount: Number(totalFormAmount.toFixed(2))
                                                });
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </div>
    
                                    <div className="d-flex gap-3">
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small>
                                                        <strong>Qty Invoiced</strong>
                                                        <div className="text-danger">
                                                            (remaining: {ele?.quantity_remaining})
                                                        </div>
                                                    </small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="quantity_invoiced"
                                                value={ele?.quantity_invoiced || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                                size="sm"
                                            />
                                        </div>
    
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Unit Price</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="unit_price"
                                                value={ele?.unit_price || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>GST %</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="gst_percentage"
                                                value={ele?.gst_percentage || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>
    
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Item Amount</strong>
                                                    <div className="text-danger">
                                                        (remaining: {ele?.price_remaining})
                                                    </div>
                                                    </small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="item_amount"
                                                value={ele?.item_amount || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>
    
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Tax Amount</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="tax_amount"
                                                value={ele?.tax_amount || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>
    
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '50px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Total Amount</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="total_price"
                                                value={ele?.total_price || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                        <Row className="mb-3">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdateVendorInvoice;
