
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { colWidthsHotTable, handleAfterSelectionRow, hotInstanceLoader, searchFieldKeyupCallback, postRequestByLocalUrl, getRequestByLocalUrl } from '../admins/adminUtils';

import moment from 'moment';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import MomentDate from '../../consts/MomentDate';
import JobStatusCountsFilter from './JobStatusCountsFilter';
import { ListHintStatus } from './ListHintStatus';

registerAllModules();
const intervalType = [{ name: "Daily", id: "day" }, { name: "Weekly", id: "week" }, { name: "Monthly", id: "month" }]
const defaultRowsIDs = [2, 10]
const defaultTaskIds = [3, 4, 6, 9, 10, 7]

export const JobStatusCounts = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [trainingLocationList, setTrainingLocationList] = useState<any>([])
    const [areaManagerList, setAreaManagerList] = useState<any>([])
    const [adminListData, setAdminListData] = useState<any>([])
    const [taskTypeList, setTaskTypeList] = useState<any>([])
    const [rowsFilterData, setRowsFilterData] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: MomentDate(0),
        interval: "day",
        selectedDisplayValues: defaultRowsIDs,
        task_type_id: defaultTaskIds,
        area_manager_id: [],
        created_by_id: [],
        training_location_id: [],
        list_type_id: 1
    })


    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])

    async function getJobStatusCountLists() {
        setFilteredSelectedRows([])
        setPending(true)
        const jobStatusCountList = await postRequestByLocalUrl("https://analyticsapi.carselonadaily.com/api/v1/analytics/task/getTaskListCounts", payload, setPending)
        // const jobStatusCountList = await postRequestByLocalUrl("http://localhost:7000/api/v1/analytics/task/getTaskListCounts", payload, null)
        setColumns(jobStatusCountList?.data?.columns || [])
        setFilterData(jobStatusCountList?.data?.data || [])
        setData(jobStatusCountList?.data?.data || [])
        setPending(false)
    }

    const getActionsList = async () => {
        const getTaskRows = await getRequestByLocalUrl("https://analyticsapi.carselonadaily.com/api/v1/analytics/task/getTaskRows", setPending)
        setRowsFilterData(getTaskRows?.data?.data || [])
    }

    const getActionsListOther = async () => {

        const getActiveAreaManagerList = await getRequest(URL_EndPoints(null)?.getActiveAreaManagerList, null)
        const getAllTrainingLocations = await getRequest(URL_EndPoints(null)?.getAllTrainingLocations, null)
        const getTaskTypes = await getRequest(URL_EndPoints(null)?.getTaskTypes, null)
        const getAdminList = await getRequest(URL_EndPoints(null)?.getAdminList, null)
        // const getTaskRows = await getRequestByLocalUrl("http://localhost:7000/api/v1/analytics/task/getTaskRows", null)
        setAreaManagerList(getActiveAreaManagerList?.data?.data)
        setTrainingLocationList(getAllTrainingLocations?.data?.data)
        setTaskTypeList(getTaskTypes?.data?.data || [])
        setAdminListData(getAdminList?.data?.data)

    }

    useEffect(() => {
        getJobStatusCountLists()
        getActionsList()
        getActionsListOther()
    }, [])

    const searchHandler = () => {
        getJobStatusCountLists()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);

    const cellRenderInner = (args: any, Handsontable: any) => {
        const [instance, td, row, col, prop, value, cellProperties] = args;
        Handsontable.renderers.TextRenderer.apply(this, args);

        if (value == "Total Counts") {
            td.style.backgroundColor = '#028a86';
            td.style.color = '#f9f9fa';
        }
        else if (value > 0) {
            td.style.backgroundColor = '#e8f0fe';
        }
    };

    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 2) { return removeUnderScore(sentence) }
        else if (index == 1 || sentence?.includes("_")) { removeUnderScore(sentence) }
        return removeUnderScore(sentence?.split(' ')[1])
    }

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };


    return (
        <>
            <KTCardBody>
                {/* <div className='mb-4 fs-4 fw-semibold'>Training Task Counts</div> */}
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                        {
                            <DropDownFilterMenu
                                ParentData={{
                                    handleActions: searchHandler,
                                    payloads: payload,
                                    onChangeHandler: onChangeHandler,
                                    listType: [{ name: "Area Manager", id: 1 }, { name: "Task Created By", id: 2 }],
                                    listTypeDefault: { label: "Area Manager", value: 1 },
                                    intervalType: intervalType,
                                    taskTypeList: taskTypeList,
                                    trainingLocationList: trainingLocationList,
                                    areaManagerList: areaManagerList,
                                    adminListData: adminListData,
                                    defaultSupervisor: { label: 'All', value: '' },
                                    defaultAdmin: { label: 'All', value: '' },
                                    rowsFilterData: rowsFilterData,
                                    defaultInterval: { label: "Daily", value: "day" },
                                    defaultTaskType: taskTypeList?.filter((ele: any) => defaultTaskIds?.includes(ele.id))?.map((ele: any) => ({ label: ele?.name, value: ele?.id })),
                                    defaultRows: rowsFilterData?.filter((ele: any) => defaultRowsIDs?.includes(ele.id))?.map((ele: any) => ({ label: ele?.name, value: ele?.id })),
                                    showListType: true
                                }}
                                Component={JobStatusCountsFilter}
                            ></DropDownFilterMenu>
                        }
                    </RightBox>
                </MainContainerWrapper>
                {/* hint components */}

                <ListHintStatus payload={payload} />

                {pending ? <IsLoading /> : <div >

                    <HotTable
                        ref={hotTableComponentRef}
                        style={{ zIndex: 1 }}
                        data={filterData}
                        columns={columns?.map((ele: any, index: any) => {
                            return {
                                title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                                type: 'text',
                                data: ele,
                                renderer: cellRenderer
                            }
                        })}
                        className='JobStatusCounts'
                        filters={true}
                        dropdownMenu={true}
                        colWidths={colWidthsHotTable(columns, 2, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        title='Job Status Counts'
                        rowHeaders={true}
                        colHeaders={true}
                        fixedColumnsLeft={3}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                    // afterDeselect={handleAfterDeselect}
                    />
                </div>}

            </KTCardBody>

            {columns?.map((ele: any) => (
                <ReactTooltip
                    anchorId={ele}
                    place={"top"}
                    className="bg-dark"
                    content={ele}
                />
            ))}

        </>
    );
};

