import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table, Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { adminParamsGenerator, adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faListCheck } from '@fortawesome/free-solid-svg-icons';

const AddUpdateGoodReceipt = ({ popUpPostFormOpen, refreshNow, handleClose, setData, initialValue, setInitialValue, apiUrl, initialAddEditPayload }: any) => {    
    const [loading, setLoading] = useState(false);
    const [purchageOrder, purchageOrderList] = useState([]);
    const [vendorInvoiceList, setVendorInvoiceList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [inventoryList, setAllInventoryList] = useState([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        setSelectedItems(
            initialValue?.item_list
        );
    }, [initialValue?.item_list]);

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);

    useEffect(() => {
        if (!initialValue['received_date']) {
            const today = new Date().toISOString().split('T')[0];
            setInitialValue((prev: any) => ({
                ...prev,
                received_date: today,
            }));
        }
    }, [initialValue, setInitialValue]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchageOrder, goodItem, adminList] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                    getRequest(URL_EndPoints()?.getAllInventoryItems, null),
                    getRequest(URL_EndPoints()?.getAdminList, null),
                ]);
                purchageOrderList(purchageOrder?.data?.data || []);
                setAllInventoryList(goodItem?.data?.data || []);
                setAdminList(adminList?.data?.data || []);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (popUpPostFormOpen) {
            fetchData();
        }
    }, [popUpPostFormOpen]);

    const [apiLoader, setApiLoader] = useState(false)
    const getDependedList = async () => {
        setApiLoader(true)
        const vendorInc = await getRequest(URL_EndPoints(adminParamsGenerator("getVendorInvoiceByPurchaseOrderId", initialValue?.purchase_order_id))?.getVendorInvoiceByPurchaseOrderId, setApiLoader)
        setVendorInvoiceList(vendorInc?.data?.data || []);
    }

    useEffect(() => {
        initialValue?.purchase_order_id && getDependedList()
    }, [initialValue?.purchase_order_id])

    const handleInputChange = (e: any) => {
        // When purchase order is selected, automatically fetch and display its items
        if (e.target.name === "purchase_order_id") {
            setInitialValue({
                ...initialValue,
                [e.target.name]: e.target.value,
            });
            
            // Clear any previously selected items
            setSelectedItems([]);
            
            // Fetch and auto-select items from the purchase order
            const fetchAndSelectItems = async () => {
                try {
                    const response = await getRequest(
                        URL_EndPoints()?.getAllPurchaseOrdersItems + '/' + e.target.value,
                        null
                    );
                    const items = response?.data?.data || [];
                    
                    // Automatically add all items to selectedItems
                    const formattedItems = items.map((item: any) => ({
                        item_id: item.item_id,
                        title: item.purchaseOrderInventoryItems.title,
                        quantity_ordered: item.quantity_ordered,
                        quantity_remaining: item.quantity_remaining,
                        unit_price: 0,
                        quantity_received: 0,
                        batch_number: '',
                        expiry_date: '',
                        total_price: 0
                    }));
                    
                    setSelectedItems(formattedItems);
                } catch (error) {
                    console.error('Error fetching purchase order items:', error);
                }
            };
    
            fetchAndSelectItems();
        } else {
            setInitialValue({
                ...initialValue,
                [e.target.name]: e.target.value,
            });
        }
    };
    
    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + Number(item.total_price), 0);
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
    };

    const [fileSelected, setFileSelected] = useState<boolean>(false);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('Please select at least one item');
            return;
        }

        let payload: any = {};
        for (let key in initialValue) {
            let value = initialValue[key];
            if (value) payload[key] = value;
        }

        if (typeof (file) == "string" || !file) {
            delete payload.media
        }
        else {
            payload.media = file
        }

        if (selectedItems.length > 0) {
            payload.item_list = JSON.stringify(selectedItems)
        }

        setLoading(true);
        let response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], payload, setLoading)

        adminToastRunner(response)
        if (response?.data?.data?.items) {
            setData(response?.data?.data?.items);
        }

        setSelectedItems && setSelectedItems([]);
        refreshNow && refreshNow();
        handleClose && handleClose();
    };

    const handleQuantityChangeReceived = (name: any, value: any, index: any) => {
        const updatedItems = [...selectedItems];
        
        if (name === "quantity_received") {
            const newQuantity = Number(value);
            const remainingQuantity = updatedItems[index].quantity_remaining;
    
            if (newQuantity > remainingQuantity) {
                toast.error(`Cannot receive more than remaining quantity (${remainingQuantity})`);
                return;
            }
            updatedItems[index].quantity_received = newQuantity;
            
            // Auto-calculate only if direct input wasn't made to calculated fields
            const quantity = newQuantity;
            const unitPrice = Number(updatedItems[index].unit_price) || 0;
            const gstPercentage = Number(updatedItems[index].gst_percentage) || 0;
            
            const itemAmount = quantity * unitPrice;
            const taxAmount = (itemAmount * gstPercentage) / 100;
            const totalAmount = itemAmount + taxAmount;
            
            updatedItems[index].item_amount = itemAmount;
            updatedItems[index].tax_amount = taxAmount;
            updatedItems[index].total_price = totalAmount;
        } 
        else if (name === "unit_price" || name === "gst_percentage") {
            updatedItems[index][name] = Number(value);
            
            // Auto-calculate only if direct input wasn't made to calculated fields
            const quantity = Number(updatedItems[index].quantity_received) || 0;
            const unitPrice = name === "unit_price" ? Number(value) : Number(updatedItems[index].unit_price) || 0;
            const gstPercentage = name === "gst_percentage" ? Number(value) : Number(updatedItems[index].gst_percentage) || 0;
            
            const itemAmount = quantity * unitPrice;
            const taxAmount = (itemAmount * gstPercentage) / 100;
            const totalAmount = itemAmount + taxAmount;
            
            updatedItems[index].item_amount = itemAmount;
            updatedItems[index].tax_amount = taxAmount;
            updatedItems[index].total_price = totalAmount;
        }

        else if (["item_amount", "tax_amount", "total_price"].includes(name)) {
            updatedItems[index][name] = Number(value);
        }
        else if (name === "expiry_date") {
            updatedItems[index].expiry_date = value;
        }
        else {
            updatedItems[index][name] = value;
        }
    
        setSelectedItems(updatedItems);
    };
    
    return (
        <Modal size='lg' show={popUpPostFormOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Goods Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchageOrder.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="goods_receipt_id">
                                <Form.Label><strong>Goods Receipt Number</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Goods Receipt Number"
                                    name="goods_receipt_id"
                                    value={initialValue['goods_receipt_id']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="received_date">
                                <Form.Label><strong>Received Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Received Date"
                                    name="received_date"
                                    value={initialValue['received_date']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes']}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                        </Row>

                        {selectedItems?.length > 0 ? (
                            selectedItems
                            .filter(ele => ele?.quantity_remaining > 0)
                            .map((ele, index) => (
                                <div key={ele?.id || `item-${index}`} className="mb-4 p-3" style={{ 
                                    border: '2px solid #000',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}>
                                    <div className="mb-3 d-flex justify-content-between align-items-center"
                                    style={{ 
                                        backgroundColor: '#ffe6e6', 
                                        padding: '8px', 
                                        borderRadius: '4px' }}>
                                        <div className="text-danger fw-bold">
                                            {ele?.title} (ID: {ele?.item_id})
                                        </div>
                                        <Button 
                                            variant="danger" 
                                            size="sm" 
                                            onClick={() => handleRemoveItem(index)}
                                            style={{ padding: '4px 8px' }}
                                        >
                                            Remove
                                        </Button>
                                    </div>

                                    <div className="d-flex gap-3">
                            
                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Qty Ordered</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                value={ele?.quantity_ordered || ""}
                                                readOnly
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small>
                                                        <strong>Qty Received</strong>
                                                        <div className="text-danger">
                                                            (remaining: {ele?.quantity_remaining})
                                                        </div>
                                                    </small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="quantity_received"
                                                value={ele?.quantity_received || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                max={ele?.quantity_remaining}
                                                required
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '100px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Unit Price</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="unit_price"
                                                value={ele?.unit_price || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                required
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '80px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>GST %</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="gst_percentage"
                                                value={ele?.gst_percentage || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '100px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Item Amount</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="item_amount"
                                                value={ele?.item_amount || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '100px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Tax Amount</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="tax_amount"
                                                value={ele?.tax_amount || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '120px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Total Amount</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                name="total_price"
                                                value={ele?.total_price || 0}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>

                                        <div style={{ width: '130px' }}>
                                            <div style={{ height: '60px' }}>
                                                <Form.Label className="mb-2">
                                                    <small><strong>Expiry Date</strong></small>
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                type="date"
                                                name="expiry_date"
                                                value={ele?.expiry_date || ""}
                                                onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                size="sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <></>
                        )}

                        <div style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '16px' }} className='mb-4'>
                            Total Amount: {calculateTotalAmount().toFixed(2)}
                        </div>


                        <Row className="mb-5">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddUpdateGoodReceipt;
