import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getName } from '../../admins/adminUtils'
import moment from 'moment'
import { JOB_STATUSES_FORMATTED } from '../../../consts/champChatDashboard/constants/JobStatusLists'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 1,
    width: '80px',
  },
  {
    name: 'Task Name',
    cell: (row: any) => row?.taskType?.name,
    sortField: 'taskType.name',
    sortable: true,
    reorder: true,
    id: 2,
    width: '200px',
  },
  {
    name: 'Task Date',
    cell: (row: any) => row?.attendencedate,
    sortField: 'attendencedate',
    sortable: false,
    reorder: true,
    id: 3,
    width: '120px',
  },
  {
    name: 'Task Status',
    cell: (row: any) =>  JOB_STATUSES_FORMATTED[+row?.job_status],
    sortField: 'job_status',
    sortable: true,
    reorder: true,
    id: 4,
    width: '100px',
  },
  {
    name: 'Returned By',
    cell: (row: any) => getName(row?.attendenceCleaner),
    sortField: 'attendenceCleaner.first_name',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Accepted By',
    cell: (row: any) => getName(row?.attendencecleaner),
    sortField: 'attendencecleaner.first_name',
    sortable: true,
    reorder: true,
    id: 6,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.is_returned == 1 ? "accepted" : "pending",
    sortField: 'is_returned',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Created At',
    cell: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
    sortField: 'createdAt',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Action',
    cell: (row: any) => <span>
      <FontAwesomeIcon
        className='me-2 ms-2 text-muted fs-4 cursor-pointer'
        icon={faEye}
        onClick={() => handleViewButtonClick(row?.id, row?.returnedItems)}
      />
    </span>,
    sortable: true,
    reorder: true,
    id: 9,
    width: '120px',
  },

]