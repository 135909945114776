
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getRequest, postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { adminQueryGenerator, adminToastRunner } from '../../modules/admins/adminUtils';
import { MultipleCheckbox, MultipleRadio, SingleSelectBox, SingleTextArea, SingleTextBox } from './ReviewComponents';
import SkeletonLoader from '../IsLoading/SkeletonLoader';
import AdminSingleSelectFilter from '../../modules/admins/adminForms/adminFormComponents/AdminSingleSelectFilter';
import IsLoading from '../IsLoading/IsLoading';
interface ReviewDynamicFormModalProps {
  show: boolean;
  onHide: () => void;
  initialValue: {
    form_id: number;
    reference_id: number;
    isQuery?: boolean;
  };
}

const ReviewDynamicFormModal: React.FC<ReviewDynamicFormModalProps> = ({ show, onHide, initialValue }) => {
  const removeAnwerText = ["select", "radio", "checkbox"]
  const [fields, setFields] = useState<any>([]);
  const [adminList, setAdminList] = useState<any>([]);
  const [pending, setPending] = useState<any>(false);
  const [formLoader, setFormLoader] = useState<any>(false);
  const [adminId, setAdminId] = useState<any>(null);
  const [isMultiFormSelected, setIsMultiFormSelected] = useState<any>(null);
  const [isMultiFormSelectedDefault, setIsMultiFormSelectedDefault] = useState<any>(null);
  const [multi, setMulti] = useState<any>(false);
  const [multipleFormData, setMultipleFormData] = useState([])

  const getMult = async () => {
    setMulti(true)
    const response = await getRequest(
      URL_EndPoints(adminQueryGenerator("getFormsByFormType", { form_type: initialValue?.form_id }))?.getFormsByFormType,
      null
    );
    setMultipleFormData(response?.data?.data?.forms)
    let defaultValue = response?.data?.data?.forms?.length > 0 ? {
      label: response?.data?.data?.forms[0]?.form_name,
      value: response?.data?.data?.forms[0]?.id,
    } : null

    setIsMultiFormSelectedDefault(defaultValue)
    setIsMultiFormSelected(response?.data?.data?.forms?.length > 0 ? response?.data?.data?.forms[0]?.id : null)
    setMulti(false)
  }

  useEffect(() => {
    getMult()
  }, [show])

  const [formData, setFormData] = useState<any>({
    answers: fields?.questions?.map((ele: any) => (removeAnwerText?.includes(ele?.answer_type) ? {
      question_id: ele?.id,
      selected_option_ids: [],
    } : {
      question_id: ele?.id,
      answer_text: "",
    }))
  });

  useEffect(() => {
    if (isMultiFormSelected) {
      fetchFormFields();
    }
  }, [isMultiFormSelected]);

  const fetchFormFields = async () => {
    try {
      setPending(true)
      const response = await getRequest(
        URL_EndPoints(adminQueryGenerator("getAuditForm", { form_id: isMultiFormSelected }))?.getAuditForm,
        setPending
      );
      setFormData({
        answers: response?.data?.data?.formData?.questions?.map((ele: any) => (removeAnwerText?.includes(ele?.answer_type) ? {
          question_id: ele?.id,
          selected_option_ids: [],
        } : {
          question_id: ele?.id,
          answer_text: "",
        }))
      })

      if (response?.data?.data?.formData?.form?.has_multiple_executive == 1) {
        const adminData = await getRequest(
          URL_EndPoints(initialValue?.isQuery ? adminQueryGenerator("getAdminList", { ticketId: initialValue?.reference_id }) : null)?.getAdminList,
          null
        );
        setAdminList(adminData?.data?.data)
      }

      setFields(response?.data?.data?.formData || []);
    } catch (error) {
      console.error('Error fetching form fields:', error);
    }
  };

  const handleChange = (question_id: number, name: string, value: any) => {
    setFormData((prevData: any) => {
      const updatedAnswers = prevData.answers.map((answer: any) =>
        answer.question_id === question_id ? { ...answer, [name]: value } : answer
      );

      const existingQuestionIndex = updatedAnswers.findIndex((ans: any) => ans.question_id === question_id);
      if (existingQuestionIndex === -1) {
        updatedAnswers.push({
          question_id: question_id,
          [name]: value,
        });
      }

      return { ...prevData, answers: updatedAnswers };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setFormLoader(true)
    try {
      const filteredData = formData?.answers?.filter((item: any) => {
        return (item.answer_text && item.answer_text.trim() !== '') || (item.selected_option_ids && item.selected_option_ids.length > 0);
      });
      const newPayload: any = {
        form_id: fields?.form?.id,
        reference_id: initialValue?.reference_id,
        answers: filteredData
      }

      if (fields?.form?.has_multiple_executive == 1) {
        newPayload.submitted_for_user_id = adminId
      }

      const response = await postRequest(URL_EndPoints()?.submitAuditForm, newPayload, setFormLoader);
      adminToastRunner(response)
      onHide();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const FieldComponents: any = {
    text: SingleTextBox,
    textarea: SingleTextArea,
    checkbox: MultipleCheckbox,
    radio: MultipleRadio,
    select: SingleSelectBox
  };

  const renderField = (field: any) => {
    const Component = FieldComponents[field.answer_type];
    if (!Component) return <></>;
    return (
      <Component
        key={field.id}
        questionId={field.id}
        questionText={field.question_text}
        options={field.options}
        isRequired={field.is_required}
        onChangeHandler={handleChange}
        formData={formData}
      />
    );
  };

  const onChangeHandler = (event: any, name: any) => {
    setAdminId(event ? event?.value : event)
  }

  const onChangeFormType = (event: any, name: any) => {
    setIsMultiFormSelected(event ? event?.value : event)
  }

  return (
    <>
      {
        <Modal show={show} onHide={onHide} size="lg">
          <Modal.Header closeButton>
            {
              pending ? <SkeletonLoader rowCount={2} title="" /> : <Modal.Title>{fields?.form?.form_name || "Review Form"}</Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>


              {
                <div className='mb-4' key={"select_form_type"}>
                  <label htmlFor="Select Form Type"> Select Form Type</label>
                  {
                    multi ? <IsLoading /> : <AdminSingleSelectFilter
                      onChangeHandler={onChangeFormType}
                      HeaderTitle="Select Form Type"
                      SelectData={multipleFormData}
                      displayFields={["form_name"]}
                      id="id"
                      name="form_type_value"
                      defaultData={{ item: isMultiFormSelectedDefault }}
                      searchable={false}
                      isLoading={multi}
                    />
                  }
                </div>
              }


              {
                fields?.form?.has_multiple_executive == 1 ? <div className='mb-4' key={"select_admin"}>
                  <label htmlFor="Select Admin"> Select Admin</label>
                  <AdminSingleSelectFilter
                    onChangeHandler={onChangeHandler}
                    HeaderTitle="Select Admin"
                    SelectData={adminList}
                    displayFields={["first_name", "last_name", "phone"]}
                    id="id"
                    name="submitted_for_user_id"
                    defaultData={null}
                    searchable={false}
                  />
                </div> : <></>
              }

              {
                pending ? <SkeletonLoader rowCount={10} title="Review Form Getting Loaded" /> : <>

                  {fields?.questions?.map((field: any) => (
                    <Form.Group key={field.id} className="mb-4">
                      {renderField(field)}
                    </Form.Group>
                  ))}

                  <div className='text-end mt-4'>
                    <Button type="submit" variant={formLoader ? "success" : "primary"} disabled={formLoader}>
                      {
                        formLoader ? "Submiting..." : "Submit"
                      }
                    </Button>
                  </div>
                </>
              }
            </form>

          </Modal.Body>
        </Modal>
      }


    </>
  );
};

export default ReviewDynamicFormModal;



{/* <ReviewDynamicFormModal
show={showForm}
onHide={() => setShowForm(false)}
initialValue={{
  form_id: 1,
  reference_id: 1,
}}
/> */}