import React, { useState, useEffect, useRef } from 'react'
import { getColumns } from './GetColumns'
import '../custom.css'
import { KTCardBody } from '../../../../_metronic/helpers'
import { AdminServerSidePaginationLists } from '../../admins/adminServerSidePaginationLists/AdminServerSidePaginationLists'
import {
  getCurrentFilterHandler,
  getFilterDataBySearch,
  initialPayload,
  operators,
  removeFilterHandler,
} from '../../../consts/DynamicFilteration/components/CommonData'
import {
  adminParamsGenerator,
  adminQueryGenerator,
  useAdminListApiUrl,
  useAdminSmartFilterList,
} from '../../admins/adminUtils'
import { KeywiseComponents } from '../../../consts/DynamicFilteration/KeywiseComponents'
import { DynamicFilter } from '../../../consts/DynamicFilteration/FilterHandler'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'

import Refresh from '../../../consts/Refresh'
import ViewReturnTask from './ViewReturnTask'

const ReturnTaskList = ({ filterDataOutsource }: any) => {
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0)
  const [perPageSize, setPerPageSize] = useState(10)
  const [direction, setdirections] = useState<any>('')
  const [payload, setPayload] = useState<any>(initialPayload())
  const [filter, setFilter] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [search, setSearch] = useState<any>('')
  const [filterkey, setFilterKey] = useState<any>({})
  const [sortby, setSortBy] = useState<any>('')
  const [page, setPages] = useState<any>(1)
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>('')
  const [dynFilter, setDynFilter] = useState<any>('')
  const [productData, setProductData] = useState<any>([])
  const [showViewReturnTask, setshowViewReturnTask] = useState<boolean>(false)
  const [sortedColumns, setSortedColumns] = useState<any>(getColumns())
  const [modalLoader, setModalLoader] = useState(false);
  async function InvokedRendered(payloads: any, setloading: any) {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    setPending(true)
    const { data } = await postRequest(
      URL_EndPoints(null)?.getReturnTaskListPagination,
      payloads,
      setloading
    )

    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.proformaPagination)
    setPending(false)
  }
  const [taskStatus, setTaskStatus] = useState({})

  const getTaskStatus = async () => {

    const response = await getRequest(URL_EndPoints()?.getItemStatuses, null)


    const filterByCategory = (items: any, category: number) => {
      return items.reduce((acc: any, item: any) => {
        if (item.category_id === category) {
          acc.push(item);
        }
        return acc;
      }, []);
    };

    const filteredCat1 = filterByCategory(response?.data?.data, 1);
    const filteredCat2 = filterByCategory(response?.data?.data, 2);
    const filteredCat3 = filterByCategory(response?.data?.data, 3);

    setTaskStatus({
      cat_1: filteredCat1,
      cat_2: filteredCat2,
      cat_3: filteredCat3,
    })

  }

  useEffect(() => {
    getTaskStatus()
  }, [])

  const handleViewButtonClick = async (taskId: any, returnedItems: any) => {
    setshowViewReturnTask(true);
    // setModalLoader(true);
    // const { data } = await getRequest(URL_EndPoints(adminQueryGenerator("getReturnTaskDetails", { taskId: taskId }))?.getReturnTaskDetails, setModalLoader)
    setProductData(returnedItems);
  };

  useEffect(() => {
    if (filterData) {
      const newCol = getColumns(handleViewButtonClick, filterData?.map((ele: any) => ele?.id));
      setSortedColumns(newCol);
    }
  }, [filterData]);


  const handleCloseModal = () => {
    setshowViewReturnTask(false)
  }

  const onChangeHandler = (
    event: any,
    key: any,
    value: any,
    defVal: any = false,
    timestamp: any,
    multiVal: any
  ) => {
    setPayload({
      ...payload,
      filterData: {
        ...payload.filterData,
        [key]: {
          ...payload.filterData[key],
          [value]: event?.value,
          [key + '_' + value]: defVal ? event : '',
          isDate: defVal ? false : true,
          timestamp: timestamp === 'null' ? undefined : timestamp,
          multiVal: multiVal,
        },
      },
    })
  }

  const handlePagination = (
    search: any = '',
    sortBy = '',
    sortDirection: any = 'desc',
    page: any = 1,
    perPageSize: any = 10
  ) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const actionHandler = async () => {
    await InvokedRendered(
      handlePagination(search, sortby, direction, page, perPageSize),
      setPending
    )
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(
      key,
      currentItemRemovedOrAdded,
      setFilterKey,
      setSelectedFilterData,
      selectedFilterData,
      setStoreFilterList,
      storeFilterList,
      payload,
      setPayload
    )
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(
      handlePagination(search, column.sortField, sortDirection, page, perPageSize),
      setPending
    )
  }

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  }

  useEffect(() => {
    let timer = setTimeout(async function () {
      search && setPending(true)
      search &&
        InvokedRendered(
          handlePagination(search && search, sortby, direction, page, perPageSize),
          setPending
        )
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [search])


  useEffect(() => {
    let { isFilter, proformaFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: proformaFilter }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: proformaFilter })
  }, [
    search,
    filterDataOutsource?.isFilter,
    filterDataOutsource?.proformaFilter,

  ])
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    selectedFilterData?.length === 0 &&
      InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }, [selectedFilterData?.length])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords &&
        (await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
          keyword: storeSearchKeywords,
        }))
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList('getAllReturnTaskList')
  }, [])

  const refreshNow = () => {
    setToggleClearRows(!toggledClearRows)
    setFilteredSelectedRows([])
    InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
  }

  return (
    <>
      <KTCardBody className='card py-4' key='getAllReturnTaskList'>

        <AdminServerSidePaginationLists
          title={'Return Task List'}
          reorderColumnTitle='getAllReturnTaskList'
          sortedColumns={[
            ...sortedColumns]}
          filterData={filterData}
          pending={pending}
          clearSelectedRows={toggledClearRows}
          totalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          conditionalRowStyles={{}}
          handleSort={handleSort}
          keyName={'getAllReturnTaskList'}
          search={search}
          setSearch={setSearch}
          paginationPerPage={perPageSize}
          showFilter={false}
          ExpandedComponent={() => <></>}
          expandableRows={false}
          filterHandler={{
            onChangeHandler: onChangeHandler,
            actionHandler: actionHandler,
            getCurrentFilter: getCurrentFilter,
            removeFilter: removeFilter,
            payload: payload,
            Component: DynamicFilter,
            filterData: filter,
            OperatorsData: operators,
            selectedFilterData,
            filterComponents: KeywiseComponents,
            storeFilterList: storeFilterList,
            filterkey: filterkey,
            setSeacrch: setSearchFilter,
            isLoadingFilter: isLoadingFilter,
            listApiUrl: listApiUrl('getAllReturnTaskList'),
          }}

          SubHeaderComponentRightSection={() => <>
            <Refresh refreshNow={refreshNow} />
          </>
          }

          SubHeaderComponentActionButtons={() => <></>}
        />
      </KTCardBody>

      {
        showViewReturnTask &&
        <ViewReturnTask
          show={showViewReturnTask}
          productData={productData?.map((ele: any) => ({ ...ele, quantity_ordered: ele.quantity }))}
          handleClose={handleCloseModal}
          modalLoader={modalLoader}
          taskStatus={taskStatus}
        />

      }

    </>
  )
}

export default ReturnTaskList
