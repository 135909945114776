import moment from "moment";
import GChatBTN from "../../consts/Chats/ChatButtons/GChatBTN";


export const MismatchColumns = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "SUBS. ID",
        selector: (row: any) => row?.id ? row?.id : "NA",
        // sortField: 'id',
        sortable: true,
        width: "130px"
    },
    {
        id: 2,
        reorder: true,
        name: "NAME",
        selector: (row: any) => {
            return <GChatBTN
                ticketDataParent={{
                    id: null,
                    customerid: row?.customerid
                }}
                Title={row?.name}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
            ></GChatBTN>

        },
        sortable: true,
        width: "200px",
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.name?.toLowerCase()
            const b = rowB?.name?.toLowerCase()
            if (a > b) {
                return 1
            }

            if (b > a) {
                return -1
            }
            return 0
        },

    },
    {
        id: 3,
        reorder: true,
        name: "START DATE",
        selector: (row: any) => row?.startdate ? moment(row?.startdate).format("DD-MM-YYYY") : "NA",
        // sortField: 'startdate',
        sortable: true,
        // width: "80px"
    },
    {
        id: 4,
        reorder: true,
        name: "END DATE",
        selector: (row: any) => row?.enddate ? moment(row?.enddate).format("DD-MM-YYYY") : "NA",
        // sortField: 'enddate',
        sortable: true,
        // width: "80px"
    },
    {
        id: 5,
        reorder: true,
        name: "FREQUENCY",
        selector: (row: any) => row?.frequency,
        // sortField: 'frequency',
        sortable: true,
        width: "200px"
    },
    {
        id: 10,
        reorder: true,
        name: "MONTHS",
        selector: (row: any) => row?.months,
        // sortField: 'months',
        sortable: true,
        width: "120px"
    },
    {
        id: 11,
        reorder: true,
        name: "AVAILABLE JOBS",
        selector: (row: any) => row?.available_jobs,
        // sortField: 'available_jobs',
        sortable: true,
        width: "120px"
    },
    {
        id: 6,
        reorder: true,
        name: "EC JOB COUNTS",
        selector: (row: any) => row?.ec_job_counts,
        // sortField: 'ec_job_counts',
        sortable: true,
        width: "150px"
    },

    {
        id: 7,
        reorder: true,
        name: "FC JOB COUNTS",
        selector: (row: any) => row?.fc_job_counts,
        // sortField: 'fc_job_counts',
        sortable: true,
        width: "150px"
    },
    {
        id: 8,
        reorder: true,
        name: "TOTAL FULL CLEANINGS REQUIRED",
        selector: (row: any) => row?.total_full_cleanings_required,
        // sortField: 'total_full_cleanings_required',
        sortable: true,
        width: "200px"
    },
    {
        id: 9,
        reorder: true,
        name: "NOT GENEREATED JOB COUNT",
        selector: (row: any) => row?.not_generated_jobs,
        // sortField: 'not_generated_jobs',
        sortable: true,
        width: "200px"
    },
]

