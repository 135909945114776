export const ReactTableComponentStylesSheet = {
    rows: {
      style: {
        minHeight: '50px', 
        border:"none"
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', 
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    
  };


  