import moment from 'moment';
import { useEffect, useState } from 'react';
import { TempsDateFormate } from '../../consts/toggleColumns/TempsDateFormate';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import { DateFormateMoment } from './../../consts/toggleColumns/DateFormateMoment';
import { catchCurrentOpenedUrl, image_Cloudfront } from '../../consts/components/Utility';
import { Button } from 'react-bootstrap';
import IsPaidTaskListAndRenewalTaskYesNo from '../../consts/SweetAlert/IsPaidTaskListAndRenewalTaskYesNo';

const audioPlayed = (e: any) => {
    const audios = document.querySelectorAll(".voice-note-audio");
    audios.forEach((audio: any) => {
        if (audio !== e.target)
            audio.pause();
    });
}

export const columns = (handleFetchAllCallLogs?: any, champTrackdata?: any, customerTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "SERVICE DATE",
        selector: (row: any) => row?.attendencedate,
        sortField: 'attendencedate',
        sortable: true
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP ASSIGNED",
        cell: (row: any) => <OpenChat title={row?.attendencecleaner?.first_name + " " + row?.attendencecleaner?.last_name} champId={row?.attendencecleaner?.id} trackData={champTrackdata} />,
        sortField: 'attendencecleaner.first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 3,
        reorder: true,
        name: "CUSTOMER NAME",
        cell: (row: any) =>
            <GChatBTN
                ticketDataParent={{
                    id: null,
                    customerid: row?.subscriptionMonth?.customerid,
                    source_status: "RenewalTaskList",
                    BTNColor: row?.subscriptionMonth?.customerid,
                    referenceTicket: null
                }}
                Title={row?.subscriptionMonth?.name}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
                icons={""}
                refs="renewal"
                trackData={customerTrackData}
                keyName={catchCurrentOpenedUrl()}

            ></GChatBTN>,

        sortField: 'attendencecleaner.subscriptionMonth.name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "AREA MANAGERS",
        cell: (row: any) => row?.attendencecleaner?.champSupervisor?.first_name ? row?.attendencecleaner?.champSupervisor?.first_name + " " + row?.attendencecleaner?.champSupervisor?.last_name : "NA",
        sortField: 'attendencecleaner.champSupervisor.first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 5,
        reorder: true,
        name: "TASK COMPLETED",
        selector: (row: any) => TempsDateFormate(row?.attendencedatedone, true) === "Invalid date" ? "NA" : TempsDateFormate(row?.attendencedatedone, true),
        sortField: 'attendencedatedone',
        sortable: true,
        minWidth: '200px',
    },
    // {
    //     id: 6,
    //     reorder: true,
    //     name: "TASK STATUS",
    //     selector: (row: any) => row?.action_result ? JSON.parse(row?.action_result)?.actionType : "No Action",
    //     sortField: 'action_result',
    //     minWidth: '170px',
    //     sortable: true
    // },
    // {
    //     id: 6,
    //     reorder: true,
    //     name: "Notes",
    //     selector: (row: any) => row?.action_result ? JSON.parse(row?.action_result)?.notes : "",
    //     minWidth: '170px',
    // },
    // {
    //     id: 6,
    //     reorder: true,
    //     name: "Voice note",
    //     selector: (row: any) => {
    //         if (!row?.action_result) {
    //             return "";
    //         }
    //         const fileName = image_Cloudfront + JSON.parse(row?.action_result)?.voiceNotesFileName;
    //         return <audio controls style={{
    //             maxWidth: "210px",
    //         }} className='voice-note-audio' onPlay={audioPlayed} id={`audio-${row?.id}`}>
    //             <source src={fileName} type="audio/x-m4a" />
    //             Your browser does not support the audio element.
    //         </audio>
    //     },
    //     minWidth: '250px',
    // },
    {
        id: 7,
        reorder: true,
        name: "CREATED AT",
        selector: (row: any) => moment(row?.createdAt).format("DD-MM-YYYY"),
        sortField: 'createdAt',
        sortable: true
    },
    // {
    //     id: 8,
    //     reorder: true,
    //     name: "RENEWAL DATE",
    //     selector: (row: any) => <DateFormateMoment date={row?.subscriptionMonth?.paymentdate} from="RENEWAL_TASK_LIST_RENEWAL_DATE" />,
    //     sortField: 'createdAt',
    //     sortable: true,
    //     minWidth: "200px",
    // },

    {

        id: 9,
        reorder: true,
        name: "Action",
        selector: (row: any) => <IsPaidModal row={row} />,
        sortField: 'is_paid',
        sortable: true,
        minWidth: '150px',
    },
];





const IsPaidModal = ({ row }: any) => {
    const [isPaidStatusModal, setIsPaidStatusModal] = useState<any>(false)
    const [renders, setRender] = useState<any>(null)
    const [storeRow, setStoreRow] = useState<any>(row)
    const [loader, setLoader] = useState<any>(false)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const openIsPaidModalHandler = () => {
        setIsPaidStatusModal(true)
        setRender(Math.random())
    }

    const title = loader ? "Inititating..." : storeRow?.ispaid == 0 ? "Not Paid" : "Paid"
    return (
        <>

            <Button disabled={storeRow?.ispaid == 0 ? false : true} className={`btn btn-sm btn-${storeRow?.ispaid == 0 ? "primary" : "success"}`} onClick={() => openIsPaidModalHandler()}>{title}</Button>
            {
                isPaidStatusModal ?
                    <IsPaidTaskListAndRenewalTaskYesNo
                        render={renders}
                        confirm={'Yes'}
                        cancel={'No'}
                        payload={{
                            "renewal_task_id": row?.id,
                        }}
                        setIsPaidStatusModal={setIsPaidStatusModal}
                        title={`Are you sure want to Pay ?`}
                        setLoader={setLoader}
                        setStoreRow={setStoreRow}
                        row={row}
                    /> : ""
            }
        </>
    )
}
