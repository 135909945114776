import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { adminToastRunner, adminToastRunnerError, } from '../admins/adminUtils';
import AdminSelectSearchable from '../admins/adminForms/adminFormComponents/AdminSelectSearchable';
import MomentDate from '../../consts/MomentDate';
import { getFilterDataBySearchClientSide } from '../../consts/DynamicFilteration/components/CommonData';
import ReturnTaskDate from './ReturnTaskDate';

const CreateReturnTask = (props: any) => {
    const { show, setShow, refreshNow } = props;
    const [loading, setLoading] = useState(false);
    const [deliveryChampList, setDeliveryChampList] = useState([]);

    const [initialValue, setInitialValue] = useState<any>({
        md_champ_id: null,
        champ_ids: null,
        task_date: MomentDate(0),

    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [champListResponse] = await Promise.all([
                    getRequest(URL_EndPoints()?.getMaterialDeliveryChampList, null),
                ]);
                setDeliveryChampList(champListResponse?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!initialValue?.md_champ_id || !initialValue?.task_date || initialValue?.champ_ids?.length == 0) {
            toast.warning('Champ Name | Delivery Champ | Task Date is missing', { position: "top-center" });
            return;
        }

        setLoading(true);
        try {
            let response = await postRequest(URL_EndPoints()?.createMaterialReturnTask, initialValue, setLoading)
            adminToastRunner(response);
            refreshNow && refreshNow();
            if (response?.data?.status == 200) {
                setShow(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to submit form');
        } finally {
            setLoading(false);
        }
    };

    const onChangeHandler = (event: any, name: any) => {
        setInitialValue({ ...initialValue, [name]: event ? event?.value : event })
    }


    const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
    const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
    const [dynFilter, setDynFilter] = useState<any>("")
    const [storeFilterListItem, setStoreFilterListItem] = useState<any>({
        cleanerListData: []
    })

    const setSearchFilter = (event: any, filters: any) => {
        setDynFilter(filters)
        setStoreSearchKeywords(event)
    }

    useEffect(() => {
        let x = setTimeout(async () => {
            setIsLoadingFilter(true)
            await getFilterDataBySearchClientSide(dynFilter, setStoreFilterListItem, storeFilterListItem, setIsLoadingFilter, { keyword: storeSearchKeywords })
            setIsLoadingFilter(false)
        }, 2000)

        return () => {
            setIsLoadingFilter(false)
            clearTimeout(x)
        }
    }, [storeSearchKeywords, dynFilter])

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Return Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <Row className='w-100'>
                            <Col xs={12} md={12} className="mb-3">
                                <AdminSelectSearchable
                                    HeaderTitle='Select Delivery Champ'
                                    onChangeHandler={onChangeHandler}
                                    selectData={deliveryChampList}
                                    displayFields={["first_name", "last_name"]}
                                    id="id"
                                    name="md_champ_id"
                                    defaultData={null}
                                    searchable={false}
                                    setSeacrch={null}
                                    isLoading={null}
                                    isMulti={false}
                                    isDisabled={false}
                                />
                            </Col>

                            <Col xs={12} md={12} className="mb-3">
                                <AdminSelectSearchable
                                    HeaderTitle='Select Champ'
                                    onChangeHandler={onChangeHandler}
                                    selectData={storeFilterListItem["cleanerListData"]}
                                    displayFields={["first_name", "last_name"]}
                                    id="id"
                                    name="champ_ids"
                                    defaultData={null}
                                    searchable={true}
                                    isMulti={true}
                                    setSeacrch={setSearchFilter}
                                    isLoading={isLoadingFilter}
                                    filters={{
                                        reqApi: "getChampsList",
                                        storeKeyName: "cleanerListData",
                                    }}
                                    isDisabled={false}
                                />
                            </Col>

                            {/* Single Date Filter */}
                            <Col xs={12} md={12} className="mb-3">
                                <ReturnTaskDate
                                    onChangeHandler={onChangeHandler}
                                    payload={initialValue}
                                    name="task_date"
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className='text-end mt-4 me-4'>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateReturnTask;
