import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getRequest, postRequest, postRequestWithFile } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../../admins/adminUtils';
import MediaUploadFile from '../MediaUploadFile';
import MediaPreview from '../MediaPreview';
import { toast } from 'react-toastify';
import axios from 'axios';

const CreateUpdateProformaForm = ({ show, refreshNow, setShow, setData, initialValue, setInitialValue, apiUrl }: any) => {
    const [loading, setLoading] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [file, setFile] = useState<any>(null);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [proformaHistory, setProformaHistory] = useState<any[]>([]);

    useEffect(() => {
        setFile(initialValue?.media)
    }, [initialValue?.media]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [purchaseOrderList] = await Promise.all([
                    getRequest(URL_EndPoints()?.getPurchaseOrderListing, null),
                ]);
                setPurchaseOrderList(purchaseOrderList?.data?.data);

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchData();
        }
    }, [show]);

    useEffect(() => {
        if (initialValue?.purchase_order_id) {
            const fetchProformaHistory = async () => {
                try {
                    const response = await getRequest(
                        URL_EndPoints()?.getProformaByPurchaseOrderId + '/' + initialValue.purchase_order_id,
                        null
                    );

                    if (response?.data?.data) {
                        setProformaHistory(response.data.data);
                    }
                } catch (error) {
                    console.error('Error fetching proforma history:', error);
                    toast.error('Failed to fetch proforma history');
                }
            };
            fetchProformaHistory();
        }
    }, [initialValue?.purchase_order_id]);

    useEffect(() => {
        if (initialValue?.id && initialValue?.item_list && initialValue?.purchase_order_id) {
            try {
                const fetchOriginalData = async () => {
                    const [itemsResponse, proformaResponse] = await Promise.all([
                        getRequest(URL_EndPoints()?.getAllPurchaseOrdersItems + '/' + initialValue.purchase_order_id, null),
                        getRequest(URL_EndPoints()?.getProformaByPurchaseOrderId + '/' + initialValue.purchase_order_id, null)
                    ]);

                    const originalItems = itemsResponse.data.data;
                    const proformaHistory = proformaResponse?.data?.data || [];
                    const currentItems = Array.isArray(initialValue.item_list) 
                        ? initialValue.item_list 
                        : JSON.parse(initialValue.item_list);

                    const formattedItems = currentItems.map((item: any) => {
                        const originalItem = originalItems.find((oi: any) => oi.item_id === item.item_id);
                        
                        // Calculate total quantity invoiced from other proformas
                        const totalQuantityInvoicedByOthers = proformaHistory.reduce((total: number, proforma: any) => {
                            if (proforma.id === initialValue.id) return total; // Skip current proforma
                            
                            const proformaItem = proforma.proformaInvoiceWithItems
                                .find((pi: any) => pi.item_id === item.item_id);
                            
                            return total + (proformaItem?.quantity_invoiced || 0);
                        }, 0);

                        const remainingQuantity = originalItem.quantity_ordered - totalQuantityInvoicedByOthers;

                        return {
                            item_id: item.item_id,
                            title: originalItem?.purchaseOrderInventoryItems?.title || item.title,
                            quantity_ordered: originalItem.quantity_ordered,
                            quantity_invoiced: item.quantity_invoiced,
                            quantity_remaining: remainingQuantity,
                            unit_price: item.unit_price || 0,
                            gst_percentage: item.gst_percentage || 0,
                            item_amount: item.item_amount || 0,
                            tax_amount: item.tax_amount || 0,
                            total_price: item.total_price || 0
                        };
                    });

                    setSelectedItems(formattedItems);
                };

                fetchOriginalData();
            } catch (error) {
                console.error('Error processing item list:', error);
                toast.error('Error loading item details');
            }
        }
    }, [initialValue?.id, initialValue?.item_list, initialValue?.purchase_order_id]);

// For creating a new proforma (handleInputChange)
    const handleInputChange = async (e: any) => {
        const { name, value } = e.target;
        
        setInitialValue((prev: any) => ({
            ...prev,
            [name]: value
        }));

        if (name === "purchase_order_id" && !initialValue?.id) {
            if (!value) {
                setSelectedItems([]);
                return;
            }

            try {
                setLoading(true);
                const [itemsResponse, proformaResponse] = await Promise.all([
                    getRequest(URL_EndPoints()?.getAllPurchaseOrdersItems + '/' + value, null),
                    getRequest(URL_EndPoints()?.getProformaByPurchaseOrderId + '/' + value, null)
                ]);
                
                const items = itemsResponse.data.data;
                const existingProformas = proformaResponse?.data?.data || [];

                const formattedItems = items.map((item: any) => {
                    // Calculate total quantity invoiced from existing proformas
                    const totalQuantityInvoiced = existingProformas.reduce((total: number, proforma: any) => {
                        const proformaItem = proforma.proformaInvoiceWithItems
                            .find((pi: any) => pi.item_id === item.item_id);
                        return total + (proformaItem?.quantity_invoiced || 0);
                    }, 0);

                    const remainingQuantity = item.quantity_ordered - totalQuantityInvoiced;

                    return {
                        item_id: item.item_id,
                        title: item.purchaseOrderInventoryItems.title,
                        quantity_ordered: item.quantity_ordered,
                        quantity_invoiced: 0,
                        quantity_remaining: remainingQuantity,
                        unit_price: item.unit_price || 0,
                        gst_percentage: 0,
                        item_amount: 0,
                        tax_amount: 0,
                        total_price: 0
                    };
                }).filter((item: any) => item.quantity_remaining > 0);

                if (formattedItems.length === 0) {
                    toast.warning('All items in this purchase order have been fully invoiced');
                }
                setSelectedItems(formattedItems);

            } catch (error) {
                console.error('Error fetching purchase order items:', error);
                toast.error('Failed to fetch purchase order items');
            } finally {
                setLoading(false);
            }
        }
    };

    const [fileSelected, setFileSelected] = useState<boolean>(false);
    // const [file, setFile] = useState<any>(null);

    const CancelImage = () => {
        setFile(null);
        setFileSelected(false);
    };

    const handleQuantityChangeReceived = (name: string, value: any, index: number) => {
        const updatedItems = [...selectedItems];
        
        // Validate numeric inputs
        if (["quantity_invoiced", "unit_price", "gst_percentage"].includes(name)) {
            const numValue = Number(value);
            if (numValue < 0) {
                return;
            }
            
            // validation for quantity_invoiced
            if (name === "quantity_invoiced") {
                if (numValue > updatedItems[index].quantity_remaining) {
                    toast.error(`Cannot exceed remaining quantity of ${updatedItems[index].quantity_remaining}`);
                    return;
                }
            }
            
            // validation for unit_price
            if (name === "unit_price" && numValue === 0) {
                return;
            }

            updatedItems[index][name] = numValue;
        }
    
        // Calculate amounts
        const quantity = updatedItems[index].quantity_invoiced;
        const unitPrice = updatedItems[index].unit_price;
        const gstPercentage = updatedItems[index].gst_percentage;
        
        const itemAmount = quantity * unitPrice;
        const taxAmount = (itemAmount * gstPercentage) / 100;
        const totalAmount = itemAmount + taxAmount;
        
        updatedItems[index] = {
            ...updatedItems[index],
            item_amount: Number(itemAmount.toFixed(2)),
            tax_amount: Number(taxAmount.toFixed(2)),
            total_price: Number(totalAmount.toFixed(2))
        };
    
        setSelectedItems(updatedItems);

        // Calculate new total amount for all items
        const totalFormAmount = updatedItems.reduce((sum: number, item: any) => {
            const itemTotal = Number(item.total_price) || 0;
            return sum + itemTotal;
        }, 0);
        
        // Update both the initialValue state and the amount field
        setInitialValue((prev: any) => ({
            ...prev,
            amount: Number(totalFormAmount.toFixed(2))
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        
        if (!selectedItems || selectedItems.length === 0) {
            toast.warning('Please select at least one item');
            return;
        }
    
        // Validate required fields
        const requiredFields = ['purchase_order_id', 'proforma_invoice_id', 'invoice_date'];
        for (const field of requiredFields) {
            if (!initialValue[field]) {
                toast.error(`Please fill in ${field.replace(/_/g, ' ')}`);
                return;
            }
        }
    
        // Validate items have required values
        const invalidItems = selectedItems.some(item => 
            !item.unit_price || 
            item.total_price <= 0
        );
    
        if (invalidItems) {
            toast.error('Please fill in all required item details');
            return;
        }
    
        setLoading(true);
        try {
            let payload = { ...initialValue };
            
            if (typeof (file) === "string" || !file) {
                delete payload.media;
            } else {
                payload.media = file;
            }
    
            // Add items list to payload
            payload.item_list = JSON.stringify(selectedItems);
    
            const response = await postRequestWithFile(URL_EndPoints()?.[apiUrl], payload, setLoading);
            adminToastRunner(response);
            
            if (response?.data?.data?.items) {
                setData(response?.data?.data?.items);
            }
    
            refreshNow && refreshNow();
            setShow && setShow(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to submit form');
        } finally {
            setLoading(false);
        }
    };

    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + Number(item.total_price), 0);
    };
    
    const handleRemoveItem = (index: number) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
        
        // Update form total amount after removing item
        const totalFormAmount = updatedItems.reduce((sum, item) => sum + item.total_price, 0);
        setInitialValue({
            ...initialValue,
            amount: Number(totalFormAmount.toFixed(2))
        });
    };

    return (
        <Modal size='lg' show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{initialValue?.id ? "Edit" : "Add"} Proforma Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="purchase_order_id">
                                <Form.Label><strong>Select Purchase Order</strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="purchase_order_id"
                                    value={initialValue["purchase_order_id"] || []}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Purchase Order</option>
                                    {purchaseOrderList.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.purchase_order_id ? item.purchase_order_id : `${(item.purchase_order_id)} | NA`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>


                            <Form.Group as={Col} className="mb-3" controlId="proforma_invoice_id">
                                <Form.Label><strong>Proforma Invoice Number</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Proforma Invoice Number"
                                    name="proforma_invoice_id"
                                    value={initialValue['proforma_invoice_id']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="invoice_date">
                                <Form.Label><strong>Invoice Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Invoice Date"
                                    name="invoice_date"
                                    value={initialValue['invoice_date']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" controlId="amount">
                                <Form.Label><strong>Amount</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Amount"
                                    name="amount"
                                    value={initialValue['amount']}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="due_date">
                                <Form.Label><strong>Due Date</strong></Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Due Date"
                                    name="due_date"
                                    value={initialValue['due_date']}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><strong>Notes</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Notes"
                                    name="notes"
                                    value={initialValue['notes'] || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>
                        
                        {selectedItems?.length > 0 && (
                            <>
                                {selectedItems
                                .filter(ele => ele?.quantity_remaining > 0)
                                .map((ele, index) => (
                                    <div key={ele?.id || `item-${index}`} className="mb-4 p-3" style={{ 
                                        border: '2px solid #000',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                        }}>

                                        <div className="mb-3 d-flex justify-content-between align-items-center"
                                        style={{ 
                                            backgroundColor: '#ffe6e6', 
                                            padding: '8px', 
                                            borderRadius: '4px' }}>
                                            <div className="text-danger fw-bold">
                                                {ele?.title} (ID: {ele?.item_id})
                                            </div>
                                            <Button 
                                                variant="danger" 
                                                size="sm" 
                                                onClick={() => handleRemoveItem(index)}
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Remove
                                            </Button>
                                        </div>

                                        <Row>
                                        <Form.Group as={Col} md={2}>
                                            <div style={{ width: '120px' }}>
                                                <div style={{ height: '50px' }}>
                                                    <Form.Label>
                                                        <small><strong>Qty Ordered</strong></small>
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    value={ele?.quantity_ordered || ""}
                                                    disabled
                                                    size="sm"
                                                />
                                            </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={2}>
                                                <div style={{ width: '120px' }}>
                                                    <div style={{ height: '50px' }}>
                                                        <Form.Label>
                                                            <small><strong>Qty Invoiced</strong>
                                                            <div className="text-danger">
                                                                (remaining: {ele?.quantity_remaining})
                                                            </div>
                                                        </small>
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    name="quantity_invoiced"
                                                    value={ele?.quantity_invoiced || "0"}
                                                    onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                    size="sm"
                                                />
                                            </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={2}>
                                                <div style={{ width: '120px' }}>
                                                    <div style={{ height: '50px' }}>
                                                        <Form.Label>
                                                            <small><strong>Unit Price</strong></small>
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        name="unit_price"
                                                        value={ele?.unit_price || ""}
                                                        onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                        required
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={1}>
                                                <div style={{ width: '120px' }}>
                                                    <div style={{ height: '50px' }}>
                                                        <Form.Label>
                                                            <small><strong>GST %</strong></small>
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        name="gst_percentage"
                                                        value={ele?.gst_percentage || 0}
                                                        onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={2}>
                                                <div style={{ width: '120px' }}>
                                                    <div style={{ height: '50px' }}>
                                                        <Form.Label>
                                                            <small><strong>Item Amount</strong></small>
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        name="item_amount"
                                                        value={ele?.item_amount || 0}
                                                        onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={2}>
                                                <div style={{ width: '120px' }}>
                                                    <div style={{ height: '50px' }}>
                                                        <Form.Label>
                                                            <small><strong>Tax Amount</strong></small>
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        type="number"
                                                        name="tax_amount"
                                                        value={ele?.tax_amount || 0}
                                                        onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} md={2}>
                                                <div style={{ width: '120px' }}>
                                                    {/* <div style={{ height: '50px' }}> */}
                                                        <Form.Label>
                                                            <small><strong>Total Amount</strong></small>
                                                        </Form.Label>
                                                    {/* </div> */}
                                                    <Form.Control
                                                        type="number"
                                                        name="total_price"
                                                        value={ele?.total_price || 0}
                                                        onChange={(e) => handleQuantityChangeReceived(e.target.name, e.target.value, index)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                ))}

                                <div style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '16px' }} className='mb-4'>
                                    Total Amount: {calculateTotalAmount().toFixed(2)}
                                </div>
                            </>
                        )}

                        <Row className="mb-3">
                            <MediaUploadFile setFileSelected={setFileSelected} setFile={setFile} file={file} fileSelected={fileSelected} setPayload={setInitialValue} payload={initialValue} />
                            <MediaPreview fileSelected={fileSelected} file={file} CancelImage={CancelImage} defaultUrl={initialValue?.media} />
                        </Row>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>

                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CreateUpdateProformaForm;
