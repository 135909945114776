import OpenChat from "../../../consts/champChatDashboard/OpenChat"

import { getFullName } from "../../../utils/helper.util"

const ChampDetailTable = ({rowData, submitForm}: any) => {
    return <div className="table-responsive border border-2 rounded p-3">
        <table className="table table-striped table-hover">
    <thead>
        <tr>
            <th scope="col">Action</th>
            <th scope="col">Champ</th>
            <th scope="col">Workload &lt; 80%</th>
            <th scope="col">Same Society</th>
            <th scope="col">Repeated Champ</th>
            <th scope="col">Champ Availability</th>
        </tr>
    </thead>
    <tbody>
        {rowData?.inRangeChamps?.map((champ: any) => {
            return <tr>
                <td>
                    <button className="btn btn-sm btn-primary" onClick={() => submitForm({
                        jobId: rowData?.id,
                        champId: champ?.id,
                        assignment_type: 2
                    })}>Assign</button>
                </td>
                <td>
                    <OpenChat 
                        title={getFullName(champ)} 
                        champId={champ?.id} 
                    />
                </td>
                <td>{champ?.withInLoadPercentage ? "Yes" : "No"}{`(${champ?.loadPercentage}%)`}</td>
                <td>{champ?.availableInSociety ? "Yes" : "No"}</td>
                <td>{champ?.isRepeatChamp ? "Yes" : "No"}</td>
                <td className="d-flex flex-wrap gap-2">
                    {champ?.attendenceData?.map((elm: any) => (
                        <div
                            className={'badge badge-light-success fw-bolder mb-2 rounded d-flex justify-content-center text-align'}
                            style={{ whiteSpace: 'pre-wrap', cursor: "pointer" }}
                        >
                            {elm?.siteid == 1 ? "SO" : "IN"}{`(${elm?.subscriptionMonth?.masterFrequency?.name
                                ?.split(" ")
                                ?.map((elm: any) => elm.slice(0, 1)?.toUpperCase())
                                ?.join("")})`
                            }
                        </div>
                    ))}
                    {Array.from({length: (champ?.champAvailabilities?.length*4 - champ?.attendenceData?.length)}, (_, index) => (
                        <div
                            className={'badge badge-light-info fw-bolder mb-2 rounded d-flex justify-content-center text-align'}
                            style={{ whiteSpace: 'pre-wrap', cursor: "pointer" }}
                        >
                            NJ
                        </div>
                    ))}
                </td>
            </tr>
        })}
    </tbody>
</table>
</div>
}
export default ChampDetailTable