import React, { FC, useEffect, useState } from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import AdminMultiSelectFilter from '../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
const LeadsVsSourceFilter = ({ ParentData }: any) => {
  let from = "start_date"
  let to = "end_date"
  return (
    // showListType
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>

      <div className='row mb-2 mb-md-0'>
        <div className='col-md-6 mb-md-2'>
          <span>Start Date</span>
          <input
            type='date'
            style={{ height: '38px' }}
            value={ParentData?.payloads[from]}
            name={from}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, from, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
        <div className='col-md-6'>
          <span>End Date</span>

          <input
            type='date'
            style={{ height: '38px' }}
            name={to}
            value={ParentData?.payloads[to]}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, to, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
      </div>

      {
        ParentData?.showListType && <>
          <span className='fs-semibold fs-7'>Leads Type</span>
          <SingleSelectSearchDetailsDefaultDyanmic
            handleChangeInputData={ParentData?.onChangeHandler}
            HeaderTitle='Select Leads Type'
            SelectData={[{ name: "Cleaners", id: "cleaners" }, { name: "Customers", id: "customers" }]}
            DynamicKeys={["name"]}
            id={"id"}
            name={"leads_type"}
            targetValue={"value"}
            defaultData={ParentData?.defaultListType}
          ></SingleSelectSearchDetailsDefaultDyanmic>

        </>
      }


      <div>
            <span className='fs-semibold fs-7'>Campaign</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Campaign"
              SelectData={ParentData?.campaigns || []}
              displayFields={["name"]}
              id="id"
              name="campaign"
              defaultData={ParentData?.defaultCampaigns}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>
          


    </div>
  )
}

export default LeadsVsSourceFilter

