import React from 'react';
import { Modal, Button, Spinner, Table } from 'react-bootstrap';

interface Product {
  vendorInvoiceItemsList: {
    title: string;
  };
  item_id: number;
  total_price: number;
  quantity_invoiced: number;
  unit_price: number;
  gst_percentage: number;
  item_amount: number;
  tax_amount: number;
}

interface ProductModalProps {
  show: boolean;
  productData: Product[];
  handleClose: () => void;
  modalLoader: boolean;
}

const ViewProductModal: React.FC<ProductModalProps> = ({ 
  show, 
  productData, 
  handleClose, 
  modalLoader 
}) => {
  // Calculate total amount
  const totalAmount = productData?.reduce((sum, item) => sum + (Number(item.total_price) || 0), 0) || 0;

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalLoader && (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {!modalLoader && productData && productData.length > 0 ? (
          <>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Item ID</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>GST %</th>
                  <th>Item Amount</th>
                  <th>Tax Amount</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {productData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.vendorInvoiceItemsList.title}</td>
                    <td>{item.item_id}</td>
                    <td>{item.quantity_invoiced}</td>
                    <td>{item.unit_price}</td>
                    <td>{item.gst_percentage}%</td>
                    <td>{item.item_amount}</td>
                    <td>{item.tax_amount}</td>
                    <td>{item.total_price}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={7} className="text-end fw-bold">Total Amount:</td>
                  <td className="fw-bold">{totalAmount}</td>
                </tr>
              </tfoot>
            </Table>
          </>
        ) : (
          <p>No items available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewProductModal;