import moment from 'moment'
import { getName } from '../../admins/adminUtils'

export const getColumns = (handleViewButtonClick?: any, viewGoodReceipt?: any, viewPaymentReceipt?: any, viewVendorInvoice?: any, viewProformaInvoice?: any) => [

  {
    name: 'Order ID',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Purchase Order Number',
    cell: (row: any) => row?.purchase_order_id,
    sortField: 'purchase_order_id',
    sortable: true,
    reorder: true,
    id: 16,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => (
      <span style={{ 
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: statusColors[row?.purchaseOrderStatus?.status] || '#6C757D',
        color: 'white',
        fontSize: '12px',
        fontWeight: '500'
      }}>
        {row?.purchaseOrderStatus?.status}
      </span>
    ),
    sortField: 'purchaseOrderStatus.status',
    sortable: true,
    reorder: true,
    id: 5,
    width: '120px',
  },
  {
    name: 'Material Status',
    cell: (row: any) => (
      <span style={{ 
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: statusColors[row?.purchaseOrderMaterialStatus?.status] || '#6C757D',
        color: 'white',
        fontSize: '12px',
        fontWeight: '500'
      }}>
        {row?.purchaseOrderMaterialStatus?.status}
      </span>
    ),
    sortField: 'purchaseOrderMaterialStatus.status',
    sortable: true,
    reorder: true,
    id: 18,
    width: '120px',
  },
  {
    name: 'Payment Status',
    cell: (row: any) => (
      <span style={{
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: statusColors[row?.purchaseOrderPaymentStatus?.status] || '#6C757D',
        color: 'white',
        fontSize: '12px',
        fontWeight: '500'
      }}>
        {row?.purchaseOrderPaymentStatus?.status}
      </span>
    ),
    sortField: 'purchaseOrderPaymentStatus.status',
    sortable: true,
    reorder: true,
    id: 17,
    width: '120px',
  },
  // {
  //   name: 'Total Amount',
  //   cell: (row: any) => row?.total_amount,
  //   sortField: 'total_amount',
  //   sortable: true,
  //   reorder: true,
  //   id: 6,
  //   width: '100px',
  // },
  {
    name: 'Vendor',
    cell: (row: any) => row?.purchaseOrdersVendor?.vendor_name,
    sortField: 'purchaseOrdersVendor.vendor_name',
    sortable: true,
    reorder: true,
    id: 4,
    width: '120px',
  },
  {
    name: 'Warehouse',
    cell: (row: any) => row?.purchaseOrdersWarehouse?.warehouse_name,
    sortField: 'purchaseOrdersWarehouse.warehouse_name',
    sortable: true,
    reorder: true,
    id: 13,
    width: '120px',
  },
  {
    name: 'Total Invoice Amount',
    cell: (row: any) => row?.total_invoice_amount,
    sortField: 'total_invoice_amount',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Paid Amount',
    cell: (row: any) => row?.paid_amount,
    sortField: 'paid_amount',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Balance Due',
    cell: (row: any) => row?.balance_due,
    sortField: 'balance_due',
    sortable: true,
    reorder: true,
    id: 9,
    width: '120px',
  },
  {
    name: 'Received Quantity',
    cell: (row: any) => row?.received_quantity,
    sortField: 'received_quantity',
    sortable: true,
    reorder: true,
    id: 10,
    width: '120px',
  },
  {
    name: 'Ordered Date',
    cell: (row: any) => moment(row?.order_date).format('YYYY-MM-DD HH:mm') || 'Unkown date',
    sortField: 'order_date',
    sortable: true,
    reorder: true,
    id: 14,
    width: '100px',
  },
  {
    name: 'Expected Delivery Date',
    cell: (row: any) => row?.expected_delivery_date,
    sortField: 'expected_delivery_date',
    sortable: true,
    reorder: true,
    id: 15,
    width: '110px',
  },
  {
    name: 'Completion Date',
    cell: (row: any) => row?.completion_date ? row?.completion_date : "Not Set",
    sortField: 'completion_date',
    sortable: true,
    reorder: true,
    id: 11,
    width: '120px',
  },
  {
    name: 'Created By',
    cell: (row: any) => getName(row?.purchaseOrdersAdminUser),
    sortField: 'purchaseOrdersAdminUser.first_name',
    sortable: true,
    reorder: true,
    id: 12,
    width: '200px',
  },
]

const statusColors: any = {
  'Pending': '#FF9800',        // Orange
  'Partially Paid': '#03A9F4', // Light Blue
  'Paid': '#4CAF50',          // green
  'Invoiced': '#9C27B0',      // Purple
  'Completed': '#4CAF50',     // green
  'Recieved': '#4CAF50',      // green
  'Partially Recieved': '#03A9F4' // Light blue
}