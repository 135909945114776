import React, { useEffect, useState } from 'react'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import Refresh from '../../consts/Refresh'
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import { MismatchColumns } from './MismatchColumns'

const JobMismatchSubscriptions = () => {
    const [sortedColumns, setSortedColumns] = useState<any>(MismatchColumns())
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getMismatchSubscriptionList = async () => {
        setPending(true)
        const response = await postRequest(URL_EndPoints()?.getJobMismatchSubscriptions, {}, setPending)
        setFilterData(response?.data?.data)
        setData(response?.data?.data)
    }

    useEffect(() => {
        getMismatchSubscriptionList()
    }, [])

    return (
        <>
            <AdminClientSidePaginationLists
                title="Job Mismatch Subscription"
                reorderColumnTitle="jobMismatchList"
                sortedColumns={sortedColumns}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={() => <></>}
                // clientSideFilterComponent={AllotmentFilter}
                keyName={"jobMismatchList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                    <Refresh refreshNow={getMismatchSubscriptionList} />
                    {/* <BulkUtilButton title="Create Audit Form" filteredSelectedRows="static" setShow={setShowAuditForm} /> */}
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)} clientSideFilterComponent={undefined} />


        </>
    )
}

export default JobMismatchSubscriptions

