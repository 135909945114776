import React, { FC, useEffect, useState } from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import AdminMultiSelectFilter from '../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
const JobStatusCountsFilter = ({ ParentData }: any) => {
  let from = "startDate"
  let to = "endDate"
  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>

      <div className='row mb-2 mb-md-0'>
        <div className='col-md-6 mb-md-2'>
          <span>Task Start Date</span>
          <input
            type='date'
            style={{ height: '38px' }}
            value={ParentData?.payloads[from]}
            name={from}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, from, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
        <div className='col-md-6'>
          <span>Task End Date</span>
          <input
            type='date'
            style={{ height: '38px' }}
            name={to}
            value={ParentData?.payloads[to]}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, to, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
      </div>

      <span className='fs-semibold fs-7'>List Type</span>
      <SingleSelectSearchDetailsDefaultDyanmic
        handleChangeInputData={ParentData?.onChangeHandler}
        HeaderTitle='Select List Type'
        SelectData={ParentData?.listType || []}
        DynamicKeys={["name"]}
        id={"id"}
        name={"list_type_id"}
        targetValue={"value"}
        defaultData={ParentData?.listTypeDefault}
      ></SingleSelectSearchDetailsDefaultDyanmic>

      {
        ParentData?.payloads?.list_type_id == 1 ?
          <div key="list_type_id11111">
            <span className='fs-semibold fs-7'>Select Area Manager</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Area Manager"
              SelectData={ParentData?.areaManagerList || []}
              displayFields={["first_name", "last_name", "phone"]}
              id="id"
              name="area_manager_id"
              defaultData={{ item: ParentData?.defaultSupervisor }}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div> :

          ParentData?.payloads?.list_type_id == 2 && <div key="list_type_id2222222">
            <span className='fs-semibold fs-7'>Select Created By</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Created By"
              SelectData={ParentData?.adminListData || []}
              displayFields={["first_name", "last_name", "phone"]}
              id="id"
              name="created_by_id"
              defaultData={{ item: ParentData?.defaultAdmin }}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>
      }

      <span className='fs-semibold fs-7'>Select Task Type</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Task Type"
        SelectData={ParentData?.taskTypeList || []}
        displayFields={["name"]}
        id="id"
        name="task_type_id"
        defaultData={{ item: ParentData?.defaultTaskType }}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      />



      <span className='fs-semibold fs-7'>Select Training Location</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Training Location"
        SelectData={ParentData?.trainingLocationList || []}
        displayFields={["locationname"]}
        id="id"
        name="training_location_id"
        defaultData={{ item: { label: 'All', value: '' } }}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      />


      <span className='fs-semibold fs-7'>Interval</span>
      <SingleSelectSearchDetailsDefaultDyanmic
        handleChangeInputData={ParentData?.onChangeHandler}
        HeaderTitle='Select Interval'
        SelectData={ParentData?.intervalType || []}
        DynamicKeys={["name"]}
        id={"id"}
        name={"interval"}
        targetValue={"value"}
        defaultData={ParentData?.defaultInterval}
      ></SingleSelectSearchDetailsDefaultDyanmic>



      <span className='fs-semibold fs-7'>Select Rows</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Rows"
        SelectData={ParentData?.rowsFilterData || []}
        displayFields={["name"]}
        id="id"
        name="selectedDisplayValues"
        defaultData={{
          item: ParentData?.defaultRows
        }}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      />

    </div>
  )
}

export default JobStatusCountsFilter

