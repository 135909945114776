import React, { useEffect, useState } from 'react'
import { getRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton'
import { ChampAbsentListColumns } from './ChampAbsentListColumns'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { useDispatch } from 'react-redux'

const ChampAbsentList = () => {
    const [sortedColumns, setSortedColumns] = useState<any>(ChampAbsentListColumns())
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getChampList = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints()?.getAbsentChampList, setPending)
        setFilterData(response?.data?.data)
        setData(response?.data?.data)
    }
    // getChampActiveJobs

    useEffect(() => {
        getChampList()
    }, [])

    const dispatch = useDispatch()

    const openJobAssign = (row: any) => {
        dispatch({
            type: 'ASSIGN_ABSENT_JOB', payload: {
                assignAbsentJobId: row?.id,
                assignAbsentJobRender: Math.random(),
                show: true,
                row: row,
                from: "",
            }
        })

    }
    return (
        <>
            <AdminClientSidePaginationLists
                title="Champ Absent List"
                reorderColumnTitle="champAbsentList"
                sortedColumns={[{
                    id: 1,
                    reorder: true,
                    name: 'ACTION',
                    cell: (row: any) => (
                        <AssignJob row={row} openJobAssign={openJobAssign} />
                    ),
                    minWidth: "150px",
                }, ...sortedColumns]}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={() => <></>}
                clientSideFilterComponent={() => <></>}
                keyName={"champAbsentList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                    <Refresh refreshNow={getChampList} />
                    {/* <BulkUtilButton title="Create Offer Allotment" filteredSelectedRows="static" setShow={setOfferAllotment} /> */}
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)}
            />



        </>
    )
}
    
export default ChampAbsentList




const AssignJob = ({ row, openJobAssign }: any) => {

    return ( 
        <> 
            <span className='cursor-pointer text-primary text-decoration-underline' id='kt_drawer_chat_absent_assign_job_toggle3' onClick={() => openJobAssign(row)}>Assign Job</span>
        </>
    )
}