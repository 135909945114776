import RenderData from './RenderData';
import JobActions from '../Actions/JobActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './custom.css'
import AlertStatusUpdate from '../Status/AlertStatusUpdate';
import Calling from '../../../consts/Call/Calling';
import Layout from '../../../consts/Call/Layout';
import TagsShow from '../../../consts/TagsShow/TagsShow';


export const alert_columns = (otherData: any) => ([
    {
        name: 'ACTION',
        cell: (row: any) => {
            const attendance_date = row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.attendance_date : ''
            const reason_id = row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.alert_reason_id : 0
            const cl_attendance_id = row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.id : 0
            const cleaner_id = row?.id
            const references = "alert"
            return <div className='d-flex flex-row'>
                <div className=' me-5'>
                    <JobActions props={{ attendance_date, reason_id, cleaner_id, references, cl_attendance_id, row, otherData }} ></JobActions>
                </div>
            </div>
        },
        width: "80px"
    },
    {
        name: 'ID',
        selector: (row: { id: any; }) => row.id,
        sortable: true,
        id: "ID",
        width: "90px"
    },
    {
        name: 'NAME',
        cell: (row: any) => <div >
            <Calling title={row?.first_name + " " + row?.last_name} row_id={row?.id} row={row} type="cleanerid" From={Layout} isOpen={false} />
            {<TagsShow Tag={row?.private_tag} />}
        </div>,

        sortFunction: (a: any, b: any) => {
            let date1: any = a.first_name
            let date2: any = b.first_name
            return date1.toString().toLowerCase().localeCompare(date2.toString().toLowerCase())
        },
        sortable: true,
        id: "css",
        width: "230px",
    },
    {
        name: 'Availability',
        selector: (row: any) => row?.champ_availability,
        sortable: true,
        width: "180px",
    },
    {
        name: 'OPERATION STATUS',
        selector: (row: any) => {
            let clAttendance: any = row?.clAttendance
            let render: any = clAttendance?.length > 0 ? <StatusP2PUpdate row={row}></StatusP2PUpdate> : 'NA'
            return render
        },
        sortable: true,
        width: "180px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a.clAttendance?.length ? a.clAttendance[0]?.alert_reason_id : 0
            let date2: any = b.clAttendance?.length ? b.clAttendance[0]?.alert_reason_id : 0
            return date1.toString().localeCompare(date2.toString())
        },
        style: {
            whiteSpace: "break-spaces"
        }
    },
    {
        name: 'TOTAL JOB',
        selector: (row: any) => row?.totalJob,
        sortable: true,
        width: "auto",
    },
    {
        name: 'JOBS DONE',
        selector: (row: any) => {
            return row?.jobsDone
        },
        sortable: true,
        width: "auto",
    },
    {
        name: <span title='Overdue 6AM'>6 O D</span>,
        selector: (row: { overdue6: number; }) => row?.overdue6,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Overdue 7AM'>7 O D</span>,
        selector: (row: { overdue7: any; }) => row?.overdue7,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Overdue 8AM'>8 O D</span>,
        selector: (row: { overdue8: any; }) => row?.overdue8,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='On Time'>OT</span>,
        selector: (row: { jobsDoneOnTime: any; }) => row?.jobsDoneOnTime,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Job Done Early'>Early</span>,
        selector: (row: { jobsDoneEarly: any; }) => row?.jobsDoneEarly,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Job Done Late'>Late</span>,
        selector: (row: { jobsDoneLate: any; }) => row?.jobsDoneLate,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Not At Home'>NAH</span>,
        selector: (row: { notAtHome: any; }) => row?.notAtHome,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Jobs Missed Late'>J M L</span>,
        selector: (row: { champLate: any; }) => row?.champLate,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Jobs Missed Customer'>J M C</span>,
        selector: (row: { customerIssue: any; }) => row?.customerIssue,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='First Job Time'>F J T</span>,
        selector: (row: { firstAttendenceTime: any; }) => row?.firstAttendenceTime,
        sortable: true,
        width: "80px",
    },
    {
        name: <span title='Last Job Time'>L J T</span>,
        selector: (row: { lastAttendenceTime: any; }) => row?.lastAttendenceTime,
        sortable: true,
        width: "80px",
    },
    {
        name: 'Area Manager',
        selector: (row: { champSupervisor: any; }) => {
            let render: any = <RenderData supervisors={[row?.champSupervisor]} />
            return render
        },
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.champSupervisor?.first_name
            let date2: any = b?.champSupervisor?.first_name

            return date1?.toString()?.toLowerCase()?.localeCompare(date2?.toString()?.toLowerCase())
        },
        sortable: true,
        width: "200px",
        style: {
            whiteSpace: "break-spaces"
        }
    },
])

const StatusP2PUpdate = ({ row }: any) => {
    const aletReasonData = useSelector((store: any) => store?.ReasonsListDataReducer?.Alert_reason)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [aletReasonList, setaletReasonList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        setaletReasonList(aletReasonData)
    }, [aletReasonData])
    useEffect(() => {

        let updatedData: any = aletReasonData?.filter((ele: any) => {
            return row?.clAttendance?.length > 0 && ele?.id === storeRowP2P?.clAttendance[0]?.alert_reason_id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : { name: "No Status" })
    }, [aletReasonList, storeRowP2P?.clAttendance[0]?.alert_reason_id])

    return <>
        <span >
            <FontAwesomeIcon onClick={() => setupdateStatusPop(!updateStatusPop)} icon={faEdit} />  &nbsp; {showRes?.name}
        </span>
        <AlertStatusUpdate
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: setupdateStatusPop,
                selectedId: {
                    cleaner_id: row?.id,
                    alert_reason_id: row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.alert_reason_id : 0,
                    attendance_date: row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.attendance_date : ''
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></AlertStatusUpdate>
    </>
}


