import {useEffect, useRef, useState} from 'react'
import {HotTable} from '@handsontable/react'
import {registerAllModules} from 'handsontable/registry'
import 'handsontable/dist/handsontable.full.min.css'
import 'handsontable/dist/handsontable.full.min.css'
import 'react-tooltip/dist/react-tooltip.css'
import Handsontable from 'handsontable'
import {
  colWidthsHotTable,
  handleAfterSelectionRow,
  hotInstanceLoader,
  rocordTotalFound,
  searchFieldKeyupCallback,
  cellRenderInner,
  getFilteredRowDataNew2,
} from '../../../admins/adminUtils'
import moment from 'moment'
import MomentDate from '../../../../consts/MomentDate'
import MainContainerWrapper from '../../../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../../../consts/reuseModules/SearchBoxModal'
import RightBox from '../../../../consts/mediaQueryLayout/RightBox'
import Refresh from '../../../../consts/Refresh'
import DropDownFilterMenu from '../../../../consts/BootrapDropdown/DropDownFilterMenu'
import {AllFielSearch} from '../../../../consts/FieldSearch/AllFieldSearch'
import {getRequest, postRequest} from '../../../../consts/Server/Requests'
import {URL_EndPoints} from '../../../../consts/Server/URL_EndPoints'
import IsLoading from '../../../../consts/IsLoading/IsLoading'
import {KTCardBody} from '../../../../../_metronic/helpers'
import ShowTicketCountChartModal from '../../../ticketCountLists/ShowTicketCountChartModal'
import ShowFilterHint from '../../../ticketCountLists/ticketComponents/ShowFilterHint'
import InventoryOrderItemsCountFilter from './InventoryOrderItemsCountFilter'

registerAllModules()
const intervalType = [
  {name: 'Daily', id: 'daily'},
  {name: 'Weekly', id: 'weekly'},
  {name: 'Monthly', id: 'monthly'},
]

export const InventoryOrderItemsCounts = () => {
  const hotTableComponentRef = useRef<any>(null)
  const [search, setSearch] = useState<any>('')
  const [pending, setPending] = useState(false)
  const [filterData, setFilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [inventoryItems, setInventoryItems] = useState<any>([])
  const [orderStatuses, setOrderStatuses] = useState<any>([])
  const [supervisors, setSupervisors] = useState<any>([])
  const [cleaners, setCleaners] = useState<any>([])
  const [payload, setPayload] = useState<any>({
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: MomentDate(0),
    type: 'daily',
    inventory_item: '',
    order_status: '',
    champ_id: '',
    area_manager: '',
    item_id: '',
    status: '',
  })

  async function getOrderedItemsCountsList() {
    setFilteredSelectedRows([])
    setPending(true)
    const orderedItemsCountsData = await postRequest(
      URL_EndPoints(null)?.getOrderedItemsCount,
      payload,
      setPending
    )

    // Filter out ID fields from column
    const filteredColumns =
      orderedItemsCountsData?.data?.columns?.filter(
        (col: string) => !col.toLowerCase().includes('id')
      ) || []

    // Filter out ID fields from data
    const filteredData =
      orderedItemsCountsData?.data?.data?.map((item: any) => {
        const newItem = {...item}
        Object.keys(newItem).forEach((key) => {
          if (key.toLowerCase().includes('id')) {
            delete newItem[key]
          }
        })
        return newItem
      }) || []

    setColumns(filteredColumns)
    setFilterData(filteredData)
    setData(filteredData)
  }

  async function getDropdownData() {
    const itemsResponse = await getRequest(URL_EndPoints(null)?.getAllInventoryItems, setPending)
    const statusesResponse = await getRequest(
      URL_EndPoints(null)?.getAllMasterOrderStatus,
      setPending
    )
    const supervisorsResponse = await getRequest(URL_EndPoints(null)?.getSupervisorList, setPending)
    const cleanersResponse = await getRequest(URL_EndPoints(null)?.getCleanerList, setPending)

    setInventoryItems(itemsResponse?.data?.data || [])
    setOrderStatuses(statusesResponse?.data?.data || [])
    setSupervisors(supervisorsResponse?.data?.data || [])
    setCleaners(cleanersResponse?.data?.data || [])
  }

  useEffect(() => {
    getDropdownData()
    getOrderedItemsCountsList()
  }, [])

  let firstTimeNotRun = useRef<any>(false)
  useEffect(() => {
    let x = setTimeout(() => {
      const filterSearchData = AllFielSearch(data, search)
      firstTimeNotRun?.current === true && setFilterData(filterSearchData)
      if (firstTimeNotRun?.current === false) {
        firstTimeNotRun.current = true
      }
    }, 2000)
    return () => {
      clearTimeout(x)
    }
  }, [search])

  const searchHandler = () => {
    getOrderedItemsCountsList()
  }

  const onChangeHandler = (event: any, name: any) => {
    setPayload((payload: any) => ({...payload, [name]: event ? event?.value : event}))
  }

  const removeUnderScore = (str: any) => {
    if (str?.includes('_')) {
      return str?.split('_')?.join(' ')?.toUpperCase()
    } else {
      return str?.toUpperCase()
    }
  }

  const [showChart, setShowChart] = useState(false)

  // hot table code ------------------------------------
  useEffect(() => {
    hotInstanceLoader(hotTableComponentRef)
  }, [])

  const handleAfterSelection = (
    row: any,
    column: any,
    row2: any,
    column2: any,
    preventScrolling: any,
    selectionLayerLevel: any
  ) => {
    handleAfterSelectionRow(
      setFilteredSelectedRows,
      row,
      column,
      row2,
      column2,
      preventScrolling,
      selectionLayerLevel
    )
  }

  const cellRenderer = (...args: any) => {
    const [instance, td, row, col, prop, value, cellProperties] = args

    Handsontable.renderers.TextRenderer.apply(this, args)
    const rowData = instance.getSourceDataAtRow(row)
    const isParentRow = rowData?.__children
    if (isParentRow && prop === 'category') {
      td.style.backgroundColor = '#059df0'
      td.style.color = 'white'
    }
    else if (!isParentRow && value > 0) {
      td.style.backgroundColor = '#e8f0fe'
    }
  }

  const shortWords = (sentence: any, index: any) => {
    if (sentence === 'Total') {
      return 'TOTAL'
    }
    if (index < 1) {
      return removeUnderScore(sentence)
    }
    if (sentence == '__children') return
    else {
      let str = sentence?.split(' ')?.[1]
      return str
    }
  }

  const mapIdToName = (id: any, list: any, nameKey = 'name') => {
    const item = list.find((item: any) => item.id === id);
    if (!item) return "";
    
    if (item.first_name && item.last_name) {
      return `${item.first_name} ${item.last_name}`;
    }
    return item[nameKey];
  };

  const mappedPayload = {
    ...payload,
    status: mapIdToName(payload.status, orderStatuses),
    area_manager: mapIdToName(payload.area_manager, supervisors),
    champ_id: mapIdToName(payload.champ_id, cleaners),
  };

  return (
    <>
      <MainContainerWrapper>
        <KTCardBody>
          <MainContainerWrapper>
            <LeftBox>
              <SearchBoxModal search={search} setSearch={setSearch} />
            </LeftBox>
            <RightBox>
              <Refresh refreshNow={searchHandler} />
              {
                <DropDownFilterMenu
                  ParentData={{
                    handleActions: searchHandler,
                    payloads: payload,
                    onChangeHandler: onChangeHandler,
                    intervalType: intervalType,
                    defaultInterval: {label: 'Daily', value: 'daily'},
                    inventoryItems: inventoryItems?.map((item: any) => ({
                      id: item.id,
                      name: item.title,
                    })),
                    orderStatuses: orderStatuses?.map((status: any) => ({
                      id: status.id,
                      name: status.name,
                    })),
                    supervisors: supervisors?.map((supervisor: any) => ({
                      id: supervisor.id,
                      name: supervisor.first_name + ' ' + supervisor.last_name,
                    })),
                    cleaners: cleaners?.map((cleaner: any) => ({
                      id: cleaner.id,
                      name: cleaner.first_name + ' ' + cleaner.last_name,
                    })),
                    defaultCleaner: null,
                    defaultSupervisor: null,
                    defaultInventoryItem: null,
                    defaultOrderStatus: null,
                  }}
                  Component={InventoryOrderItemsCountFilter}
                />
              }
            </RightBox>
          </MainContainerWrapper>

          {/* hint components */}
          <ShowFilterHint payload={mappedPayload} />

          {pending ? (
            <IsLoading />
          ) : (
            <div>
              <HotTable
                style={{zIndex: 1}}
                data={filterData}
                className='orderedItemsCounts'
                filters={true}
                dropdownMenu={true}
                colWidths={colWidthsHotTable(columns, 1, 150, 55)}
                width='100%'
                height={'80vh'}
                title='Ordered Items Counts'
                rowHeaders={true}
                colHeaders={columns.map((ele: any, index: any) => shortWords(ele, index))}
                fixedColumnsLeft={1}
                contextMenu={true}
                manualColumnFreeze={true}
                autoWrapRow={true}
                autoWrapCol={true}
                licenseKey='non-commercial-and-evaluation'
                autoRowSize={true}
                autoColumnSize={true}
                readOnly={true}
                manualRowResize={true}
                manualColumnResize={true}
                manualRowMove={true}
                multiColumnSorting={true}
                search={{callback: searchFieldKeyupCallback}}
                afterSelection={handleAfterSelection}
                ref={hotTableComponentRef}
                preventOverflow='horizontal'
                nestedRows={true}
                bindRowsWithHeaders={true}
                cells={(row, col) => {
                  return {
                    renderer: cellRenderer,
                  }
                }}
              />
            </div>
          )}
        </KTCardBody>
      </MainContainerWrapper>
    </>
  )
}
