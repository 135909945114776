import { faCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import "./AsidMenuCss.css"
import { isAllowedUser } from "../../../../app/consts/components/Utility";
import PermissionContextProvider from "../../../../app/modules/admins/adminComponents/PermissionContextProvider";
import { adminMenuActionPermissionKeys } from "../../../../app/modules/admins/adminConstant";

export const keyTitle: any = {
    dashboard: 'Dashboard',
    dashboardStatics: 'Dashboard Statics',
    ticketDashboard: 'Ticket Dashboard',
    newTicketDashboard: 'New Ticket Dashboard',
    newDailyDashboard: 'New Daily Dashboard',
    agentDailyDashbaord: 'Agent Daily Dashbaord',
    rewardAndPenalties: 'Reward And Penalties',
    adminPanelCarselona: 'Admin Panel Carselona',
    deviceLoginAllowed: 'Device Login Allowed',
    dailyDashboard: 'Daily Dashboard',
    iframeDashboard: 'Iframe Dashboard',
    newDeactivatedChampList: 'New Deactivated Champ List',
    activePaid: 'Active Paid',
    newActivePaid: 'New Active Paid',
    onDemand: 'On Demand',
    kitSubscriptions: 'Kit Subscriptions',
    inactive: 'Inactive',
    serverInactive: 'Server Inactive',
    renewalList: 'Renewal List',
    dateWiseOnboarding: 'Date wise Onboarding',
    onboardingSubscriptions: 'Onboarding Subscriptions',
    unAssigned: 'Un-Assigned',
    jobList: 'Job List',
    searchAvailibility: 'Search Availibility',
    cleanerAvailibilty: 'Cleaner Availibilty',
    requirementType: 'Requirement Type',
    termsAndCondition: 'Terms And Condition',
    createBatch: 'Create Batch',
    whatsappGroups: 'Whatsapp Groups',
    tagLists: 'Tag Lists',
    whatsappTemplate: 'Whatsapp Template',
    masterSource: 'Master Source',
    services: 'Services',
    products: 'Products',
    packages: 'Packages',
    fuelType: 'Fuel Type',
    vehicleType: 'Vehicle Type',
    vehicleCategory: 'Vehicle Category',
    vehicleBrand: 'Vehicle Brand',
    vehicleModel: 'Vehicle Model',
    problem: 'Problem',
    subProblem: 'Sub Problem',
    tShirt: 'T-Shirt',
    jobSites: 'Job Sites',
    jobType: 'Job Type',
    userType: 'User Type',
    pointsTable: 'Point-Table',
    creditReasons: 'Credit Reasons',
    jobStatus: 'Job Status',
    masterReasons: 'Master Reasons',
    masterResource: 'Master Resource',
    masterSources: 'Master Sources',
    masterOffers: 'Master Offers',
    masterTicketsCategory: 'Master Tickets Category',
    masterTicketsSubCategory: 'Master Tickets Sub Category',
    country: 'Country',
    state: 'State',
    city: 'City',
    pincode: 'Pincode',
    campaignDashboard: 'Campaign Dashboard',
    createCampaign: 'Create Campaign',
    allCampaigns: 'All Campaigns',
    rewards: 'Rewards',
    redeemtions: 'Redeemtions',
    companyCleanerMAPs: 'Company Cleaner MAP',
    customerCleanerMAPs: 'Customer Cleaner MAP',
    comanyRequirementMap: 'Company Requirement MAP',
    subLocationMap: 'Sub Location Map',
    cleanerRoutesMAPs: 'Cleaner Routes MAPs',
    societyMAPs: 'Society MAPs',
    cleanerMAPs: 'Cleaner MAPs',
    pincodeMarkerMAPs: 'Pincode Marker MAPs',
    allCustomerCleanerMAPs: 'All Customer Cleaner MAPs',
    customerStats: 'Customer Stats',
    outboundResult: 'Outbound result',
    inboundResult: 'Inbound result',
    items: 'Items',
    returnTaskItem: 'Return Task Item',
    orderStatus: 'Order Status',
    orderList: 'Order List',
    newOrderList: 'New Order List',
    warehouse: 'Warehouse',
    vendor: 'Vendor',
    warehouseAlertStatus: 'Warehouse Alert Status',
    warehouseType: 'Warehouse Type',
    purchaseOrder: 'Purchas Order',
    materialTransactions: 'Material Transactions',
    warehouseStockTransfer: 'Warehouse Stock Transfer',
    inventoryOrderedItemsCounts: 'Inventory Ordered Items Counts',
    warehouseItemsCount: 'Warehouse Items Count',
    tickets: 'Tickets',
    newTickets: 'New Tickets',
    renewalSubscription: 'Renewal Subscription',
    newRenewalSubscription: 'New Renewal Subscription',
    resumedList: 'Resumed Subscription List',
    pausePaid: 'Pause Paid',
    pauseUnPaid: 'Pause UnPaid',
    inactiveAutometically: 'Inactive Autometically',
    inactiveAgent: 'Inactive Agent',
    futurePause: 'Future Pause',
    visitorsList: 'Visitors List',
    champReferralList: 'Champ Referral List',
    // champsVisitorsList: 'Champs Visitors List',
    customerLeads: 'Customer Leads',
    champLeads: 'Champ Leads',
    taskLists: 'Task Lists',
    renewalSupervisorList: 'Renewal Supervisor List',
    active: 'Active',
    attendance: 'Attendance',
    champMissedCallList: 'Champ Missed Call List',
    absentList: 'Absent List',
    leaveList: 'Leave List',
    deactivated: 'Deactivated',
    schemes: 'Schemes',
    deductionJobs: 'Deduction Jobs',
    onboardingPending: 'Onboarding Pending',
    leads: 'Leads',
    league: 'League',
    trainingSchedules: 'Training Schedules',
    trainingLocations: 'Training Locations',
    champRequirement: 'Champ Requirement',
    trainingTopics: 'Training Topics',
    areas: 'Areas',
    hiringDashboard: 'Hiring Dashboard',
    hiringDashboard2: 'Hiring Dashboard 2',
    requirementMap: 'Requirement Map',
    notificationTemplate: 'Notification Template',
    notificationDashboard: 'Notification Dashboard',
    welcomeList: 'Welcome List',
    weeklyPayoutReport: 'Weekly Payout Report',
    newWeeklyPayoutReport: 'New Weekly Payout Report',
    inApp: 'All Ratings',
    // playStore: 'Play Store',
    // appStore: 'App Store',
    adminUsers: 'Admin Users',
    workingOn: 'Working on',
    workInProgress: 'Work in progress',
    roleModules: 'Role Modules',
    societies: 'societies',
    invoices: 'invoices',
    subscriptionMonth: 'Subscription Month',
    settings: 'Settings',
    luckyDraw: 'Lucky Draw',
    // menuColumnToggleHander: 'Toggle Menu/Cols Options',
    approvalLists: 'Approval Lists',
    customerVehicleList: 'Inactive Vehicle list',
    vehicleSubscriptionHistoryList: 'Vehicle Subscription History List',
    renewalTaskList: 'Customer Connect Task List',
    welcomeCall1List: 'Welcome Call List',
    welcomeCall2List: 'Welcome Call List 2',
    champDeviceLogin: 'Champ Device Login',
    customerMissedCallLists: 'Customer Missed Call Lists',
    accessAreaManager: 'Area manager access',
    cronHistoryList: 'Cron History List',
    customerAssignedAgents: 'Customer Assigned Agents',
    easebuzzTransactionHiistory: 'Easebuzz Transaction Hiistory',
    deactivationNotes: 'Deactivation Notes',
    loanLists: 'Loan Lists',
    loanTransaction: 'Loan Transaction',
    frequentlyAskedQuestion: 'Frequently Asked Question',
    campaignListSid: 'Campaign List Sid',
    trainingList: 'Training List',
    payoutTimeline: 'Payout Timeline',
    ticketCountList: 'Ticket Count List',
    ticketCountSourceWise: 'Ticket Count Source Wise',
    champHiringAutomation: 'Champ Hiring Automation',
    newChampAssignmentList: 'New Champ Assignment List',
    developerTools: 'Developer Tools',
    leadsCountSourceWise: 'Leads Count Source Wise',
    paymentCounts: 'Payment Counts',
    salesCounts: 'Sales Counts',
    champHiringCounts: 'Champ HiringCounts',
    autopayCounts: 'Autopay Counts',
    reviewCounts: 'Review Counts',
    trainingTaskCount: 'Training Task Count',
    attritionData: 'Attrition Data',
    facebookLeads: 'Facebook Leads',
    adsInsights: 'Ads Insights',
    longNah: 'Long Nah',
    sourceVsLeadsStatus: 'Source Vs Leads Status',
    userCallStatus: 'User Call Status',
    // new add here  
    areaManagerJobDashboard: 'Area Manager Job Dashboard',
    unsuccessfulSubscriptions: 'Unsuccessful Subscriptions',
    jobMismatchSubscriptions: 'Job Mismatch Subscriptions',
    noJobSubscriptionList: 'No Job Subscription List',
    agentOfferAllotment: 'Agent Offer Allotment',
    agentOffersList: 'Agent Offers List',
    notAtHomeList: 'Not At Home List',
    goodsReceipt: "Goods Receipt",
    paymentReceipt: "Payment Receipt",
    proformaInvoice: "Proforma Invoice",
    vendorInvoice: "Vendor Invoice",
    commonLeads: "Common Leads",
    auditFormList: "Audit Form List",
    approvalList: "Approval List",

};

export const menuTitle: any = {
    Subscriptions: "Subscriptions",
    Schedules: "Schedules",
    Masters: "Masters",
    Address: "Address",
    Campaign: "Campaign",
    Maps: "Maps",
    Statistics: "Statistics",
    Call_Results: "Call Results",
    Inventory: "Inventory",
    Dashboards: "Dashboards",
    All_Tickets: "All Tickets",
    Data_Lists: "Data Lists",
    Champs: "Champs",
    Hiring: "Hiring",
    Notifications: "Notifications",
    All_Ratings: "Ratings",
    User_management: "User management",
    Invoice_List: "Invoice List",
    Access: 'Access',
    Easebuzz: 'Easebuzz',
    Cron: 'Cron',
    Loan: 'Loan',
    faq: 'FAQ',
    champCampaign: "Champ Campaign",
    modelTraining: "Model Training",
    Developer_Tools: "Developer Tools",
    Tracking_List: "Tracking List",
    rolesAndPermissions: "Roles And Permissions",

}

export const AsidMenuMainSearchListRoutes = [
    { to: '/ticket-dashboard', title: keyTitle.ticketDashboard, hasBullet: true, ID: 'ticket-dashboard' },
    { to: '/admin/job-dashboard', title: keyTitle.dailyDashboard, hasBullet: true, ID: 'daily-dashboard' },
    { to: '/iframe-dashboard', title: keyTitle.iframeDashboard, hasBullet: true, ID: 'iframe-dashboard' },
    isAllowedUser?.includes(Number(localStorage.getItem("user"))) && { to: '/admin/device-login-allowed', title: keyTitle.deviceLoginAllowed, hasBullet: true, ID: 'device-login-allowed' },
    { to: '/subscriptions/active-paid', title: keyTitle.activePaid, hasBullet: true, ID: 'active-paid' },
    { to: '/subscriptions/inactive-subscriptions-list', title: keyTitle.onDemand, hasBullet: true, ID: 'on-demand' },
    { to: '/subscriptions/vehicles/new', title: keyTitle.kitSubscriptions, hasBullet: true, ID: 'kit-subscriptions' },
    { to: '/subscriptions/renewed', title: keyTitle.inactive, hasBullet: true, ID: 'inactive' },
    { to: '/subscriptions/reactivated', title: keyTitle.serverInactive, hasBullet: true, ID: 'server-inactive' },
    { to: '/subscriptions/future-pause', title: keyTitle.renewalList, hasBullet: true, ID: 'renewal-list' },
    { to: '/subscriptions/monthly-onboard', title: keyTitle.dateWiseOnboarding, hasBullet: true, ID: 'date-wise-onboarding' },
    { to: '/subscriptions/pending-onboard', title: keyTitle.onboardingSubscriptions, hasBullet: true, ID: 'onboarding-subscriptions' },
    { to: '/schedule/cleaner/un-assigned', title: keyTitle.unAssigned, hasBullet: true, ID: 'un-assigned' },
    { to: '/schedule/cleaner/job-list', title: keyTitle.jobList, hasBullet: true, ID: 'job-list' },
    { to: '/schedule/cleaner/area-wise-cleaner', title: keyTitle.searchAvailibility, hasBullet: true, ID: 'search-availibility' },
    { to: '/schedule/cleaner-area-availibilty', title: keyTitle.cleanerAvailibilty, hasBullet: true, ID: 'cleaner-availibilty' },
    { to: '/master/requirement-type', title: keyTitle.requirementType, hasBullet: true, ID: 'requirement-type' },
    { to: '/master/terms-and-condition', title: keyTitle.termsAndCondition, ID: "terms-and-condition" },
    { to: '/master/create-batch', title: keyTitle.createBatch, hasBullet: true, ID: 'create-batch' },
    { to: '/master/services', title: keyTitle.services, hasBullet: true, ID: 'services' },
    { to: '/master/tag-lists', title: keyTitle.services, hasBullet: true, ID: 'services' },
    { to: '/master/products', title: keyTitle.products, hasBullet: true, ID: 'products' },
    { to: '/master/packages', title: keyTitle.packages, hasBullet: true, ID: 'packages' },
    { to: '/master/fueltype', title: keyTitle.fuelType, hasBullet: true, ID: 'fuel-type' },
    { to: '/master/vehicle-type', title: keyTitle.vehicleType, hasBullet: true, ID: 'vehicle-type' },
    { to: '/master/vehicle-category', title: keyTitle.vehicleCategory, hasBullet: true, ID: 'vehicle-category' },
    { to: '/master/vehicle-brand', title: keyTitle.vehicleBrand, hasBullet: true, ID: 'vehicle-brand' },
    { to: '/master/vehicle-model', title: keyTitle.vehicleModel, hasBullet: true, ID: 'vehicle-model' },
    { to: '/master/problem', title: keyTitle.problem, hasBullet: true, ID: 'problem' },
    { to: '/master/sub-problem', title: keyTitle.subProblem, hasBullet: true, ID: 'sub-problem' },
    { to: '/master/t-shirt', title: keyTitle.tShirt, hasBullet: true, ID: 't-shirt' },
    { to: '/master/job-sites', title: keyTitle.jobSites, hasBullet: true, ID: 'job-sites' },
    { to: '/master/job-type', title: keyTitle.jobType, hasBullet: true, ID: 'job-type' },
    { to: '/master/user-type', title: keyTitle.userType, hasBullet: true, ID: 'user-type' },
    { to: '/master/points-table', title: keyTitle.pointsTable, hasBullet: true, ID: 'points-table' },
    { to: '/master/credit-reasons', title: keyTitle.creditReasons, hasBullet: true, ID: 'credit-reasons' },
    { to: '/master/job-status', title: keyTitle.jobStatus, hasBullet: true, ID: 'job-status' },
    { to: '/master/master-reasons', title: keyTitle.masterReasons, hasBullet: true, ID: 'master-reasons' },
    { to: '/master/master-resource', title: keyTitle.masterResource, hasBullet: true, ID: 'master-resource' },
    { to: '/master/master-sources', title: keyTitle.masterSources, hasBullet: true, ID: 'master-sources' },
    { to: '/master/master-offers', title: keyTitle.masterOffers, hasBullet: true, ID: 'master-offers' },
    { to: '/master/master-ticket-category', title: keyTitle.masterTicketsCategory, hasBullet: true, ID: 'master-tickets-category' },
    { to: '/master/master-ticket-sub-category', title: keyTitle.masterTicketsSubCategory, hasBullet: true, ID: 'master-tickets-sub-category' },
    { to: '/address/country', title: keyTitle.country, hasBullet: true, ID: 'country' },
    { to: '/address/state', title: keyTitle.state, hasBullet: true, ID: 'state' },
    { to: '/address/city', title: keyTitle.city, hasBullet: true, ID: 'city' },
    { to: '/address/pincode', title: keyTitle.pincode, hasBullet: true, ID: 'pincode' },
    { to: '/campaigns/dashboards', title: keyTitle.campaignDashboard, hasBullet: true, ID: 'campaign-dashboard' },
    { to: '/campaigns/create-campaign', title: keyTitle.createCampaign, hasBullet: true, ID: 'create-campaign' },
    { to: '/campaigns/all-campaign', title: keyTitle.allCampaigns, hasBullet: true, ID: 'all-campaigns' },
    { to: '/campaigns/reward', title: keyTitle.rewards, hasBullet: true, ID: 'rewards' },
    { to: '/campaigns/redeemtions', title: keyTitle.redeemtions, hasBullet: true, ID: 'redeemtions' },
    { to: '/maps/comapany-cleaner-map', title: keyTitle.companyCleanerMAPs, hasBullet: true, ID: 'company-cleaner-maps' },
    { to: '/maps/customer-cleaner-map', title: keyTitle.customerCleanerMAPs, hasBullet: true, ID: 'customer-cleaner-maps' },
    { to: '/maps/requirement-map', title: keyTitle.comanyRequirementMap, hasBullet: true, ID: 'comany-requirement-map' },
    { to: '/maps/sub-location-map', title: keyTitle.subLocationMap, hasBullet: true, ID: 'sub-location-map' },
    { to: '/maps/cleaner-route-map', title: keyTitle.cleanerRoutesMAPs, hasBullet: true, ID: 'cleaner-routes-maps' },
    { to: '/maps/society-map', title: keyTitle.societyMAPs, hasBullet: true, ID: 'society-maps' },
    { to: '/maps/cleaner-map', title: keyTitle.cleanerMAPs, hasBullet: true, ID: 'cleaner-maps' },
    { to: '/maps/pincode-marker-map', title: keyTitle.pincodeMarkerMAPs, hasBullet: true, ID: 'pincode-marker-maps' },
    { to: '/maps/all-customer-cleaner-map', title: keyTitle.allCustomerCleanerMAPs, hasBullet: true, ID: 'all-customer-cleaner-maps' },
    { to: '/apps/stats/customers', title: keyTitle.customerStats, hasBullet: true, ID: 'customer-stats' },
    { to: '/callresults/outboundcalls', title: keyTitle.outboundResult, hasBullet: true, ID: 'outbound-result' },
    { to: '/callresults/inboundcalls', title: keyTitle.inboundResult, hasBullet: true, ID: 'inbound-result' },
    { to: '/inventory/items', title: keyTitle.items, hasBullet: true, ID: 'items' },
    { to: '/inventory/return-task-list', title: keyTitle.returnTaskItem, hasBullet: true, ID: 'return-task-list'},
    { to: '/inventory/order-status', title: keyTitle.orderStatus, hasBullet: true, ID: 'order-status' },

    { to: '/inventory/orders-listing', title: keyTitle.orderList, hasBullet: true, ID: 'order-list' },
    { to: '/inventory/new-orders-listing', title: keyTitle.newOrderList, hasBullet: true, ID: 'new-order-list' },
    { to: '/inventory/warehouse', title: keyTitle.warehouse, hasBullet: true, ID: 'warehouse' },
    { to: '/inventory/warehouse-alert-status', title: keyTitle.warehouseAlertStatus, hasBullet: true, ID: 'warehouse-alert-status' },
    { to: '/inventory/warehouse-type', title: keyTitle.warehouseType, hasBullet: true, ID: 'warehouse-type' },
    { to: '/inventory/purchase-order', title: keyTitle.purchaseOrder, hasBullet: true, ID: 'purchase-order' },
    { to: '/inventory/vendor', title: keyTitle.vendor, hasBullet: true, ID: 'vendor' },
    { to: '/inventory/material-transactions', title: keyTitle.materialTransactions, hasBullet: true, ID: 'material-transactions' },
    { to: '/inventory/warehouse-stock-transfer', title: keyTitle.warehouseStockTransfer, hasBullet: true, ID: 'warehouse-stock-transfer' },
    { to: '/admin/new-tickets', title: keyTitle.newTickets, hasBullet: true, ID: 'new-tickets' },
    { to: '/new-renewal-subscriptions', title: keyTitle.newRenewalSubscription, hasBullet: true, ID: 'new-renewal-subscription' },
    { to: '/resumed-subscriptions', title: keyTitle.resumedList, hasBullet: true, ID: 'resumed-list' },
    { to: '/inactive-pause-paid', title: keyTitle.pausePaid, hasBullet: true, ID: 'pause-paid' },
    { to: '/inactive-pause-unpaid', title: keyTitle.pauseUnPaid, hasBullet: true, ID: 'pause-unpaid' },
    { to: '/inactive-auto', title: keyTitle.inactiveAutometically, hasBullet: true, ID: 'inactive-autometically' },
    { to: '/inactive-agent', title: keyTitle.inactiveAgent, hasBullet: true, ID: 'inactive-agent' },
    { to: '/future-pause', title: keyTitle.futurePause, hasBullet: true, ID: 'future-pause' },
    { to: '/champ-referral', title: keyTitle.champReferralList, hasBullet: true, ID: 'champ-referral-list' },
    { to: '/customer-leads', title: keyTitle.customerLeads, hasBullet: true, ID: 'customer-leads' },
    { to: '/champs/champ-leads', title: keyTitle.champLeads, hasBullet: true, ID: 'champ-leads' },
    { to: '/task-lists', title: keyTitle.taskLists, hasBullet: true, ID: 'task-lists' },
    { to: '/master/whatsapp-groups', title: keyTitle.whatsappGroups, hasBullet: true, ID: 'whatsapp-groups' },
    { to: '/inactive-vehicle-list', title: keyTitle.customerVehicleList, hasBullet: true, ID: 'customer-vehicle-list' },
    { to: '/data/renewal-supervisor-list', title: keyTitle.renewalSupervisorList, hasBullet: true, ID: 'renewal-supervisor-list' },
    { to: '/schedule/cleaner/cleanerlist', title: keyTitle.active, hasBullet: true, ID: 'active' },
    { to: '/apps/cleaner/cleanerattendance', title: keyTitle.attendance, hasBullet: true, ID: 'attendance' },
    { to: '/apps/cleaner/champ-missed-list', title: keyTitle.champMissedCallList, hasBullet: true, ID: 'champ-missed-call-list' },
    { to: '/absent/cleanerList', title: keyTitle.absentList, hasBullet: true, ID: 'absent-list' },
    { to: '/apps/cleaner/cleanerleaves', title: keyTitle.leaveList, hasBullet: true, ID: 'leave-list' },
    { to: '/apps/cleaner/deactivatedcleanerlist', title: keyTitle.deactivated, hasBullet: true, ID: 'deactivated' },
    { to: '/champ-schemes', title: keyTitle.schemes, hasBullet: true, ID: 'schemes' },
    { to: '/deduction-jobs', title: keyTitle.deductionJobs, hasBullet: true, ID: 'deduction-jobs' },
    { to: '/apps/cleaner/onboardingpendingcleanerlist', title: keyTitle.onboardingPending, hasBullet: true, ID: 'onboarding-pending' },
    { to: '/apps/cleaner/leads', title: keyTitle.leads, hasBullet: true, ID: 'leads' },
    { to: '/apps/cleaner/leagues', title: keyTitle.league, hasBullet: true, ID: 'league' },
    { to: '/old-deactivated-champ', title: keyTitle.newDeactivatedChampList, hasBullet: true, ID: 'new-deactivated-champ-list' },
    { to: '/hiring/training-schedules', title: keyTitle.trainingSchedules, hasBullet: true, ID: 'training-schedules' },
    { to: '/hiring/training-location', title: keyTitle.trainingLocations, hasBullet: true, ID: 'training-locations' },
    { to: '/hiring/champ-requirement', title: keyTitle.champRequirement, hasBullet: true, ID: 'champ-requirement' },
    { to: '/hiring/training-topics', title: keyTitle.trainingTopics, hasBullet: true, ID: 'training-topics' },
    { to: '/hiring/requirement-map', title: keyTitle.requirementMap, hasBullet: true, ID: 'requirement-map' },
    { to: '/hiring/areas', title: keyTitle.areas, hasBullet: true, ID: 'areas' },
    { to: '/apps/cleaner/NotificationTemplate', title: keyTitle.notificationTemplate, fontIcon: 'bi bi-back', hasBullet: true, ID: 'notification-template' },
    { to: '/notification-dashboard', title: keyTitle.notificationDashboard, fontIcon: 'bi bi-bell-fill', hasBullet: true, ID: 'notification-dashboard' },
    { to: '/inapp/ratings', title: keyTitle.inApp, hasBullet: true, ID: 'in-app' },
    { to: '/master/admin-users', title: keyTitle.adminUsers, hasBullet: true, ID: 'admin-users' },
    { to: '/invoice/invoices', title: keyTitle.invoices, hasBullet: true, ID: 'invoices' },
    { to: '/invoice/subscriptions', title: keyTitle.subscriptionMonth, hasBullet: true, ID: 'subscription-month' },
    { to: '/working-on', title: keyTitle.workingOn, fontIcon: 'bi bi-hourglass-split', ID: 'working-on' },
    { to: '/WorkingOn2', title: keyTitle.workInProgress, fontIcon: 'bi bi-hourglass-split', ID: 'work-in-progress' },
    { to: '/role-module', title: keyTitle.roleModules, fontIcon: 'bi bi-hourglass-split', ID: 'role-modules' },
    { to: '/all/Societies', title: keyTitle.societies, fontIcon: 'bi bi-geo-fill', ID: 'societies' },
    { to: '/welcome-list', title: keyTitle.welcomeList, fontIcon: 'bi bi-bag-plus', ID: 'welcome-list' },
    { to: '/apps/cleaner/weeklysummery', title: keyTitle.weeklyPayoutReport, fontIcon: 'bi bi-wallet2', ID: 'weekly-payout-report' },
    { to: '/apps/cleaner/weekly-payout-report', title: keyTitle.newWeeklyPayoutReport, fontIcon: 'bi bi-wallet2', ID: 'new-weekly-payout-report' },
    { to: '/admin/settings', title: keyTitle.settings, hasBullet: true, ID: 'settings' },
    isAllowedUser?.includes(Number(localStorage.getItem("user"))) && { to: '/admin/approval-list', title: keyTitle.approvalLists, hasBullet: true, ID: 'approval-lists' },
    { to: '/inactive-vehicle-list', title: keyTitle.customerVehicleList, hasBullet: true, ID: 'customer-vehicle-list' },
    { to: '/vehicle-subscription-history-list', title: keyTitle.vehicleSubscriptionHistoryList, hasBullet: true, ID: 'vehicle-subscription-history-list' },
    { to: '/renewal-task-list', title: keyTitle.renewalTaskList, hasBullet: true, ID: 'renewal-task-list' },
    { to: '/welcome-call-1-list', title: keyTitle.welcomeCall1List, hasBullet: true, ID: 'welcome-call-1-list' },
    { to: '/welcome-call-2-list', title: keyTitle.welcomeCall2List, hasBullet: true, ID: 'welcome-call-2-list' },
    { to: '/customer-assigned-agents', title: keyTitle.customerAssignedAgents, hasBullet: true, ID: 'customer-assigned-agents' },
    { to: '/customer/customer-missed-list', title: keyTitle.customerMissedCallLists, hasBullet: true, ID: 'customer-missed-call-lists' },
    { to: '/easebuzz/transaction-history', title: keyTitle.easebuzzTransactionHiistory, hasBullet: true, ID: 'easebuzz-transaction-history' },
    { to: '/new-daily-dashboard', title: keyTitle.newDailyDashboard, hasBullet: true, ID: 'new-daily-dashboard' },
    { to: '/agent-daily-dashboard', title: keyTitle.agentDailyDashbaord, hasBullet: true, ID: 'agent-daily-dashboard' },
    { to: '/cron/history', title: keyTitle.cronHistoryList, hasBullet: true, ID: 'cron-history-list' },
    { to: '/loan/history', title: keyTitle.loanLists, hasBullet: true, ID: 'loan-lists' },
    { to: '/champ-campaign/list-sid', title: keyTitle.campaignListSid, hasBullet: true, ID: 'campaign-list-sid' },
    { to: '/schedule/champ/champ-absent', title: keyTitle.campaignListSid, hasBullet: true, ID: 'champ-absent' },
    { to: '/faq/frequently-asked-question', title: keyTitle.frequentlyAskedQuestion, hasBullet: true, ID: 'frequently-asked-question' },
    { to: '/loan/transaction', title: keyTitle.loanTransaction, hasBullet: true, ID: 'loan-transaction' },
    { to: '/master/deactivation-notes', title: keyTitle.deactivationNotes, hasBullet: true, ID: 'deactivation-notes' },
    { to: '/model-training/training-list', title: keyTitle.trainingList, hasBullet: true, ID: 'training-list' },
    { to: '/payout/payout-timeline', title: keyTitle.payoutTimeline, hasBullet: true, ID: 'payout-timeline' },
    { to: '/tracking/ticket-count-list', title: keyTitle.ticketCountList, hasBullet: true, ID: 'ticket-count-list' },
    { to: '/hiring/hiring-automation', title: keyTitle.champHiringAutomation, hasBullet: true, ID: 'champ-hiring-automation' },
    { to: '/champs/new-champ-assignment-list', title: keyTitle.newChampAssignmentList, hasBullet: true, ID: 'new-champ-assignment-list' },
    { to: '/developer/tools', title: keyTitle.developerTools, hasBullet: true, ID: 'developer-tools' },
    { to: '/tracking/leads-count-source-wise', title: keyTitle.leadsCountSourceWise, hasBullet: true, ID: 'leads-count-source-wise' },
    { to: '/tracking/inventory-ordered-items-counts', title: keyTitle.inventoryOrderedItemsCounts, hasBullet: true, ID: 'inventory-ordered-items-counts' },
    { to: '/tracking/warehouse-items-count', title: keyTitle.warehouseItemsCount, hasBullet: true, ID: 'warehouse-items-count' },
    { to: '/tracking/payment-counts', title: keyTitle.paymentCounts, hasBullet: true, ID: 'payment-counts' },
    { to: '/tracking/sales-counts', title: keyTitle.salesCounts, hasBullet: true, ID: 'sales-counts' },
    { to: '/tracking/champ-hiring-counts', title: keyTitle.champHiringCounts, hasBullet: true, ID: 'champ-hiring-counts' },
    { to: '/tracking/review-counts', title: keyTitle.reviewCounts, hasBullet: true, ID: 'review-counts' },
    { to: '/tracking/training-task-counts', title: keyTitle.trainingTaskCount, hasBullet: true, ID: 'training-task-counts' },
    { to: '/tracking/attrition-data', title: keyTitle.attritionData, hasBullet: true, ID: 'attrition-data' },
    { to: '/tracking/ads-insights', title: keyTitle.adsInsights, hasBullet: true, ID: 'ads-insights' },
    { to: '/tracking/facebook-leads', title: keyTitle.facebookLeads, hasBullet: true, ID: 'facebook-leads' },
    { to: '/tracking/long-nah', title: keyTitle.longNah, hasBullet: true, ID: 'long-nag' },
    { to: '/tracking/source-vs-leads', title: keyTitle.sourceVsLeadsStatus, hasBullet: true, ID: 'source-vs-leads-status' },
    { to: '/tracking/user-call-status', title: keyTitle.userCallStatus, hasBullet: true, ID: 'user-call-status' },
    { to: '/tracking/user-logout-status', title: keyTitle.userCallStatus, hasBullet: true, ID: 'user-logout-status' },
    { to: '/tracking/autopay-counts', title: keyTitle.autopayCounts, hasBullet: true, ID: 'autopay-counts' },
    { to: '/area-manager-job-dashboard', title: keyTitle.areaManagerJobDashboard, hasBullet: true, ID: 'am-job-dashboard' },
    { to: '/subscriptions/unsuccessful-subscriptions', title: keyTitle.unsuccessfulSubscriptions, hasBullet: true, ID: 'unsuccessful-subscriptions' },
    { to: '/subscriptions/job-mismatch-subscriptions', title: keyTitle.jobMismatchSubscriptions, hasBullet: true, ID: 'job-mismatch-subscriptions' },
    { to: '/schedule/no-job-subscription-list', title: keyTitle.noJobSubscriptionList, hasBullet: true, ID: 'no-job-subscription-list' },
    // { to: '/user/agent-offer-allotment-list', title: keyTitle.agentOfferAllotment, hasBullet: true, ID: 'agent-offer-allotment' },
    { to: '/user/agent-offers-list', title: keyTitle.agentOffersList, hasBullet: true, ID: 'agent-offers-list' },
    { to: '/inventory/goods-receipt', title: keyTitle.goodsReceipt, hasBullet: true, ID: 'goods-receipt' },
    { to: '/inventory/payment-receipt', title: keyTitle.paymentReceipt, hasBullet: true, ID: 'payment-receipt' },
    { to: '/inventory/proforma-invoice', title: keyTitle.proformaInvoice, hasBullet: true, ID: 'proforma-invoice' },
    { to: '/inventory/vendor-invoice', title: keyTitle.vendorInvoice, hasBullet: true, ID: 'vendor-invoice' },
    { to: '/configurations/common-leads', title: keyTitle.commonLeads, hasBullet: true, ID: 'audit-form-list' },
    { to: '/configurations/audit-form-list', title: keyTitle.auditFormList, hasBullet: true, ID: 'common-leads' },
    { to: '/approval/approval-list', title: keyTitle.approvalList, hasBullet: true, ID: 'approval-list' },

]


export const AsidMenuMainSearchList = ({ show, handleClose, query, setSearchQuery, suggestions, onSuggestionClick }: any) => {
    return <>
        {show ? <Modal
            show={show}
            onHide={handleClose}
        >
            <div className='d-flex justify-content-between col-12 p-2'>
                <Modal.Title className='text-muted fs-6'></Modal.Title>
                <Modal.Title className='cursor-pointer me-2' onClick={handleClose}><FontAwesomeIcon className='fs-5 text-muted me-2 ' icon={faClose} /></Modal.Title>
            </div>
            <Modal.Body style={{ background: "rgba(226, 226, 226, 0.459)" }}>
                <input
                    autoFocus
                    type='text'
                    className='form-control col-12 mb-3 outlinecss'
                    value={query}
                    onChange={setSearchQuery}
                    placeholder='Search / Daily-Dashboard / Ticket Dashboard / Job-List'
                />
                <div className=' d-flex flex-column g-2 text-dark cursor-pointer  col-12'>
                    {
                        suggestions?.map((ele: any) => {
                            return <div className='mb-3 text-dark d-flex justify-content-start align-items-center text-hover-success  col-12 px-2 hovercss ' onClick={() => onSuggestionClick(ele?.to)} >
                                {
                                    ele?.hasBullet &&
                                    <FontAwesomeIcon className='fs-9 me-2 text-success' icon={faCircle} />
                                }
                                &nbsp;
                                {ele?.title}
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
        </Modal> : ''}
    </>
}

export const MenuWithSubMenuItems = [
    {
        isAllowed: 0, to: '/subscriptions', title: menuTitle?.Subscriptions, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // subscription Routes 
            { isAllowed: 0, to: '/subscriptions/active-paid', title: keyTitle.activePaid, hasBullet: true, },
            { isAllowed: 0, to: '/subscriptions/inactive-subscriptions-list', title: keyTitle.onDemand, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/vehicles/new', title: keyTitle.kitSubscriptions, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/renewed', title: keyTitle.inactive, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/reactivated', title: keyTitle.serverInactive, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/future-pause', title: keyTitle.renewalList, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/monthly-onboard', title: keyTitle.dateWiseOnboarding, hasBullet: true },
            { isAllowed: 0, to: '/subscriptions/pending-onboard', title: keyTitle.onboardingSubscriptions, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/schedule/cleaner', title: menuTitle?.Schedules, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // schedule routes  
            { isAllowed: 0, to: '/schedule/cleaner/un-assigned', title: keyTitle.unAssigned, hasBullet: true },
            { isAllowed: 0, to: '/schedule/cleaner/job-list', title: keyTitle.jobList, hasBullet: true },
            { isAllowed: 0, to: '/schedule/cleaner/area-wise-cleaner', title: keyTitle.searchAvailibility, hasBullet: true },
            { isAllowed: 0, to: '/schedule/cleaner-area-availibilty', title: keyTitle.cleanerAvailibilty, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/master', title: menuTitle?.Masters, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // Masters Routes  
            { isAllowed: 0, to: '/master/requirement-type', title: keyTitle.requirementType, hasBullet: true },
            { isAllowed: 0, to: '/master/terms-and-condition', title: keyTitle.termsAndCondition, hasBullet: true },
            { isAllowed: 0, to: '/master/create-batch', title: keyTitle.createBatch, hasBullet: true },
            { isAllowed: 0, to: '/master/services', title: keyTitle.services, hasBullet: true },
            { isAllowed: 0, to: '/master/products', title: keyTitle.products, hasBullet: true },
            { isAllowed: 0, to: '/master/packages', title: keyTitle.packages, hasBullet: true },
            { isAllowed: 0, to: '/master/fueltype', title: keyTitle.fuelType, hasBullet: true },
            { isAllowed: 0, to: '/master/vehicle-type', title: keyTitle.vehicleType, hasBullet: true },
            { isAllowed: 0, to: '/master/vehicle-category', title: keyTitle.vehicleCategory, hasBullet: true },
            { isAllowed: 0, to: '/master/vehicle-brand', title: keyTitle.vehicleBrand, hasBullet: true },
            { isAllowed: 0, to: '/master/vehicle-model', title: keyTitle.vehicleModel, hasBullet: true },
            { isAllowed: 0, to: '/master/problem', title: keyTitle.problem, hasBullet: true },
            { isAllowed: 0, to: '/master/sub-problem', title: keyTitle.subProblem, hasBullet: true },
            { isAllowed: 0, to: '/master/t-shirt', title: keyTitle.tShirt, hasBullet: true },
            { isAllowed: 0, to: '/master/job-sites', title: keyTitle.jobSites, hasBullet: true },
            { isAllowed: 0, to: '/master/job-type', title: keyTitle.jobType, hasBullet: true },
            { isAllowed: 0, to: '/master/user-type', title: keyTitle.userType, hasBullet: true },
            { isAllowed: 0, to: '/master/points-table', title: keyTitle.pointsTable, hasBullet: true },
            { isAllowed: 0, to: '/master/credit-reasons', title: keyTitle.creditReasons, hasBullet: true },
            { isAllowed: 0, to: '/master/job-status', title: keyTitle.jobStatus, hasBullet: true },
            { isAllowed: 0, to: '/master/master-reasons', title: keyTitle.masterReasons, hasBullet: true },
            { isAllowed: 0, to: '/master/master-resource', title: keyTitle.masterResource, hasBullet: true },
            { isAllowed: 0, to: '/master/master-sources', title: keyTitle.masterSources, hasBullet: true },
            { isAllowed: 0, to: '/master/master-offers', title: keyTitle.masterOffers, hasBullet: true },
            { isAllowed: 0, to: '/master/master-ticket-category', title: keyTitle.masterTicketsCategory, hasBullet: true },
            { isAllowed: 0, to: '/master/master-ticket-sub-category', title: keyTitle.masterTicketsSubCategory, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/address', title: menuTitle?.Address, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // Address Routes 
            { isAllowed: 0, to: '/address/country', title: keyTitle.country, hasBullet: true },
            { isAllowed: 0, to: '/address/state', title: keyTitle.state, hasBullet: true },
            { isAllowed: 0, to: '/address/city', title: keyTitle.city, hasBullet: true },
            { isAllowed: 0, to: '/address/pincode', title: keyTitle.pincode, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/campaigns', title: menuTitle?.Campaign, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // ... campaingns routes
            { isAllowed: 0, to: '/campaigns/dashboards', title: keyTitle.campaignDashboard, hasBullet: true },
            { isAllowed: 0, to: '/campaigns/create-campaign', title: keyTitle.createCampaign, hasBullet: true },
            { isAllowed: 0, to: '/campaigns/all-campaign', title: keyTitle.allCampaigns, hasBullet: true },
            { isAllowed: 0, to: '/campaigns/reward', title: keyTitle.rewards, hasBullet: true },
            { isAllowed: 0, to: '/campaigns/redeemtions', title: keyTitle.redeemtions, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/maps', title: menuTitle?.Maps, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // maps Routes 
            { isAllowed: 0, to: '/maps/comapany-cleaner-map', title: keyTitle.companyCleanerMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/customer-cleaner-map', title: keyTitle.customerCleanerMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/requirement-map', title: keyTitle.comanyRequirementMap, hasBullet: true },
            { isAllowed: 0, to: '/maps/sub-location-map', title: keyTitle.subLocationMap, hasBullet: true },
            { isAllowed: 0, to: '/maps/cleaner-route-map', title: keyTitle?.cleanerRoutesMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/society-map', title: keyTitle.societyMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/cleaner-map', title: keyTitle.cleanerMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/pincode-marker-map', title: keyTitle.pincodeMarkerMAPs, hasBullet: true },
            { isAllowed: 0, to: '/maps/all-customer-cleaner-map', title: keyTitle.allCustomerCleanerMAPs, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/apps/admin/stats', title: menuTitle?.Statistics, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            { isAllowed: 0, to: '/apps/stats/customers', title: keyTitle.customerStats, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/callresults/outboundresults', title: menuTitle?.Call_Results, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // call result routes 
            { isAllowed: 0, to: '/callresults/outboundcalls', title: keyTitle.outboundResult, hasBullet: true },
            { isAllowed: 0, to: '/callresults/inboundcalls', title: keyTitle.inboundResult, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/inventory/items', title: menuTitle?.Inventory, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // inventory route 
            { isAllowed: 0, to: '/inventory/items', title: keyTitle.items, hasBullet: true },
            { isAllowed: 0, to: '/inventory/order-status', title: keyTitle.orderStatus, hasBullet: true },
            { isAllowed: 0, to: '/inventory/orders-listing', title: keyTitle.orderList, hasBullet: true },
            { isAllowed: 0, to: '/inventory/new-orders-listing', title: keyTitle.newOrderList, hasBullet: true },
            { isAllowed: 0, to: '/inventory/warehouse', title: keyTitle.warehouse, hasBullet: true },
            { isAllowed: 0, to: '/inventory/vendor', title: keyTitle.vendor, hasBullet: true },
            { isAllowed: 0, to: '/inventory/warehouse-alert-status', title: keyTitle.warehouseAlertStatus, hasBullet: true },
            { isAllowed: 0, to: '/inventory/warehouse-type', title: keyTitle.warehouseType, hasBullet: true },
            { isAllowed: 0, to: '/inventory/purchase-order', title: keyTitle.purchaseOrder, hasBullet: true },
            { isAllowed: 0, to: '/inventory/material-transactions', title: keyTitle.materialTransactions, hasBullet: true },
            { isAllowed: 0, to: '/inventory/warehouse-stock-transfer', title: keyTitle.warehouseStockTransfer, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/', title: menuTitle?.Dashboards, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            { isAllowed: 0, to: '/ticket-dashboard', title: keyTitle.ticketDashboard, hasBullet: true },
            { isAllowed: 0, to: '/new-ticket-dashboard', title: keyTitle.newTicketDashboard, hasBullet: true },
            { isAllowed: 0, to: '/admin/job-dashboard', title: keyTitle.dailyDashboard, hasBullet: true },
            { isAllowed: 0, to: '/new-daily-dashboard', title: keyTitle.newDailyDashboard, hasBullet: true },
            { isAllowed: 0, to: '/iframe-dashboard', title: keyTitle.iframeDashboard, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/inactive', title: menuTitle?.All_Tickets, fontIcon: 'bi-app-indicator', isDisable: false, isMenu: true, subMenu: [
            // ticket list routes 
            { isAllowed: 0, to: '/admin/tickets', title: keyTitle.tickets, hasBullet: true },
            { isAllowed: 0, to: '/renewal-subscriptions', title: keyTitle.renewalSubscription, hasBullet: true },
            { isAllowed: 0, to: '/inactive-pause-paid', title: keyTitle.pausePaid, hasBullet: true },
            { isAllowed: 0, to: '/inactive-pause-unpaid', title: keyTitle.pauseUnPaid, hasBullet: true },
            { isAllowed: 0, to: '/inactive-auto', title: keyTitle.inactiveAutometically, hasBullet: true },
            { isAllowed: 0, to: '/inactive-agent', title: keyTitle.inactiveAgent, hasBullet: true },
            { isAllowed: 0, to: '/future-pause', title: keyTitle.futurePause, hasBullet: true },
            // { isAllowed: 0,to: '/visitors-list', title: keyTitle.visitorsList, hasBullet: true },
            // { isAllowed: 0,to: '/champs/visitor-list', title: keyTitle.champsVisitorsList, hasBullet: true },
            { isAllowed: 0, to: '/customer-leads', title: keyTitle.customerLeads, hasBullet: true },
            { isAllowed: 0, to: '/champs/champ-leads', title: keyTitle.champLeads, hasBullet: true },
            { isAllowed: 0, to: '/task-lists', title: keyTitle.taskLists, hasBullet: true },
            { isAllowed: 0, to: '/master/whatsapp-groups', title: keyTitle.whatsappGroups, hasBullet: true },
            { isAllowed: 0, to: '/data/renewal-supervisor-list', title: keyTitle.renewalSupervisorList, hasBullet: true },
            { isAllowed: 0, to: '/customer-vehicle-type-list', title: keyTitle.customerVehicleList, hasBullet: true },
            { isAllowed: 0, to: '/renewal-task-list', title: keyTitle.renewalTaskList, hasBullet: true },
            { to: '/admin/tickets', title: keyTitle.tickets, hasBullet: true },
            { to: '/renewal-subscriptions', title: keyTitle.renewalSubscription, hasBullet: true },
            { to: '/inactive-pause-paid', title: keyTitle.pausePaid, hasBullet: true },
            { to: '/inactive-pause-unpaid', title: keyTitle.pauseUnPaid, hasBullet: true },
            { to: '/inactive-auto', title: keyTitle.inactiveAutometically, hasBullet: true },
            { to: '/inactive-agent', title: keyTitle.inactiveAgent, hasBullet: true },
            { to: '/future-pause', title: keyTitle.futurePause, hasBullet: true },
            // { to: '/visitors-list', title: keyTitle.visitorsList, hasBullet: true },
            // { to: '/champs/visitor-list', title: keyTitle.champsVisitorsList, hasBullet: true },
            { to: '/customer-leads', title: keyTitle.customerLeads, hasBullet: true },
            { to: '/champs/champ-leads', title: keyTitle.champLeads, hasBullet: true },
            { to: '/task-lists', title: keyTitle.taskLists, hasBullet: true },
            { to: '/data/renewal-supervisor-list', title: keyTitle.renewalSupervisorList, hasBullet: true },
            { to: '/inactive-vehicle-list', title: keyTitle.customerVehicleList, hasBullet: true },
            { to: '/vehicle-subscription-history-list', title: keyTitle.vehicleSubscriptionHistoryList, hasBullet: true },
            { to: '/renewal-task-list', title: keyTitle.renewalTaskList, hasBullet: true },
            { to: '/welcome-call-1-list', title: keyTitle.welcomeCall1List, hasBullet: true },
            { to: '/welcome-call-2-list', title: keyTitle.welcomeCall2List, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/data', title: menuTitle?.Data_Lists, fontIcon: 'bi-app-indicator', isDisable: false, isMenu: true, subMenu: [
            { isAllowed: 0, to: '/data/renewal-supervisor-list', title: keyTitle.renewalSupervisorList, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/apps/admin/cleaner', title: menuTitle?.Champs, fontIcon: 'bi bi-people-fill', isDisable: false, isMenu: true, subMenu: [
            // // Champs Routes 
            { isAllowed: 0, to: '/schedule/cleaner/cleanerlist', title: keyTitle.active, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/cleanerattendance', title: keyTitle.attendance, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/champ-missed-list', title: keyTitle.champMissedCallList, hasBullet: true },
            { isAllowed: 0, to: '/absent/cleanerList', title: keyTitle.absentList, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/cleanerleaves', title: keyTitle.leaveList, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/deactivatedcleanerlist', title: keyTitle.deactivated, hasBullet: true },
            { isAllowed: 0, to: '/champ-schemes', title: keyTitle.schemes, hasBullet: true },
            { isAllowed: 0, to: '/deduction-jobs', title: keyTitle.deductionJobs, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/onboardingpendingcleanerlist', title: keyTitle.onboardingPending, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/leads', title: keyTitle.leads, hasBullet: true },
            { isAllowed: 0, to: '/apps/cleaner/leagues', title: keyTitle.league, hasBullet: true },
            { isAllowed: 0, to: '/old-deactivated-champ', title: keyTitle.newDeactivatedChampList, hasBullet: true },
            { isAllowed: 0, to: '/admin/approval-list', title: keyTitle.approvalLists, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/hiring', title: menuTitle?.Hiring, fontIcon: 'bi-app-indicator', isDisable: false, isMenu: true, subMenu: [
            // hiring routes 
            { isAllowed: 0, to: '/hiring/training-schedules', title: keyTitle.trainingSchedules, hasBullet: true },
            { isAllowed: 0, to: '/hiring/training-location', title: keyTitle.trainingLocations, hasBullet: true },
            { isAllowed: 0, to: '/hiring/champ-requirement', title: keyTitle.champRequirement, hasBullet: true },
            { isAllowed: 0, to: '/hiring/requirement-map', title: keyTitle.requirementMap, hasBullet: true },
            { isAllowed: 0, to: '/hiring/areas', title: keyTitle.areas, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/apps/notification/same-day', title: menuTitle?.Notifications, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // notification routes 
            { isAllowed: 0, to: '/apps/cleaner/NotificationTemplate', title: keyTitle.notificationTemplate, fontIcon: 'bi bi-back', hasBullet: true },
            { isAllowed: 0, to: '/notification-dashboard', title: keyTitle.notificationDashboard, fontIcon: 'bi bi-bell-fill', hasBullet: true },
        ]
    },
    { isAllowed: 0, to: '/inapp/ratings', title: keyTitle.inApp, fontIcon: 'bi bi-hourglass-split', isMenu: false },
    { isAllowed: 0, to: '/reward-and-penalties', title: keyTitle.rewardAndPenalties, fontIcon: 'bi bi-hourglass-split', isMenu: false },
    // {
    //     isAllowed: 0, to: '/ratings', title: menuTitle?.All_Ratings, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
    //         // rating routes 
    //         { isAllowed: 0, to: '/inapp/ratings', title: keyTitle.inApp, hasBullet: true },
    //         // { isAllowed: 0,to: '/ratings/PlayStore', title: keyTitle.playStore, hasBullet: true },
    //         // { isAllowed: 0,to: '/ratings/AppStore', title: keyTitle.appStore, hasBullet: true },
    //         // { isAllowed: 0,to: '/master/admin-users', title: keyTitle.adminUsers, hasBullet: true },
    //     ]
    // },
    {
        isAllowed: 0, to: '/apps/user-management/users', title: menuTitle?.User_management, fontIcon: 'bi-layers', isDisable: false, isMenu: true, subMenu: [
            { isAllowed: 0, to: '/master/admin-users', title: keyTitle.adminUsers, hasBullet: true },
        ]
    },
    {
        isAllowed: 0, to: '/invoice', title: menuTitle?.Invoice_List, fontIcon: 'bi-chat-left', isDisable: false, isMenu: true, subMenu: [
            // invoice routes  
            { isAllowed: 0, to: '/invoice/invoices', title: keyTitle.invoices, hasBullet: true },
            { isAllowed: 0, to: '/invoice/subscriptions', title: keyTitle.subscriptionMonth, hasBullet: true },
        ]
    },
    // DM Routes 
    // working on 
    { isAllowed: 0, to: '/working-on', title: keyTitle.workingOn, fontIcon: 'bi bi-hourglass-split', isMenu: false },
    { isAllowed: 0, to: '/WorkingOn2', title: keyTitle.workInProgress, fontIcon: 'bi bi-hourglass-split', isMenu: false },
    { isAllowed: 0, to: '/role-module', title: keyTitle.roleModules, fontIcon: 'bi bi-hourglass-split', isMenu: false },
    { isAllowed: 0, to: '/all/Societies', title: keyTitle.societies, fontIcon: 'bi bi-geo-fill', isMenu: false },
    // welcome-list
    { isAllowed: 0, to: '/welcome-list', title: keyTitle.welcomeList, fontIcon: 'bi bi-bag-plus', isMenu: false },
    // weeklysummery 
    { isAllowed: 0, to: '/apps/cleaner/weeklysummery', title: keyTitle.weeklyPayoutReport, fontIcon: 'bi bi-wallet2', isMenu: false },
    { isAllowed: 0, to: '/apps/cleaner/weeklysummery', title: keyTitle.newWeeklyPayoutReport, fontIcon: 'bi bi-wallet2', isMenu: false },
    // admin setting 
    { isAllowed: 0, to: '/admin/settings', title: keyTitle.settings, hasBullet: true, isMenu: false, fontIcon: 'bi bi-gear' },
    // lucky draw  
    { isAllowed: 0, to: '/lucky-draw', title: keyTitle.luckyDraw, hasBullet: true, isMenu: false, fontIcon: 'bi bi-award-fill' },
    { isAllowed: 0, to: '/admin/device-login-allowed', title: keyTitle.deviceLoginAllowed, hasBullet: true, fontIcon: 'bi bi-person' },
]

export const initialMenuStaus = {
    "Active_Paid": true,
    "Subscriptions": true,
    "Campaign": true,
    "Schedules": true,
    "On_Demand": true,
    "Kit_Subscriptions": true,
    "Date_wise_Onboarding": true,
    "Onboarding_Subscriptions": true,
    "Inactive": true,
    "Server_Inactive": true,
    "Renewal_List": true,
    "Un-Assigned": true,
    "Job_List": true,
    "Search_Availibility": true,
    "Masters": true,
    "Requirement_Type": true,
    "Services": true,
    "Products": true,
    "Packages": true,
    "Fuel_Type": true,
    "Vehicle_Type": true,
    "Vehicle_Category": true,
    "Vehicle_Brand": true,
    "Vehicle_Model": true,
    "Problem": true,
    "Sub_Problem": true,
    "Hiring_Dashboard": true,
    "T-Shirt": true,
    "Job_Sites": true,
    "Job_Type": true,
    "User_Type": true,
    "Point-Table": true,
    "Credit_Reasons": true,
    "Job_Status": true,
    "Master_Reasons": true,
    "Master_Resources": true,
    "Master_Sources": true,
    "Master_Offers": true,
    "Master_Tickets_Category": true,
    "Master_Tickets_Sub_Category": true,
    "Address": true,
    "Country": true,
    "State": true,
    "City": true,
    "Pincode": true,
    "Campaign_Dashboard": true,
    "Create_Campaign": true,
    "All_Campaigns": true,
    "Rewards": true,
    "Redeemtions": true,
    "Maps": true,
    "Company_Cleaner_MAPs": true,
    "Customer_Cleaner_MAPs": true,
    "Cleaner_Routes_MAPs": true,
    "Society_MAPs": true,
    "Cleaner_MAPs": true,
    "Pincode_Marker_MAPs": true,
    "All_Customer_Cleaner_MAPs": true,
    "Statistics": true,
    "Customer_Stats": true,
    "Call_Results": true,
    "Outbound_result": true,
    "Inbound_result": true,
    "Inventory": true,
    "Items": true,
    "Order_Status": true,
    "Order_List": true,
    "New_Order_List": true,
    "Dashboards": true,
    "Ticket_Dashboard": true,
    "Daily_Dashboard": true,
    "Iframe_Dashboard": true,
    "All_Tickets": true,
    "Tickets": true,
    "Renewal_Subscription": true,
    "Pause_Paid": true,
    "Pause_UnPaid": true,
    "Inactive_Autometically": true,
    "Inactive_Agent": true,
    "Future_Pause": true,
    "Visitors_List": true,
    "Champs_Visitors_List": true,
    "Customer_Leads": true,
    "Champ_Leads": true,
    "Task_Lists": true,
    "Renewal_Supervisor_List": true,
    "Data_Lists": true,
    "Champs": true,
    "Active": true,
    "Attendance": true,
    "Absent_List": true,
    "Leave_List": true,
    "Deactivated": true,
    "Schemes": true,
    "Deduction_Jobs": true,
    "Onboarding_Pending": true,
    "Leads": true,
    "League": true,
    "Hiring": true,
    "Training_Schedules": true,
    "Training_Locations": true,
    "Champ_Requirement": true,
    "Training_Topics": true,
    "Areas": true,
    "Notifications": true,
    "Notification_Template": true,
    "Notification_Dashboard": true,
    "All_Ratings": true,
    "In_App": true,
    "Play_Store": true,
    "App_Store": true,
    "Admin_Users": true,
    "User_management": true,
    "Invoice_List": true,
    "invoices": true,
    "Subscription_Month": true,
    "Working_on": true,
    "Work_in_progress": true,
    "Role_Modules": true,
    "societies": true,
    "Welcome_List": true,
    "Weekly_Payout_Report": true,
    "Settings": true,
    "Lucky_Draw": true,
    "isSet": true,
    "Approval_Lists": true,
    "customer_vehicle_type_list": true,
    "Device_Login_Allowed": true,
    "Cleaner_Availibilty": true,
    "Access_Area_Manager": true,
    "Customer_Assigned_Agents": true,
    "Easebuzz_Transaction_Hiistory": true,
}

// const routesCount = AsidMenuMainSearchListRoutes.length;
// const keyTitleCount = Object.keys(keyTitle).length;
