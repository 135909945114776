import RenderData from './RenderData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import JobActions from '../Actions/JobActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import InactiveStatusUpdate from '../Status/InactiveStatusUpdate';
import TagsShow from '../../../consts/TagsShow/TagsShow';
import Calling from '../../../consts/Call/Calling';
import Layout from '../../../consts/Call/Layout';


export const inactive_columns = (otherData: any) => ([
    {
        name: 'ACTION',
        cell: (row: any) => {
            const attendance_date = row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.attendance_date : ''
            const Reason_id = row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.inactive_reason_id : 0
            const cleaner_id = row?.id
            // const cleaner_id = row?.cleaner_id
            const reference = "inactive"
            return <div className='d-flex flex-row'>
                <div className=' me-5'>
                    <JobActions props={{ attendance_date, Reason_id, cleaner_id, reference, otherData }} row={row}  ></JobActions>
                </div>
            </div>
        },
        width: "80px"
    },
    {
        name: 'ID',
        selector: (row: { id: any; }) => row?.id,
        sortable: true,
        id: "ID",
        width: "90px"
    },
    {
        name: 'PHONE',
        cell: (row: any) => <div >
            <Calling title={row?.first_name + " " + row?.last_name} row_id={row?.id} row={row} type="cleanerid" From={Layout} isOpen={false} />
            {<TagsShow Tag={row?.private_tag} />}
        </div>,

        sortable: true,
        width: "200px",
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.first_name
            let date2: any = b?.first_name
            return date1?.toString()?.toLowerCase()?.localeCompare(date2?.toString()?.toLowerCase())
        },
    },
    {
        name: 'Status',
        selector: (row: any) => {
            let render: any = row?.clAttendance
            if (render?.length > 0) {
                return <StatusP2PUpdate row={row}></StatusP2PUpdate>
            } else {
                return "NA"
            }
        },
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.clAttendance?.length ? a.clAttendance[0]?.inactive_reason_id : 0
            let date2: any = b?.clAttendance?.length ? b.clAttendance[0]?.inactive_reason_id : 0
            return date1?.toString()?.localeCompare(date2?.toString())
        },
        sortable: true,
        width: "200px",
        style: {
            whiteSpace: "break-spaces"
        }
    },

    {
        name: 'Jobs Done Count',// pending 
        selector: (row: {jobsDone: number}) => row?.jobsDone,
        sortable: true,
        width: "auto",
    },
    {
        name: 'Unassigned Jobs Count',// pending 
        selector: (row: { unAssignedCount: number }) => row?.unAssignedCount,
        sortable: true,
        width: "auto",
    },
    {
        name: 'Area Manager',
        selector: (row: { champSupervisor: any; }) => {
            let render: any = <RenderData supervisors={[row?.champSupervisor]} />
            return render
        },
        sortFunction: (a: any, b: any) => {
            let date1: any = a?.champSupervisor?.first_name
            let date2: any = b?.champSupervisor?.first_name
            return date1?.toString()?.toLowerCase()?.localeCompare(date2?.toString()?.toLowerCase())
        },
        sortable: true,
        width: "250px",
        style: {
            whiteSpace: "break-spaces"
        },
    },
]);

const StatusP2PUpdate = ({ row }: any) => {
    const leaveReasonData = useSelector((store: any) => store?.ReasonsListDataReducer?.Inactive_reason)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [leaveReasonList, setleaveReasonList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])
    useEffect(() => {
        setleaveReasonList(leaveReasonData)
    }, [leaveReasonData])

    useEffect(() => {
        var updatedData: any = leaveReasonData?.filter((ele: any) => {
            return row.clAttendance?.length > 0 && ele?.id === storeRowP2P?.clAttendance[0]?.inactive_reason_id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : { name: "No Status" })
    }, [leaveReasonList, storeRowP2P?.clAttendance[0]?.inactive_reason_id])

    return <>

        <span >
            <FontAwesomeIcon onClick={() => setupdateStatusPop(!updateStatusPop)} icon={faEdit} />  &nbsp;{showRes?.name}
        </span>
        <InactiveStatusUpdate
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: setupdateStatusPop,
                selectedId: {
                    // cleaner_id: row.cleaner_id,
                    cleaner_id: row?.id,
                    inactive_reason_id: row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.inactive_reason_id : 0,
                    attendance_date: row?.clAttendance?.length > 0 ? row?.clAttendance[0]?.attendance_date : ''
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></InactiveStatusUpdate>
    </>
}



