import axios from 'axios'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import NewOrderListMain from './NewOrderListMain'

const NewOrderList: any = () => {
    const dispatch = useDispatch()
    const getData = async () => {
        const { data: orderStatusMaster } = await getRequest(URL_EndPoints(null)?.getAllMasterOrderStatus, null);
        dispatch({ type: "ADD_ORDER_STATUS_MASTER", payload: orderStatusMaster?.data });
    }
    
    useEffect(() => {
        getData()
    }, [])


    return (
        <KTCardBody className='card py-4'>
            <NewOrderListMain ></NewOrderListMain>
        </KTCardBody>
    )
}
export default NewOrderList
