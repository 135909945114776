import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Form, Table } from 'react-bootstrap';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner, adminToastRunnerError } from '../../admins/adminUtils';
import { toast } from 'react-toastify';
import IsLoading from '../../../consts/IsLoading/IsLoading';
import ViewRow from './ViewRow';

const getTotalQuantity = (addedItems: any, selectedItems: any, id: any) => {
  const selectedItem = selectedItems.find((item: any) => item.id === id)
  return (addedItems?.reduce((sum: any, item: any) => sum + Number(item.quantity), 0) || 0) + selectedItem?.quantity;
}

const ViewReturnTask = ({ show, productData, handleClose, modalLoader, taskStatus }: any) => {

  const [selectedItems, setSelectedItems] = useState<any[]>(productData || []);
  const [apiLoader, setApiLoader] = useState<any>(false);

  const [addedItems, setAddedItems] = useState<any>({})

  useEffect(() => {
    setSelectedItems(productData)
  }, [productData?.id])

  const handleDropdownChange = (index: number, value: number, keyName: any, subItemUpdate: any, subData: any) => {
    if (subItemUpdate) {
      const { id, addedIndex } = subData;
      if (addedItems[id] && addedItems[id].length > 0) {
        const updatedItems = [...addedItems[id]];
        updatedItems[addedIndex][keyName] = !value ? value : Number(value);
        const newAddedItems = { ...addedItems, [id]: updatedItems };
        setAddedItems(newAddedItems);
      }
    }
    else {
      const updatedItems = [...selectedItems];
      updatedItems[index][keyName] = !value ? value : Number(value);
      setSelectedItems(updatedItems);
    }

  };

  const handleQuantity = (index: number, value: number, orderedQuantity: number, subItemUpdate: any, subData: any) => {
    const { id, addedIndex } = subData;
    const isNumber = !isNaN(parseInt(value.toString()))
    if(isNumber && value == 0){
      toast.error('Quantity cannot be 0')
      return
    }

    const selectedItem = selectedItems.find((item: any) => item.id === id)
    const item = productData.find((item: any) => item.id === id)
    const sumValue = (addedItems[id]?.reduce((sum: any, item: any) => sum + Number(item.quantity), 0) || 0) + Number(value) + selectedItem?.quantity;
    if (sumValue > item?.quantity_ordered) {
      adminToastRunnerError('Quantity cannot be greater than sum of added item');
      return
    }

    if (subItemUpdate) {
      if (addedItems[id] && addedItems[id].length > 0) {
        const updatedItems = [...addedItems[id]];
        updatedItems[addedIndex]["quantity"] = !value ? value : Number(value)
        const newAddedItems = { ...addedItems, [id]: updatedItems };
        setAddedItems(newAddedItems);
      }
    }
    else {
      const updatedItems = selectedItems.map((item: any) => {
        if (item.id === subData.id) {
          return { ...item, quantity: !value ? value : Number(value) };
        }
        return item;
      });
      setSelectedItems(updatedItems);
    }
  };


  const submitHandler = async () => {

    // setApiLoader(true)
    if (!selectedItems || selectedItems.length === 0) {
      toast.warning('No Item Is Selected', { position: "top-center" });
      setApiLoader(false)
      return;
    }

    let result = [
      ...selectedItems?.map((ele: any) => ({
        id: ele?.id,
        quantity: ele?.quantity,
        condition: ele?.condition,
        age: ele?.age,
        care: ele?.care,
      })),
      ...Object.values(addedItems).flat(),
    ];

    const allStatusSelected = result.every((item: any) => item.condition > 0 && item.age > 0 && item.care > 0);
    const allNonZeroQuantity = result.every((item: any) => item.quantity > 0);
    if(!allStatusSelected){
      toast.error('Please mark condition, age and care of all items')
      return
    }
    if(!allNonZeroQuantity){
      toast.error('All items quantity should be greater than 0')
      return
    }

    const initialValue =   {
      returnedItems: result
    }

    console.log("initialValue", initialValue)

    // const response = await postRequest(URL_EndPoints()?.acceptReturnedItems, initialValue, setApiLoader)
    // adminToastRunner(response)
  }

  const addNewRow = (id: any, value: any) => {
    const item = productData.find((item: any) => item.id === id)
    const selectedItem = selectedItems.find((item: any) => item.id === id)
    const totalQuantity = selectedItem?.quantity + (addedItems[id]?.reduce((sum: any, item: any) => sum + Number(item.quantity), 0) || 0);
    if(totalQuantity >= item?.quantity_ordered){
      toast.error('Quantity cannot be greater than returned quantity')
      return
    }
    setAddedItems((prevItems: any) => {
      const currentItems = prevItems[id] || [];
      return {
        ...prevItems,
        [id]: [...currentItems, value]
      };
    });
  }

  const removCurrentRow = (id: any, value: any) => {
    setAddedItems((prevItems: any) => {
      if (prevItems[id] && prevItems[id]?.length > 0) {
        const updatedItems = prevItems[id].filter((item: any, index: any) => index !== value);
        if (updatedItems.length === 0) {
          const { [id]: removed, ...remainingItems } = prevItems;
          return remainingItems;
        }
        return { ...prevItems, [id]: updatedItems };
      }
      return prevItems;
    });
  };


  return (
    <Modal show={show} onHide={handleClose} size="xl"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Returned Materials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {<div>
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: '#5cb85c', color: 'white' }} className='px-2'>
              <tr className='px-2'>
                <th><strong className='mx-1'>Item</strong></th>
                <th><strong>Quantity</strong></th>
                <th><strong>Condition</strong></th>
                <th><strong>Age</strong></th>
                <th><strong>Care</strong></th>
              </tr>
            </thead>
            {
              modalLoader ? (
                <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <IsLoading />
                </div>
              ) : <tbody>
                {selectedItems.map((item, index) => (
                  // <div >
                  <ViewRow
                    key={index}
                    item={item}
                    index={index}
                    taskStatus={taskStatus}
                    handleQuantity={handleQuantity}
                    handleDropdownChange={handleDropdownChange}
                    addNewRow={addNewRow}
                    removCurrentRow={removCurrentRow}
                    addedItems={addedItems[item?.id] || []}
                  />
                  // </div>
                ))}
              </tbody>
            }

          </Table>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        {
          productData?.length > 0 && <Button disabled={apiLoader} variant="secondary" onClick={submitHandler}>
            {apiLoader ? "Saving" : " Submit"}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ViewReturnTask;
