import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

export const getColumns = (handleViewButtonClick?: any, data?: any, handleEdit?: any) => [
  {
    name: 'Id',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 2,
    width: '80px',
  },
  {
    name: 'Proforma Invoice Number',
    cell: (row: any) => row?.proforma_invoice_id,
    sortField: 'proforma_invoice_id',
    sortable: true,
    reorder: true,
    id: 10,
    width: '200px',
  },
  {
    name: 'Items',
    cell: (row: any) => (
      <span>
        <FontAwesomeIcon 
          className='me-2 ms-2 text-muted fs-4 cursor-pointer' 
          icon={faEye} 
          onClick={() => handleViewButtonClick(row?.id)}
        />
      </span>
    ),
    sortable: false,
    reorder: true,
    id: 12,
    width: '120px',
  },
  {
    name: 'Media',
    cell: (row: any) => (
      <img 
        src={row?.media} 
        alt="" 
        style={{ width: "50px", height: "50px", cursor: "pointer" }} 
        onClick={() => window.open(row?.media, '_blank')}
      />
    ),
    reorder: true,
    id: 3,
    width: '100px',
  },
  {
    name: 'Amount',
    cell: (row: any) => row?.amount,
    sortField: 'amount',
    sortable: true,
    reorder: true,
    id: 4,
    width: '120px',
  },
  {
    name: 'Status',
    cell: (row: any) => row?.proformaStatus?.status,
    sortField: 'proformaStatus.status',
    sortable: true,
    reorder: true,
    id: 9,
    width: '120px',
  },
  {
    name: 'Purchase Order Number',
    cell: (row: any) => row?.proformaPurchaseOrder?.purchase_order_id,
    sortField: 'proformaPurchaseOrder.purchase_order_id',
    sortable: true,
    reorder: true,
    id: 8,
    width: '120px',
  },
  {
    name: 'Invoice Date',
    cell: (row: any) => row?.invoice_date,
    sortField: 'invoice_date',
    sortable: true,
    reorder: true,
    id: 6,
    width: '120px',
  },
  {
    name: 'Due Date',
    cell: (row: any) => row?.due_date,
    sortField: 'due_date',
    sortable: true,
    reorder: true,
    id: 7,
    width: '120px',
  },
  {
    name: 'Notes',
    cell: (row: any) => row?.notes,
    sortField: 'notes',
    sortable: true,
    reorder: true,
    id: 5,
    width: '150px',
  },
]