
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getRequest, postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { adminQueryGenerator, adminToastRunner } from '../../modules/admins/adminUtils';
import SkeletonLoader from '../IsLoading/SkeletonLoader';
import AdminTextBoxReuse from '../../modules/admins/adminForms/adminFormComponents/AdminTextBoxReuse';
import AdminSelectSearchable from '../../modules/admins/adminForms/adminFormComponents/AdminSelectSearchable';
import AdminCreatebleDropdown from '../../modules/admins/adminForms/adminFormComponents/AdminCreatebleDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faRemove } from '@fortawesome/free-solid-svg-icons';
import { CreateCreatableSelectSinglMulti, CreateInput, CreateText } from './CreateAuditCompoents';
import AdminSelectCheckbox from '../../modules/admins/adminForms/adminFormComponents/AdminSelectCheckbox';
import { CapitalizeFirstLetter } from '../CapitalizeFirstLetter';

interface CreateAuditFormProps {
    show: boolean;
    readOnlyForm: boolean;
    onHide: () => void;
    row?: any
}

const CreateAuditForm: React.FC<CreateAuditFormProps> = ({ show, onHide, readOnlyForm = false, row = null }) => {
    const [formTypeList, setFormTypeList] = useState<any>([]);
    const [formConfig, setFormConfig] = useState<any>([]);
    const [answerTypeList, setAnswerTypeList] = useState<any>([]);
    const [optionList, setOptionType] = useState<any>([]);
    const [pending, setPending] = useState<any>(false);
    const [formLoader, setFormLoader] = useState<any>(false);
    const [formData, setFormData] = useState<any>({
        form_name: "",
        form_type_id: "",
        has_multiple_executive: "",
        questions: [
            {
                id: 1,
                question_text: "",
                answer_type: null,
                options: [],
                is_required: 0,
                order_no: 0
            }
        ],
    });

    console.log('formData', formData);

    const onChangeHandler = (event: any, questionId: any, fieldName: string) => {

        if (event) {
            setFormData((prevData: any) => {
                const updatedQuestions = prevData.questions.map((question: any) => {
                    if (question.id === questionId) {
                        return { ...question, [fieldName]: event?.value };
                    }
                    return question;
                });
                return { ...prevData, questions: updatedQuestions };
            });
        }
        else {
            setFormData((prevData: any) => {
                const updatedQuestions = prevData.questions.map((question: any) => {
                    if (question.id === questionId) {
                        return { ...question, [fieldName]: event };
                    }
                    return question;
                });
                return { ...prevData, questions: updatedQuestions };
            });
        }
    };

    const onChangeHandler2 = (event: any, name: any) => {
        setFormData({ ...formData, [name]: event ? event?.value : event });
    };


    const handleSubmit = async () => {
        setFormLoader(true)
        try {
            const response = await postRequest(URL_EndPoints()?.createFormConfig, formData, setFormLoader);
            adminToastRunner(response)
            onHide();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const AddRemoveHandler = (value: boolean, id: any) => {
        if (value) {
            setFormData((prevData: any) => {
                let newQuestion = {
                    id: formData?.questions?.length + 1,
                    question_text: "",
                    answer_type: null,
                    options: null,
                }

                return { ...prevData, ["questions"]: [...prevData?.questions, newQuestion] }
            })
        }
        else {
            setFormData((prevData: any) => {
                const updatedAnswers = prevData?.questions?.filter((removeElement: any) => removeElement.id != id)
                return { ...prevData, ["questions"]: updatedAnswers }
            })
        }
    }

    const [addContentLoader, setAddContentLoader] = useState(false)

    const getFormQuestionOptions = async () => {
        const response = await getRequest(URL_EndPoints()?.["getFormQuestionOptions"], null)
        setOptionType(response?.data?.data?.options || [])

    }

    const getFormTypes = async () => {
        const response = await getRequest(URL_EndPoints()?.["getFormTypes"], null)
        setFormTypeList(response?.data?.data?.formTypes || [])
    }

    const getAnswerTypes = async () => {
        const response = await getRequest(URL_EndPoints()?.["getAnswerTypes"], null)
        setAnswerTypeList(response?.data?.data?.answerTypes || [])
    }

    const getFormConfig = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints(adminQueryGenerator("getFormConfig", {
            form_id: row?.id
        }))?.["getFormConfig"], setPending)

        let formDataNew = {
            form_name: response?.data?.data?.formData?.form?.form_name,
            form_type_id: { label: response?.data?.data?.formData?.form?.form_name, value: response?.data?.data?.formData?.form?.form_type },
            has_multiple_executive: response?.data?.data?.formData?.form?.has_multiple_executive == 1 ? true : false,
            questions: response?.data?.data?.formData?.questions?.map((ele: any) => ({
                id: ele?.id,
                question_text: ele?.question_text,
                is_required: ele?.is_required == 1 ? 1 : 0,
                answer_type: { label: CapitalizeFirstLetter(ele?.answer_type), value: ele?.answer_type },
                options: typeof (ele?.options) == "string" ? [] : ele?.options?.map((ele: any) => ({ label: ele?.option_text, value: ele?.id })),
            })),
        }

        setFormData(formDataNew)
        setFormConfig(response?.data?.data || [])
    }

    useEffect(() => {
        readOnlyForm && row?.id && getFormConfig();
    }, [readOnlyForm, row?.id])

    useEffect(() => {
        getFormQuestionOptions()
        getFormTypes()
        getAnswerTypes()
    }, [])

    const addContentToDropdown = async (apiUrl: any, newPayload: any, getApiData: any) => {
        const isConfirmed = window.confirm("Are you sure you want to add this content ?");
        if (!isConfirmed) {
            return;
        }

        const response = await postRequest(URL_EndPoints()?.[apiUrl], newPayload, setAddContentLoader)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            getApiData()
        }

    }

    const isAllowedActions = (action: any) => {
        return readOnlyForm ? () => { } : action
    }

    const notAllowedOptions = ["text", "textarea"]
    return (
        <>
            {
                <Modal show={show} onHide={onHide} size="lg">
                    <Modal.Header closeButton>
                        {
                            <Modal.Title>{"Create Audit Form"}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>

                        {

                            pending ? <SkeletonLoader rowCount={2} title="" /> : <>

                                <div className='border p-2 py-4 mb-4'>
                                    {
                                        <div className='mb-4'>
                                            <label htmlFor="Form Name"> Form Name</label>
                                            <AdminTextBoxReuse
                                                onChangeHandler={isAllowedActions(onChangeHandler2)}
                                                HeaderTitle={`Enter Form Name`}
                                                payload={formData}
                                                name="form_name"
                                            />
                                        </div>
                                    }

                                    {
                                        <div className='mb-4'>
                                            <label htmlFor="Select Form Type" className='mb-1'> Select Form Type</label>
                                            <AdminCreatebleDropdown
                                                handleInputTextValue={isAllowedActions(addContentToDropdown)}
                                                placeHolder="Add Form Type"
                                                Component={AdminSelectSearchable}
                                                apiUrl="createFormType"
                                                initialPayload={{
                                                    name: ""
                                                }}
                                                keyName="name"
                                                getApiData={getFormTypes}
                                                props={{
                                                    HeaderTitle: 'Select Form Type',
                                                    onChangeHandler: isAllowedActions(onChangeHandler2),
                                                    selectData: formTypeList || [],
                                                    displayFields: ["name"],
                                                    id: "id",
                                                    name: "form_type_id",
                                                    searchable: false,
                                                    setSeacrch: null,
                                                    isLoading: null,
                                                    filters: null,
                                                    isMulti: false,
                                                    defaultData: readOnlyForm ? { item: formData?.form_type_id } : null
                                                }}
                                                default
                                            />
                                        </div>
                                    }

                                    {
                                        <AdminSelectCheckbox
                                            onChangeHandler={isAllowedActions(onChangeHandler2)}
                                            HeaderTitle="Has Multiplie Executive"
                                            payload={formData}
                                            name="has_multiple_executive"
                                            label=""
                                        />
                                    }

                                </div>

                                <>

                                    {
                                        formData?.questions?.map((ele: any, index: number) => {
                                            console.log('answer_type', ele?.answer_type);
const texValue =  ele?.answer_type?.value ?? ele?.answer_type

                                        
                                            return (
                                                <div className='border bg-secondary p-2 py-4 mb-4' key={ele?.id}>

                                                    {
                                                        <div className='mb-4'>
                                                            <label htmlFor="Question"> Question</label>
                                                            <CreateText
                                                                onChangeHandler={isAllowedActions(onChangeHandler)}
                                                                value={ele?.question_text}
                                                                fieldName="question_text"
                                                                questionId={ele?.id}
                                                                placeholder="Enter Question"
                                                                label="Enter Question"
                                                            />
                                                        </div>
                                                    }

                                                    {
                                                        <div className='mb-4'>
                                                            <label htmlFor="Select Answer Type" className='mb-1'> Select Answer Type</label>
                                                            <CreateCreatableSelectSinglMulti
                                                                headerTitle={'Select Answer Type'}
                                                                onChangeHandler={isAllowedActions(onChangeHandler)}
                                                                selectData={answerTypeList || []}
                                                                displayFields={["name"]}
                                                                id={"id"}
                                                                name={"answer_type"}
                                                                searchable={false}
                                                                setSearch={null}
                                                                isLoading={false}
                                                                filters={null}
                                                                isMulti={false}
                                                                questionId={ele?.id}
                                                                defaultData={readOnlyForm ? { item: ele?.answer_type } : null}
                                                            />
                                                        </div>
                                                    }

                                                    {

                                                        !notAllowedOptions?.includes(texValue) &&  <div className='mb-4'>
                                                            <label htmlFor="Options" className='mb-1'> Options</label>
                                                            <AdminCreatebleDropdown
                                                                handleInputTextValue={isAllowedActions(addContentToDropdown)}
                                                                placeHolder="Add New Option"
                                                                Component={CreateCreatableSelectSinglMulti}
                                                                apiUrl="createFormQuestionOption"
                                                                initialPayload={{
                                                                    name: "",
                                                                    is_active: 1,
                                                                }}
                                                                keyName="name"
                                                                getApiData={getFormQuestionOptions}
                                                                props={{
                                                                    HeaderTitle: 'Options',
                                                                    onChangeHandler: isAllowedActions(onChangeHandler),
                                                                    selectData: optionList || [],
                                                                    displayFields: ["option_text"],
                                                                    id: "id",
                                                                    name: "options",
                                                                    searchable: false,
                                                                    setSeacrch: null,
                                                                    isLoading: null,
                                                                    filters: null,
                                                                    isMulti: true,
                                                                    questionId: ele?.id,
                                                                    defaultData: readOnlyForm ? { item: ele?.options } : null
                                                                }}
                                                                default
                                                            />


                                                        </div>
                                                    }
                                                    <div className='mb-4'>
                                                        {
                                                            <CreateInput
                                                                onChangeHandler={isAllowedActions(onChangeHandler)}
                                                                value={ele?.is_required == 1 ? true : false}
                                                                fieldName="is_required"
                                                                questionId={ele?.id}
                                                                label="Is Required"
                                                            />
                                                        }
                                                    </div>


                                                    <div className='d-flex justify-content-end mt-3 w-100'>
                                                        {
                                                            formData?.questions?.length != 0 && <Button className='me-3  rounded-0 btn btn-sm btn-danger p-1 px-3 d-flex justify-content-center align-items-center' onClick={() => readOnlyForm ? {} : AddRemoveHandler(false, ele?.id)} disabled={formLoader}>
                                                                Remove <FontAwesomeIcon icon={faRemove} className='ms-2 fs-4' />
                                                            </Button>
                                                        }
                                                        {
                                                            formData?.questions?.length - 1 == index && <Button className='rounded-0 btn btn-sm btn-success p-1 px-3 d-flex justify-content-center align-items-center' onClick={() => readOnlyForm ? {} : AddRemoveHandler(true, index + 1)} disabled={formLoader}>
                                                                Add More <FontAwesomeIcon icon={faAdd} className='ms-2 fs-4' />
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </>

                                <div className='text-end mt-12'>
                                    <Button variant={formLoader ? "success" : "primary"} onClick={handleSubmit} disabled={formLoader}>
                                        {
                                            formLoader ? "Submiting..." : "Submit"
                                        }
                                    </Button>
                                </div>

                            </>
                        }


                    </Modal.Body>
                </Modal>
            }


        </>
    );
};

export default CreateAuditForm;

