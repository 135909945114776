import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import CloseButton from '../../consts/Schedules/CloseButton'
import AssignJobForm from './AssignJobForm'
import { useDispatch, useSelector } from 'react-redux'

const AssignJobDrawerList = ({ isDrawer = false, row, }: any) => {

    const assignAbsentJob = useSelector((store: any) => store.Schedules.assignAbsentJob)

    const dispatch = useDispatch()
    const closeHandle = () => {
        document.getElementById('kt_drawer_chat_absent_assign_job')?.classList.add("kt_drawer_chat_absent_assign_job_close")
        dispatch({
            type: 'ASSIGN_ABSENT_JOB', payload: {
                assignAbsentJobId: null,
                assignAbsentJobRender: null,
                show: false,
                row: row,
                from: "",
            }
        })
    }


    // dispatch({
    //     type: 'CHAMP_AVALITY', payload: {
    //         assignAbsentJobId: SingleId,
    //         assignAbsentJobRender: Math.random(),
    //         show: false,
    //         row: null,
    //         from: "",
    //     }
    // })

    return (
        <div
            style={{ width: "99.6%", padding: "5px" }}
            id='kt_drawer_chat_absent_assign_job'
            className='bg-body px-2'
            data-kt-drawer='true'
            data-kt-drawer-name='chat'
            // data-kt-drawer-width="{default:'600px', 'md': `${widths}`}"
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_drawer_chat_absent_assign_job_toggle3'
            data-kt-drawer-close='#kt_drawer_chat_absent_assign_job_close'
        >
            <div className='border border-muted w-100 ml-2 rounded-0' id='kt_drawer_chat_absent_assign_job_messenger'>
                <div className='card-header pe-2 d-flex justify-content-end' id='kt_drawer_chat_absent_assign_job_messenger_header'>
                    <CloseButton closeHandle={closeHandle} id={"kt_drawer_chat_absent_assign_job_close"}></CloseButton>
                </div>
                <>
                    {
                        <div
                            // className=' customer-detail-body'
                            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}

                        >
                            <div
                                style={{ maxHeight: "500px" }}
                                // className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                                data-kt-element='messages'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies={
                                    isDrawer
                                        ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                                        : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                                }
                                data-kt-scroll-wrappers={
                                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                                }
                            >

                                <div key={row?.id} className='w-100 p-2'>
                                    {
                                        assignAbsentJob?.show ? <AssignJobForm
                                            show={assignAbsentJob?.show}
                                            row={assignAbsentJob?.row}
                                            assignAbsentJobId={assignAbsentJob?.assignAbsentJobId}
                                            assignAbsentJobRender={assignAbsentJob?.assignAbsentJobRender}
                                        /> : <></>
                                    }

                                </div>
                            </div>
                        </div>
                    }
                </>
            </div>
        </div>
    )
}
 export default AssignJobDrawerList
