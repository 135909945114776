import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import CleanerAvailabilityRoute from '../cleaner/CleanerAvailabilityRoute';
const OpenCleanerList = ({ show, setShow, champAvailty, getChampDetails }: any) => {

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cleaner List</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        champAvailty?.champAvailty ?
                            <CleanerAvailabilityRoute refrence={"outside"} iscleanerpage={false} champAvailty={champAvailty.champAvailty} champAvailtyRender={champAvailty.champAvailtyRender} from={champAvailty.from} getChampDetails={getChampDetails}></CleanerAvailabilityRoute> : <></>
                    }
                </Modal.Body>
            </Modal >
        </>
    )
}
export default OpenCleanerList
