import moment from 'moment'
export const GoodItemListColumn = () => [
    // {
    //     name: 'Batch Number',
    //     cell: (row: any) => row?.batch_number,
    //     sortable: true,
    //     reorder: true,
    //     id: 2,
    //     width: '120px',
    // },
    {
        name: 'Item Name',
        cell: (row: any) => row?.goodsReceiptItemsList.title,
        sortable: true,
        reorder: true,
        id: 3,
        width: '150px',
    },
    // {
    //     name: 'Media',
    //     cell: (row: any) => <img src={row?.goodsReceiptItemsList?.media} alt="" style={{ width: "50px", height: "50px" }} />,
    //     reorder: true,
    //     id: 3,
    //     width: '200px',
    //     sortable: true
    // },
    // {
    //     name: 'Description',
    //     cell: (row: any) => row?.goodsReceiptItemsList?.description,
    //     reorder: true,
    //     id: 3,
    //     width: '200px',
    //     sortable: true
    // },
    {
        name: 'Unit Price',
        cell: (row: any) => row?.unit_price,
        reorder: true,
        id: 4,
        width: '100px',
        sortable: true
    },
    {
        name: 'GST %',
        cell: (row: any) => row?.gst_percentage,
        reorder: true,
        id: 5,
        width: '100px',
        sortable: true
    },
    {
        name: 'Tax Amount',
        cell: (row: any) => row?.tax_amount,
        reorder: true,
        id: 6,
        width: '120px',
        sortable: true
    },
    {
        name: 'Item Amount',
        cell: (row: any) => row?.item_amount,
        reorder: true,
        id: 7,
        width: '120px',
        sortable: true
    },
    {
        name: 'Total Price',
        cell: (row: any) => row?.total_price,
        reorder: true,
        id: 8,
        width: '120px',
        sortable: true
    },
    {
        name: 'Qty Ordered',
        cell: (row: any) => row?.quantity_ordered,
        reorder: true,
        id: 9,
        width: '120px',
        sortable: true
    },
    {
        name: 'Qty Received',
        cell: (row: any) => row?.quantity_received,
        reorder: true,
        id: 10,
        width: '120px',
        sortable: true
    },
    {
        name: 'Expiry Date',
        cell: (row: any) => row?.expiry_date,
        reorder: true,
        id: 11,
        width: '100px',
        sortable: true
    },
]
