import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

interface Product {
  inventoryOrderedItemMaster: {
    title: string;
    price: number;

  };
  quantity: number;
}

interface ProductModalProps {
  show: boolean;
  productData: Product[];
  handleClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ show, productData, handleClose }) => {
  const columns = [
    {
      name: 'Product Name',
      selector: (row: Product) => row.inventoryOrderedItemMaster.title,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Quantity',
      selector: (row: Product) => row.quantity,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Price',
      selector: (row: Product) => row.inventoryOrderedItemMaster.price,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Init Delivery Qty',
      selector: (row: any) => row?.total_initial_delivery_quantity,
      sortable: true,
      width: "200px"
    },
    {
      name: 'Delivery Qty (After adjustment)',
      selector: (row: any) => row?.total_delivery_quantity,
      sortable: true,
      width: "250px"
    },
    {
      name: 'Delivered Qty',
      selector: (row: any) => row?.total_delivered_quantity,
      sortable: true,
      width: "200px"
    },
    {
      name: 'Max Bag Qty',
      selector: (row: any) => row?.total_max_required_quantity_in_bag,
      sortable: true,
      width: "200px"
    },
    {
      name: 'Accepted Qty (By champ)',
      selector: (row: any) => row?.total_accepted_quantity_by_champ,
      sortable: true,
      width: "250px"

    },
    {
      name: 'Remaining Qty',
      selector: (row: any) => row?.remaining_quantity_to_be_delivered,
      sortable: true,
      width: "200px"

    },
  ];

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {productData && productData.length > 0 ? (
          <DataTable
            columns={columns}
            data={productData}
            pagination
            highlightOnHover
            striped
          />
        ) : (
          <p>No item available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductModal;
